<div class="mb-4 d-flex align-items-center">
    <h1 class="page-title m-0"><i class="fa fa-usd small mr-3"></i> {{ 'Historial de Stop Sales' | translate}} </h1>
</div>
<div class="mb-4 d-flex align-items-center">
    <button class="whiteButton mr-3" (click)="dp.toggle()" [attr.aria-expanded]="dp.isOpen"> <i class="fa fa-calendar-o mr-1"
            aria-hidden="true"></i>
        Seleccionar Fechas
        <input type="text" class="form-control" #dp="bsDaterangepicker" bsDaterangepicker [bsConfig]="bsConfig"
            [bsValue]="bsValue" style="height: 0;padding: 0;border: none;" [(ngModel)]="datepickerModel" (bsValueChange)="filterByRange(datepickerModel)">
    </button>
    <div *ngIf="datepickerModel">
        <span class="mr-1">Desde el {{datepickerModel[0].getDate()}} de {{monthList[(datepickerModel[0].getMonth())]}}  al {{datepickerModel[1].getDate()}} de {{
            monthList[(datepickerModel[1].getMonth())]}}
            de {{datepickerModel[1].getFullYear()}}</span>
        <button (click)="deleteRange(datepickerModel)" class="trash-can-button"><i class="fa fa-times"
                aria-hidden="true"></i></button>
    </div>
</div>
<div class="card product-card pb-5">
    <div class="card-header">
        <b>Histórico de los stop sales:</b>
    </div>
    <div class="card-body p-0 mb-3">
        <div>
            <ngx-datatable #myTable class="bootstrap" [rowHeight]="'auto'" [columns]="columns" [columnMode]="'force'"
                [headerHeight]="90" [footerHeight]="60" [limit]="maxTableRows" [rows]="rows">

                <ngx-datatable-column *ngFor="let column of columns; let i = index;"
                    name="{{translate.instant(column.name)}}" prop="{{column.prop}}">
                    <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                        <ng-container *ngIf="i==1">
                            <div class="d-flex" style="flex-wrap: wrap;">
                                <div class="mr-3 mb-3" *ngFor="let contact of value.slice(0,2); let i = index">
                                    <span class="contact-tag">
                                        <!-- contactos -->
                                        {{contact.email}}
                                    </span>
                                </div>
                                <div *ngIf="value.length > 2" class="">
                                    <span class="contact-tag pointer"
                                        (click)="openMessageModal(viewContactsData,{class: 'small-modal', initialState:{data:{ contacts: value}}})">...</span>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="i==2">
                            <span>
                                {{formatCustomDateWithTime(value)}}
                            </span>
                        </ng-container>
                        <ng-container *ngIf="i==3">
                            <button class="whiteButton"
                                (click)="openMessageModal(viewMessageData,{class: 'medium-modal',initialState:{data:{ message: row}}})"><i
                                    class="fa fa-commenting-o mr-1" aria-hidden="true"></i>Ver
                                mensaje</button>
                        </ng-container>
                        <ng-container *ngIf="i!=1 && i!=2 && i!=3">
                            <span>
                                {{value}}
                            </span>
                        </ng-container>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-footer>
                    <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                        let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset">
                        <div class="col-6 p-0">
                            <div class="left-content">
                                <span style="padding: 0.75rem;">
                                    Total: {{ rowCount }} Stop Sales
                                </span>
                            </div>
                        </div>
                        <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'"
                            [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'"
                            [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize"
                            [count]="rowCount" [hidden]="!((rowCount / pageSize) > 1)"
                            (change)="myTable.onFooterPage($event)">
                        </datatable-pager>
                    </ng-template>
                </ngx-datatable-footer>
            </ngx-datatable>
            <div class="footer-content">

            </div>
        </div>
    </div>
</div>

<ng-template #viewMessageData id="messageData">
    <div class="card" style="width: 803px;height: 575px;">
        <div class="card-header d-flex align-items-center mb-3">
            <div class="d-flex align-items-center">
                <strong class="mr-3">Para:</strong>
                <p class="mr-3 contact-tag mb-0"
                    *ngFor="let contact of modalService.config.initialState['data'].message.contacts">
                    {{contact.email}}
                </p>
            </div>
            <button type="button" class="btn btn-lg btn-white btn-close pull-right ml-auto  mr-3 p-1" aria-label="Close"
                (click)="modalRef.hide()">
                <span class="vi-close"></span>
            </button>
        </div>
        <div class="card-body pt-0">
            <div class="d-flex flex-column">
                <strong class="mb-2">Fecha y hora de envio:</strong>
                <span [innerHTML]="formatCustomDateWithTime(modalService.config.initialState['data'].message.createdAt)"
                    class="mb-3"></span>
                <div class="d-flex mb-3">
                    <strong class="mr-3">Asunto</strong>
                    <span [innerHTML]="modalService.config.initialState['data'].message.subject"></span>
                </div>
                <strong class="mb-3">Mensaje</strong>
                <span [innerHTML]="modalService.config.initialState['data'].message.message"></span>
                <ul *ngFor="let experience of modalService.config.initialState['data'].message.experiences"
                    class="d-flex">
                    <li class="mr-3">
                        {{experience.name}}
                    </li>
                    <span *ngFor="let date of modalService.config.initialState['data'].message.dates" class="d-flex"
                        style="min-width: 250px;">
                        {{formatCustomDate(date[0]) + ' - ' + formatCustomDate(date[1])}}
                    </span>
                </ul>
                <!-- <span>
                    Pijama Party 10/08/2023-12/08/2023
                </span>
                <span>
                    Avistamiento de delfines 10/08/2023-12/08/2023
                </span> -->
                <span>
                    Un saludo,
                </span>
                <span>
                    [Firma del Maker]
                </span>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #viewContactsData id="contactsData">
    <div class="card" style="width: 409px;height: 262px;">
        <div class="card-header d-flex align-items-center">
            <i class="fa fa-users mr-3" aria-hidden="true"></i>
            <strong>Para:</strong>
            <button type="button" class="btn btn-lg btn-white btn-close pull-right ml-auto mr-3 p-1" aria-label="Close"
                (click)="modalRef.hide()">
                <span class="vi-close"></span>
            </button>
        </div>
        <div class="card-body">
            <div class="d-flex" style="flex-wrap: wrap;">
                <div class="mr-3 mb-3" *ngFor="let contact of modalService.config.initialState['data'].contacts">
                    <span class="contact-tag">
                        {{contact.email}}
                    </span>
                </div>
            </div>
        </div>
    </div>
</ng-template>