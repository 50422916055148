<div class="pin-background" *ngIf="experience">
    <div class="container py-5">
        <div class="card first-card">
            <div class="row flex-column-reverse flex-lg-row">
                <div class="col-lg-5">
                    <div class="d-flex flex-column h-100">
                        <img class="maker-image" style="margin-bottom: 28px;cursor: pointer;"
                            [routerLink]="['/es/maker/' + experience.maker.slug + '/pin']"
                            [src]="'https://venntur.com/assets/maker/files/' + (experience.maker.mediaMakers.length > 0 ? experience.maker.mediaMakers[0].contentUrl : '')"
                            alt="Imagen de la experiencia">
                        <strong [title]="experience.name" class="experience-name"
                            style="font-size: 26px;">{{experience.name}}</strong>
                        <button class="btn btn-primary mt-auto" style="width: fit-content;">Descargar ficha</button>
                    </div>
                </div>
                <div class="col-lg-7">
                    <div class="position-relative">
                        <div class="keen-slider" #pinImagesSlider>
                            <ng-container *ngFor="let mediaExperience of experience.mediaExperiences; let i = index">
                                <div class="keen-slider__slide" [ngClass]="{'active': i == 0}">
                                    <img onError="this.src='assets/venntur-placeholder.jpeg'"
                                        [src]="'https://venntur.com/assets/experience/files/' + mediaExperience.contentUrl"
                                        alt="{{experience.name}} - Venntur" title="{{experience.name}}"
                                        style="display: block; width: 100%;">
                                </div>
                            </ng-container>
                        </div>
                        <div class="dots" *ngIf="dotHelper.length > 1">
                            <button (click)="slider.moveToIdx(i)" *ngFor="let slide of dotHelper; let i = index"
                                [class]="'dot ' + (i === currentSlide ? 'active' : '')"></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ul class="nav nav-tabs mb-n1 small justify-content-lg-center">
            <li class="nav-item">
                <a class="nav-link" [ngClass]="{ 'active font-weight-bold': showTab == 'generalData'}"
                    (click)="showTab = 'generalData'"><span class="text" title="Datos generales">Datos
                        generales</span></a>
            </li>
            <li class="nav-item">
                <a class="nav-link" [ngClass]="{ 'active font-weight-bold': showTab == 'details'}"
                    (click)="showTab = 'details'"><span class="text" title="Detalles de la actividad">{{
                        translate.instant("Detalles de la actividad") }}</span></a>
            </li>
            <li class="nav-item">
                <a class="nav-link" [ngClass]="{ 'active font-weight-bold': showTab == 'modalities'}"
                    (click)="showTab = 'modalities'"><span class="text" title='Modalidades'>{{
                        translate.instant("Modalidades") }}</span></a>
            </li>
            <li class="nav-item">
                <a class="nav-link" [ngClass]="{ 'active font-weight-bold': showTab == 'multimedia'}"
                    (click)="showTab = 'multimedia'"><span class="text" title='Multimedia'>{{
                        translate.instant("Multimedia") }}</span></a>
            </li>
        </ul>
        <div class="card second-card">
            <div *ngIf="showTab == 'generalData'" class="generalData w-100">
                <div class="general-data row m-0 justify-content-center mb-5">
                    <div class="col-lg-6 col-md-12 p-0">
                        <div class="w-100">
                            <div class="py-2 my-border-bottom d-flex flex-column">
                                <strong>ID Venntur</strong>
                                <span>{{experience.id ? experience.id : 'No disponible' }}</span>
                            </div>
                            <div class="py-2 my-border-bottom d-flex flex-column">
                                <strong>Título</strong>
                                <span class="clamp" [title]="experience.name">{{experience.name}}</span>
                            </div>
                            <div class="py-2 my-border-bottom d-flex flex-column">
                                <strong>Categoría</strong>
                                <span>{{experience.categories[0].name}}</span>
                            </div>
                            <div class="py-2 my-border-bottom d-flex flex-column">
                                <strong>Subategoría/s</strong>
                                <div class="d-flex">
                                    <span class="clamp" [title]="setTitle(experience.categories)"
                                        *ngFor="let category of experience.categories.slice(1);let last = last">{{category.name}}<ng-container
                                            *ngIf="!last">,&nbsp;</ng-container></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 row p-0">
                        <div class="w-100">
                            <div class="py-2 my-border-bottom d-flex flex-column">
                                <strong>ID Channel Manager</strong>
                                <span>{{ experience.channelManagerId ? experience.channelManagerId : 'No disponible' }}</span>
                            </div>
                            <div class="py-2 my-border-bottom d-flex flex-column">
                                <strong>Destino</strong>
                                <span>{{experience.location.name}}</span>
                            </div>
                            <div class="py-2 my-border-bottom d-flex flex-column">
                                <strong>Etiquetas</strong>
                                <div class="d-flex">
                                    <span class="clamp" [title]="setTitle(experience.tags)"
                                        *ngFor="let tag of experience.tags; let last = last">{{tag.name}}<ng-container
                                            *ngIf="!last">,&nbsp;</ng-container></span>
                                </div>
                            </div>
                            <div class="py-2 my-border-bottom d-flex flex-column">
                                <strong>Idiomas</strong>
                                <div class="d-flex">
                                    <span class="clamp" [title]="setTitle(experience.languages)"
                                        *ngFor="let language of experience.languages; let last = last">{{language.name}}<ng-container
                                            *ngIf="!last">,&nbsp;</ng-container></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="grey-box rounded">
                    <div class="mb-3 d-flex flex-column">
                        <strong class="mb-3">
                            Descripción
                        </strong>
                        <span>
                            {{experience.description}}
                        </span>
                    </div>
                    
                </div>
            </div>
            <div *ngIf="showTab == 'details'" class="details w-100">
                <div class="grey-box rounded">

                    <div class="row mb-2" *ngIf="experience.descriptionExtended">
                        <div class="col-12">
                            <div class="d-flex flex-column">
                                <strong class="my-border-bottom mb-2 pb-2" style="font-size: 18px;">
                                    Descripción extendida
                                </strong>
                                <span [innerHTML]="experience.descriptionExtended" *ngIf="experience.descriptionExtended">
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-6">
                            <div class="d-flex flex-column">
                                <strong class="my-border-bottom mb-2 pb-2" style="font-size: 18px;">
                                    Qué incluye
                                </strong>
                                <ul *ngIf="experience.includes">
                                    <li *ngFor="let includes of experience.includes">
                                        {{includes}}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="d-flex flex-column">
                                <strong class="my-border-bottom mb-2 pb-2" style="font-size: 18px;">
                                    Excluído
                                </strong>
                                <ul *ngIf="experience.notIncludes">
                                    <li *ngFor="let notIncludes of experience.notIncludes">
                                        {{notIncludes}}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="d-flex flex-column">
                                <strong class="my-border-bottom mb-2 pb-2" style="font-size: 18px;">
                                    ¿Qué debes llevar?
                                </strong>
                                <ul *ngIf="experience.carryInfo">
                                    <li *ngFor="let carryInfo of experience.carryInfo">
                                        {{carryInfo}}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="d-flex flex-column">
                                <strong class="my-border-bottom mb-2 pb-2" style="font-size: 18px;">
                                    ¿Qué debes saber?
                                </strong>
                                <ul *ngIf="experience.moreInfo">
                                    <li *ngFor="let moreInfo of experience.moreInfo">
                                        {{moreInfo}}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="row">
                        <div class="col-lg-6">
                            <div class="d-flex flex-column mb-5">
                                <strong class="my-border-bottom mb-2 pb-2" style="font-size: 18px;">
                                    Highlight 1
                                </strong>
                                <span>
                                    xxx
                                </span>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="d-flex flex-column mb-5">
                                <strong class="my-border-bottom mb-2 pb-2" style="font-size: 18px;">
                                    Highlight 2
                                </strong>
                                <span>
                                    xxx
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="d-flex flex-column mb-5">
                                <strong class="my-border-bottom mb-2 pb-2" style="font-size: 18px;">
                                    Highlight 3
                                </strong>
                                <span>
                                    xxx
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="d-flex flex-column mb-5">
                                <strong class="my-border-bottom mb-2 pb-2" style="font-size: 18px;">
                                    Abstract
                                </strong>
                                <span>
                                    xxx
                                </span>
                            </div>
                        </div>
                    </div> -->
                    <div class="row">
                        <div class="col">
                            <div class="d-flex flex-column mb-5">
                                <strong class="my-border-bottom mb-2 pb-2" style="font-size: 18px;">
                                    Política de cancelaciones y cambios
                                </strong>
                                <span *ngIf="experience.cancellation">
                                    {{experience.cancellation}}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="showTab == 'modalities'" class="modalities w-100">
                <div class="row mb-5">
                    <div class="col-12">
                        <button class="btn btn-primary mx-1" style="font-size: 14px;"
                            *ngFor="let modality of experience.modalities;let i = index" (click)="setModality(i)">Modalidad {{i+1}}</button>
                    </div>
                </div>
                <div class="d-flex flex-column">
                    <div class="py-2 my-border-bottom d-flex flex-column">
                        <strong>ID Venntur</strong>
                        <span>{{ experience.id ? experience.id : 'No disponible' }}</span>
                    </div>
                    <div class="py-2 my-border-bottom d-flex flex-column">
                        <strong>ID Channel Manager</strong>
                        <span>{{ experience.modalities[currentModality].bookingEngineId ? experience.modalities[currentModality].bookingEngineId : 'No disponible' }}</span>
                    </div>
                    <div class="py-2 my-border-bottom d-flex flex-column" *ngIf="experience.modalities[currentModality].title">
                        <strong>Título modalidad {{currentModality+1}}</strong>
                        <span>{{experience.modalities[currentModality].title}}</span>
                    </div>
                    <div class="py-2 my-border-bottom d-flex flex-column" *ngIf="experience.modalities[currentModality].description">
                        <strong>Descripción modalidad {{currentModality+1}}</strong>
                        <span>{{experience.modalities[currentModality].description}}</span>
                    </div>
                    <div class="py-2 my-border-bottom d-flex flex-column" *ngIf="experience.modalities[currentModality].bookingEngineId">
                        <strong>BE option ID</strong>
                        <span>{{experience.modalities[currentModality].bookingEngineId}}</span>
                    </div>
                    <div class="py-2 my-border-bottom d-flex flex-column" *ngIf="experience.modalities[currentModality].duration">
                        <strong>Duración</strong>
                        <span>{{experience.modalities[currentModality].duration}}</span>
                    </div>
                    <div class="py-2 my-border-bottom d-flex flex-column" *ngIf="experience.modalities[currentModality].start">
                        <strong>Hora de inicio</strong>
                        <span>{{experience.modalities[currentModality].start}}</span>
                    </div>
                    <div class="py-2 my-border-bottom d-flex flex-column" *ngIf="experience.modalities[currentModality].end">
                        <strong>Hora de finalización</strong>
                        <span>{{experience.modalities[currentModality].end}}</span>
                    </div>
                    <div class="py-2 my-border-bottom d-flex flex-column" *ngIf="experience.modalities[currentModality].meetingPoint">
                        <strong>Punto de encuentro</strong>
                        <span>{{experience.modalities[currentModality].meetingPoint}}</span>
                    </div>
                    <div class="py-2 my-border-bottom d-flex flex-column">
                        <strong>Servicio de recogida</strong>
                        <span>{{experience.modalities[currentModality].pickup == true ? 'Si' : 'No'}}</span>
                    </div>
                    <div class="py-2 my-border-bottom d-flex flex-column" *ngIf="experience.modalities[currentModality].location">
                        <strong>Ubicación</strong>
                        <span>{{experience.modalities[currentModality].location}}</span>
                    </div>

                    <!-- Precios -->
                    <div class="py-2 my-border-bottom d-flex flex-column" *ngIf="experience.modalities[currentModality].prices && experience.modalities[currentModality].prices.length > 0">
                        <strong>Precios</strong>
                        <div class="d-flex flex-column mt-3">
                            <div class="d-flex flex-row" *ngFor="let price of experience.modalities[currentModality].prices">
                                <strong>
                                    {{price.name}}:&nbsp;
                                </strong>
                                <span>{{price.price}}€</span>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div *ngIf="showTab == 'multimedia'" class="multimedia w-100">
                <div class="grey-box rounded mb-5 text-center">
                    <strong>
                        Seleccione una imagen para descargar
                    </strong>
                    <div class="row mb-5">
                        <div *ngFor="let mediaExperience of experience.mediaExperiences"
                            class="col-lg-3 col-md-6 col-12 p-0">
                            <div class="position-relative image-container" (click)="saveImage(mediaExperience.contentUrl)">
                                <img id="prueba" [src]="'https://venntur.com/assets/experience/files/' + mediaExperience.contentUrl"
                                    alt="Imagen de la experiencia">
                                <div class="dot-selection">
                                    <span class="vi-check" *ngIf="savedUrls.includes('https://venntur.com/assets/experience/files/' + mediaExperience.contentUrl)"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="button-container d-flex flex-sm-row flex-column align-items-center justify-content-center" style="margin-bottom: 66px;">
                        <button class="btn btn-primary" [disabled]="savedUrls.length == 0" (click)="downloadAllFiles(savedUrls)">Descargar ({{savedUrls.length}}) </button>
                        <button class="btn btn-primary" [disabled]="disableDownload" (click)="downloadAllFiles(allImages)">Descargar todas</button>
                    </div>
                    <strong>
                        Seleccione un vídeo para descargar
                    </strong>

                    <div class="row mb-5">
                        <div *ngFor="let mediaExperience of experience.mediaExperiencesVideos"
                            class="col-lg-3 col-md-6 col-12 p-0">
                            <div class="position-relative image-container videoPin-container mx-3" (click)="saveVideo(mediaExperience.contentUrl)">
                                <video width="100%" class="videoPin" [src]="mediaExperience.contentUrl" [id]="mediaExperience.contentUrl">
                                </video>
                                <div class="dot-selection">
                                    <span class="vi-check" *ngIf="savedVideoUrls.includes(mediaExperience.contentUrl)"></span>
                                </div>
                            </div>
                        </div>
                    </div>

                   <!--  <div class="row mb-5">
                        <div *ngFor="let mediaExperience of experience.mediaExperiences"
                            class="col-lg-3 col-md-6 col-12 p-0">
                            <div class="position-relative image-container">
                                <img [src]="'https://venntur.com/assets/experience/files/' + mediaExperience.contentUrl"
                                    alt="Imagen de la experiencia">
                                <span class="dot-selection"></span>
                            </div>
                        </div>
                    </div> -->


                    <div class="button-container d-flex flex-sm-row flex-column align-items-center justify-content-center">
                        <button class="btn btn-primary" [disabled]="savedVideoUrls.length == 0" (click)="downloadAllVideos(savedVideoUrls)">Descargar ({{savedVideoUrls.length}}) </button>
                        <button class="btn btn-primary" [disabled]="disableDownload">Descargar todos</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>