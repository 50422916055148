import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService, SocialUser } from 'angularx-social-login';
import { User } from '../../../shared/users/user.model';
import { UsersService } from '../../../shared/users/users.service';
import { AuthService } from '../../shared/auth.service';
import { errorMessages, regExps } from '../../shared/custom-validators';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import { MakerService } from '../../shared/maker.service';

const FRONT_URL = environment.frontUrl;

@Component({
  selector: 'app-register-maker',
  templateUrl: './register-maker.component.html',
})
export class RegisterMakerComponent implements OnInit {
  userForm: UntypedFormGroup;
  user: User;
  alert = { message: null, type: null };
  errors = errorMessages;
  plan: string;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private socialAuthService: SocialAuthService,
    private userService: UsersService,
    private makerService: MakerService,
    public translate: TranslateService
  ) {
    this.createForm();
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.plan = params['plan'];
    });
  }

  createForm() {
    this.userForm = this.formBuilder.group({
      email: ['', [
        Validators.required,
        Validators.email
      ]],
      password: ['', [
        Validators.required,
        Validators.pattern(regExps.password)
      ]
      ],
      role: ['ROLE_MAKER', Validators.required]
    });
  }

  createUser() {
    if (this.userForm.invalid) {
      this.showErrors(this.userForm);
      return;
    }
    const user = {
      email: this.userForm.get('email').value,
      password: this.userForm.get('password').value,
      roles: this.userForm.get('role').value
    };
    this.userService.postUser(user).subscribe((result) => {
      this.alert.type = 1;
      this.alert.message = 'Usuario creado correctamente';
      setTimeout(() => {
        this.alert = { message: null, type: null };
        this.authService.login(user.email, user.password)
          .then(() => {
            if (user.roles.includes('ROLE_MAKER')) {
              this.createMakerPlan(result.user, user.password);
            }
            // } else if (user.roles.includes('ROLE_TAKER')) {
            //   this.router.navigateByUrl(this.translate.getDefaultLang() + '/taker/admin/perfil');
            // }
          })
          .catch();
      }, 1000);
      this.createForm();
    }, (error) => {
      this.alert.message = error.error.message;
      this.alert.type = 2;
      setTimeout(() => {
        this.alert = { message: null, type: null };
      }, 5000);
    });
  }

  signInWithFB(): void {
    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID).then((user: SocialUser) => {
      this.authService.loginByFacebook(user, 'ROLE_MAKER')
        .then(
          (response) => {
            // this.alert.type = 1;
            // this.alert.message = `Ya casi hemos acabado... Te hemos enviado un correo al email con el que te has registrado.
            // Por favor, ve a tu bandeja de entrada y confirma el registro.`;
            // setTimeout(() => {
            //   this.alert = {message: null, type: null};
            // }, 5000);
            // this.createMakerPlan(user.email);
          },
          (error) => {
            this.alert.type = 2;
            this.alert.message = 'Ocurrió un error al intentar registrarte con Facebook, por favor vuelve a intentarlo';
          });
    });
  }

  signInWithGoogle(): void {
    this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then((user: SocialUser) => {
      this.authService.loginByGoogle(user.idToken, 'ROLE_MAKER')
        .then(
          (response) => {
            // this.alert.type = 1;
            // this.alert.message = `Ya casi hemos acabado... Te hemos enviado un correo al email con el que te has registrado.
            // Por favor, ve a tu bandeja de entrada y confirma el registro.`;
            // setTimeout(() => {
            //   this.alert = {message: null, type: null};
            // }, 5000);
            // this.createMakerPlan(user);
          },
          (error) => {
            this.alert.type = 2;
            this.alert.message = 'Ocurrió un error al intentar registrarte con Google, por favor vuelve a intentarlo';
          });
    });
  }

  showErrors(formGroup) {
    Object.keys(formGroup.controls).forEach(key => {
      formGroup.get(key).markAsTouched();
    });
    return;
  }

  showError(formValue) {
    return this.userForm.get(formValue).invalid && this.userForm.get(formValue).touched;
  }

  createMakerPlan(user, password) {
    console.log(user);
    let maker: any = {
      "email": user.email,
      "name": user.email,
      "user": '/users/' + user.id,
      "slug": user.email
    }
    this.makerService.postMaker(maker).subscribe((makerResult) => {
      let makerPlan = {
        "price": '0',
        "startDate": new Date(),
        "maker": "/makers/" + makerResult.id,
        "plan": "/plans/df432580-3b93-11eb-80af-509a4c653a6c",
        "stripeCustomerId": "GRATUITO"
      }
      maker.plan = "START";
      maker.creation = (new Date()).toLocaleDateString('en-GB');
      this.makerService.postFreeMakerPlan(makerPlan).subscribe((result) => {
        let directoryAnalytics = {
          maker: "/makers/" + makerResult.id
        }
        this.makerService.postDirectoryAnalytics(directoryAnalytics).subscribe((result) => {
          this.makerService.postMakerContact(maker).subscribe((result) => {
            this.authService.login(user.email, password)
              .then(() => {
                this.router.navigateByUrl(this.translate.getDefaultLang() + '/maker/admin/perfil/');
                // } else if (user.roles.includes('ROLE_TAKER')) {
                //   this.router.navigateByUrl(this.translate.getDefaultLang() + '/taker/admin/perfil');
                // }
              })
          })
        })
      })
    })
    // if (this.plan === 'gratuito') {
    //   this.makerService.postFreeMakerPlan({price: 0, email: makerEmail});
    //   this.router.navigateByUrl(this.translate.getDefaultLang() + '/maker/admin/perfil');
    // } else {
    //   let planPrice = null;
    //   if (this.plan === 'basico') {
    //     planPrice = 'price_1HtuT8DQOX9k1ARpo2xGkQpJ';
    //   } else if (this.plan === 'pro') {
    //     planPrice = '';
    //   }
    //   // @ts-ignore
    //   const stripe = Stripe('pk_test_my9mJE7a5hoQWNV7HSnFvCY0');
    //   stripe.redirectToCheckout({
    //     lineItems: [{price: planPrice, quantity: 1}],
    //     mode: 'subscription',
    //     successUrl: `${FRONT_URL}${this.translate.getDefaultLang()}/maker/admin/perfil`,
    //     cancelUrl: `${FRONT_URL}${this.translate.getDefaultLang()}`,
    //   })
    //     // tslint:disable-next-line:only-arrow-functions no-shadowed-variable
    //     .then(function(result) {
    //       if (result.error) {
    //         const displayError = document.getElementById('error-message');
    //         displayError.textContent = result.error.message;
    //       }
    //     });
    // }
  }
}
