import { Location, LocationStrategy } from '@angular/common';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AuthService } from '../../pages/shared/auth.service';
import { MakerService } from '../../pages/shared/maker.service';
import { TakerService } from '../../pages/shared/taker.service';
import { TranslateService } from '@ngx-translate/core';
import { ResellerService } from '../../pages/shared/reseller.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { trigger, state, style, animate, transition } from '@angular/animations';


@Component({
  selector: 'app-navbar',
  styleUrls: ['./navbar.component.scss'],
  templateUrl: './navbar.component.html',
  animations: [
    trigger('openClose', [
      // ...
      state('open', style({
        width: '80%',
      })),
      state('closed', style({
        width: '0px',
      })),
      transition('open => closed', [
        animate('0.25s')
      ]),
      transition('closed => open', [
        animate('0.25s')
      ]),
    ]),
  ],
})
export class NavbarComponent implements OnInit {
  @ViewChild('loginModalContent') loginModalContent: TemplateRef<any>;
  isAdmin = false;
  modalRef: BsModalRef;
  public iconOnlyToggled = false;
  public activeLang = 'es';
  loginData: any = {};
  isOpen = false;

  constructor(
    public authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    public makerService: MakerService,
    public takerService: TakerService,
    public resellerService: ResellerService,
    private modalService: BsModalService,
    public translate: TranslateService
  ) {
    router.events.subscribe(val => {
      if (location.path().indexOf('/' + this.activeLang + '/maker/admin/') === 0 ||
        location.path().indexOf('/' + this.activeLang + '/taker/admin/') === 0 ||
        location.path().indexOf('/' + this.activeLang + '/reseller/admin/') === 0) {
        this.isAdmin = true;
      }
    });
    this.activeLang = location.path().substring(1, 3);
    this.translate.setDefaultLang(this.activeLang === 'ev' ? 'es' : this.activeLang);
  }

  ngOnInit(): void {
    // this.adjustSiblingMargin();
    setTimeout(() => {
      this.loginData.email = this.activatedRoute.snapshot.queryParamMap.get('email');
      this.loginData.password = this.activatedRoute.snapshot.queryParamMap.get('pk');

      if (this.activatedRoute.snapshot.queryParamMap.get('login') == 'true') {
        this.openLoginModal(this.loginModalContent);
      }
    }, 500);
  }

  logout() {
    this.isAdmin = false;
    this.authService.logout();
    // this.router.navigateByUrl('/' + this.translate.getDefaultLang());
    location.replace('/' + this.translate.getDefaultLang());
  }

  // login() {
  //   this.router.navigateByUrl('/login');
  // }

  openLoginModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  // toggle sidebar
  toggleSidebar() {
    let body = document.querySelector('body');
    this.iconOnlyToggled = !this.iconOnlyToggled;
    if (this.iconOnlyToggled) {
      body.classList.add('sidebar-icon-only');
    } else {
      body.classList.remove('sidebar-icon-only');
    }
  }

  public changeLanguage(event) {
    this.activeLang = event.target.value.toLowerCase();
    this.translate.use(event.target.value.toLowerCase());
    window.open(`/${event.target.value.toLowerCase()}${this.router.url.substring(3)}`, '_self');
  }

  changeMobileLanguage(event) {
    this.activeLang = event.target.textContent.toLowerCase();
    this.translate.use(event.target.textContent.toLowerCase());
    window.open(`/${event.target.textContent.toLowerCase()}${this.router.url.substring(3)}`, '_self');
  }

  getFirstWord(phrase: string): string {
    // Split the phrase into words using spaces as a delimiter
    const words = phrase.split(' ');

    // Check if there are words in the phrase
    if (words.length > 0) {
      if (words[0].length < 11) {
        // Return the first word
        return words[0] + '...';
      } else {
        return words[0].slice(0, 10) + '...';
      }

    } else {
      // If there are no words, return an empty string or handle the case accordingly
      return '';
    }
  }

  //Burguer menu
  openBurguerMenu() {
    this.isOpen = !this.isOpen;
  }

  // adjustSiblingMargin() {
  //   const absoluteDiv = document.getElementById('navbar');
  //   const siblingDiv = document.getElementById('open-close-container');
  //   console.log(absoluteDiv.clientHeight + 11.77);
  //   const absoluteDivHeight = absoluteDiv.offsetHeight + 11.77;
  //   siblingDiv.style.marginTop = absoluteDivHeight + 'px';
  // }
}
