import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ReviewService } from '../review-form/review.service';
import { Subscription } from 'rxjs';
import { BsModalRef, BsModalService, ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-reviews',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.scss']
})
export class ReviewsComponent implements OnInit {
  @Input() receivedData: any;
  private reloadSubscription: Subscription;

  modalRef: BsModalRef;
  @ViewChild('autoShownModal', { static: false }) autoShownModal: ModalDirective;
  isAlertShown = false;

  viewEvenMore = false;
  showedReviews = 5;
  ratingSumatory = 0;

  constructor(
    private reloadService: ReviewService,
    private modalService: BsModalService,
  ) {
    this.reloadSubscription = this.reloadService.getReloadObservable().subscribe(() => {
      this.reloadComponent();
    });
  }

  ngOnInit(): void {
    const options = { method: 'GET', headers: { accept: 'application/json' } };

    fetch('https://api.venntur.com/tripadvisor_reviews', options)
      .then(response => response.json())
      .then(response => console.log(response))
      .catch(err => console.error(err));
    setTimeout(() => {
      console.log(this.receivedData);
      
      this.receivedData.forEach(receivedElement => {
        console.log(this.ratingSumatory);

        console.log(receivedElement.rating);

        this.ratingSumatory = this.ratingSumatory + receivedElement.rating;
      });
    }, 2000);
  }

  ngOnDestroy() {
    this.reloadSubscription.unsubscribe();
  }

  fixedNumber(number) {
    return number.toFixed(2)
  }

  reloadComponent() {
    // Perform any necessary actions to reload your component
    console.log('Sibling 2 has been reloaded.');
  }

  getClassNames(i: number, last: boolean): string {
    if (i === 0) {
      return 'mb-3';
    } else if (last) {
      return 'mt-3';
    } else {
      return 'my-3';
    }
  }

  obtainTimeString(dateStr: string) {
    const date = new Date(dateStr);
    const currentDate = new Date();
    const ranTime = currentDate.getTime() - date.getTime();

    const oneMinute = 60 * 1000;
    const oneHour = oneMinute * 60;
    const oneDay = oneHour * 24;
    const oneWeek = oneDay * 7;
    const oneMonth = oneDay * 30;

    if (ranTime < oneMinute) {
      return 'Ahora';
    } else if (ranTime < oneHour) {
      return 'Hace menos de una hora';
    } else if (ranTime < oneDay) {
      return 'Hoy';
    } else if (ranTime < oneWeek) {
      return 'Hace unos días';
    } else if (ranTime < oneMonth) {
      return 'Hace más de una semana';
    } else {
      return 'Hace un mes o más';
    }
  }

  onHidden(): void {
    this.isAlertShown = false;
  }

  hideModal(): void {
    this.autoShownModal.hide();
  }

  openModal() {
    this.isAlertShown = true;
  }

}
