import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ExperiencesService } from './experiences.service';
import { Experience } from './experience.model';
import { HttpEventType } from '@angular/common/http';
import { MediaExperiencesService } from './media-experiences.service';
import { ReplaySubject } from 'rxjs';
import { Category } from '../../../shared/categories/category.model';
import { Language } from '../../../shared/languages/language.model';
import { Country } from '../../../shared/locations/country.model';
import { Location } from '../../../shared/locations/location.model';
import { CategoriesService } from '../../../shared/categories/categories.service';
import { LanguagesService } from '../../../shared/languages/languages.service';
import { CountryService } from '../../../shared/locations/country.service';
import { LocationService } from '../../../shared/locations/location.service';
import { MakerService } from '../../shared/maker.service';
import { errorMessages, regExps } from '../../shared/custom-validators';
import { Tag } from '../../../shared/tags/tag.model';
import { TagsService } from '../../../shared/tags/tags.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { environment } from '../../../../environments/environment';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { ModalAlertComponent } from 'src/app/shared/modal-alert/modal-alert.component';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const FRONT_URL = environment.frontUrl;
@Component({
  selector: 'app-experiences',
  templateUrl: './experiences.component.html',
  styleUrls: ['./experiences.component.scss'],
})
export class ExperiencesComponent implements OnInit {
  public Editor = ClassicEditor;

  @Output() newItemEvent = new EventEmitter<Experience>();

  experienceForm: UntypedFormGroup;
  experience: Experience;
  modalRef: BsModalRef;
  bsConfig?: Partial<BsDatepickerConfig>;
  categories: Category[] = [];
  allCategories: Category[] = [];
  subcategories: Category[] = [];
  categoriesSelected = [];
  subcategoriesSelected = [];
  myPreviewCategories = [];
  myPreviewSubcategories = [];
  myPreviewLanguages = [];
  myPreviewLocation = '';
  typePriceSelected = 0;
  weekDaySelected = [];
  monthSelected = [];
  tags: Tag[] = [];
  tagsSelected = [];
  languages: Language[] = [];
  languagesSelected = [];
  promotionTypes = ['Descuento', '2x1', 'Niños gratis'];
  promotionTypeSelected = null;
  selectedFile: File;
  selectedFiles = [];
  uploadedFiles = [];
  mediaExperiences = [];
  expandedMediaExperiences = [];
  uploadingProgressing = false;
  uploadComplete = false;
  serverResponse: any;
  uploadProgress = 0;
  alert = { message: null, type: null };
  errors = errorMessages;
  countries = [];
  allLocations = [];
  maker = null;
  makerName = '';
  myMakerLogo = null;
  editorTemplateStaticData = '';
  editorTemplate: any;
  images = [];
  title = 'Nueva experiencia';
  includesSelected = [];
  notIncludesSelected = [];
  moreInfoSelected = [];
  isDisabled = true;
  currencies = ['€', '$', '£', '¥', '₩', '₽', '₹', 'R$'];
  highlights = [];
  highlightsCount = [];

  modalitiesIris = [];
  modalitiesDelete = [];
  selectedModal: any;

  isMicroSheetValid = false;
  isWebSheetValid = false;
  isPinValid = false;

  uploadedVideos = [];
  loadingVideo = false;
  progressVideo = 0;

  isTooManyImages = false;

  modalities = [
    {
      title: '',
      description: '',
      bookingEngineId: 0,
      prices: [
        {
          name: '',
          price: 0,
        }
      ],
      pickup: false,
      pickupStr: '',
      duration: '',
      start: '',
      end: '',
      meetingPoint: '',
      location: '',
      experience: '',
    },
  ];

  modalitiesObject = {
    title: '',
    description: '',
    bookingEngineId: 0,
    prices: [{
      name: '',
      price: '',
    }],
    pickup: false,
    duration: '',
    start: '',
    end: '',
    meetingPoint: '',
    location: '',
  };

  isExpanded: boolean[] = [];
  // languagesWeb = ['Español'['es'], 'English'['en']];
  // languageWebSelected = []

  public filteredCountries: ReplaySubject<Country[]> = new ReplaySubject<
    Country[]
  >(1);
  countrySelected = null;

  locations: Location[] = [];
  public filteredLocations: ReplaySubject<Location[]> = new ReplaySubject<
    Location[]
  >(1);
  locationSelected = null;

  zones: any = [];
  public filteredZone: ReplaySubject<Zone[]> = new ReplaySubject<Zone[]>(1);
  zoneSelected = null;

  // whyUsText = '';
  descriptionText = '';
  descriptionMaxChar = 600;

  monthsList = [
    { value: 12, text: 'Todos los meses' },
    { value: 0, text: 'Enero' },
    { value: 1, text: 'Febrero' },
    { value: 2, text: 'Marzo' },
    { value: 3, text: 'Abril' },
    { value: 4, text: 'Mayo' },
    { value: 5, text: 'Junio' },
    { value: 6, text: 'Julio' },
    { value: 7, text: 'Agosto' },
    { value: 8, text: 'Septiembre' },
    { value: 9, text: 'Octubre' },
    { value: 10, text: 'Noviembre' },
    { value: 11, text: 'Diciembre' }
  ]

  urlButtonTexts = [
    { value: 'more-info', text: '+ Info' },
    { value: 'book', text: 'Reservar' },
  ];

  typePrices = [
    { value: 0, text: 'Por persona' },
    { value: 1, text: 'Por Servicio' },
  ];
  weekDays = [
    { value: 0, text: 'Todos los días' },
    { value: 1, text: 'Lun' },
    { value: 2, text: 'Mar' },
    { value: 3, text: 'Mié' },
    { value: 4, text: 'Jue' },
    { value: 5, text: 'Vie' },
    { value: 6, text: 'Sáb' },
    { value: 7, text: 'Dom' },
  ];
  dialogRef: BsModalRef;

  tabs = ['microSheet', 'sheet', 'pin']
  showTab = 'microSheet';

  civitatisId = '';

  makerInfo: any;

  bookingEngine = '';

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private experienceService: ExperiencesService,
    private categoryService: CategoriesService,
    private tagService: TagsService,
    private languageService: LanguagesService,
    private mediaExperienceService: MediaExperiencesService,
    private countryService: CountryService,
    private locationService: LocationService,
    // private zoneService: ZoneService,
    private makerService: MakerService,
    public translate: TranslateService,
    private modalService: BsModalService,
    public dialog: BsModalService
  ) {
    this.bsConfig = Object.assign({}, { showWeekNumbers: false, adaptivePosition: true });
    if (this.makerService.getId()) {
      this.maker = '/makers/' + this.makerService.getId();
      this.makerName = this.makerService.getName();
      this.makerService.getMaker('/makers/' + this.makerService.getId()).subscribe((maker) => {
        this.myMakerLogo = maker.mediaMakers[0].contentUrl;
        this.makerInfo = maker;

      });
      this.createForm();
    } else {
      this.router.navigateByUrl('/maker/admin/perfil');
    }
  }

  ngOnInit(): void {
    if (this.activatedRoute.snapshot.params.id) {
      this.editExperience(this.activatedRoute.snapshot.params.id);
    } else {
      setTimeout(() => {
        this.setRelations();
      }, 1000);
    }


    this.experienceForm.valueChanges.subscribe(changes => {
      this.checkMicroSheetContent();
      this.checkWebSheetContent();
      this.checkPinSheetContent();
    });

    this.newItemEvent.emit(this.experience);
  }

  createForm() {
    this.experienceForm = this.formBuilder.group({
      name: [null, Validators.required],
      promoCode: [''],
      url: [
        '',
        [
          // Validators.pattern(regExps.url),
          Validators.required
        ],
      ],
      urlButtonText: [
        '',
        // Validators.required
      ],
      description: [
        null,
        [
          Validators.required,
          Validators.minLength(120),
          Validators.maxLength(600),
        ],
      ],
      seasonStartMonth: [,],
      seasonEndMonth: [,],
      duration: [
        '', Validators.required
        // Validators.required
      ],
      promotionType: [,],
      promotion: [''],
      sustainableSeal: [false],
      reviewTripadvisor: [''],
      reviewGetyourguide: [''],
      mediaExperiences: [null, [Validators.required, Validators.minLength(5), Validators.maxLength(11)]],
      expandedMediaExperiences: [null, [Validators.minLength(5)]],
      maker: [
        this.maker,
        // Validators.required
      ],
      languages: [
        '', Validators.required
        // Validators.required
      ],
      categories: [
        '', Validators.required
        // Validators.required
      ],
      subcategories: [
        ''
      ],
      tags: [
        '',
        // Validators.required
      ],
      country: ['', Validators.required],
      location: ['', Validators.required],
      zones: [
        [],
        // Validators.required
      ],
      whyUs: ['', [Validators.required, Validators.minLength(120), Validators.maxLength(600)]],
      // tripAdv: [ '',
      //   Validators.required
      // ]
      persons: [
        null,
        // Validators.required
      ],
      cancellation: [
        '',
        // Validators.required
      ],
      includes: [[], Validators.required],
      notIncludes: [[], Validators.required],
      price: [
        '',
        // Validators.required
      ],
      moreInfo: [[], Validators.required],
      carryInfo: [[], Validators.required],
      slug: [''],
      bookingEngine: [''],
      bookingEngineWidget: [''],
      fareharborId: [''],
      bokunId: [''],
      turitopId: [''],
      typePrice: [
        0,
        // Validators.required
      ],
      // toDate: [
      //   null,
      //   // Validators.required
      // ],
      // fromDate: [
      //   null,
      //   // Validators.required
      // ],
      weekDays: [''],
      // languagesWeb : ['Español'['es'], 'English'['en']]
      status: [''],
      currency: [''],
      descriptionExtended: ['', Validators.required],
      highlights: [''],
      modalities: [[]],
      microsheetCompleted: [false],
      sheetCompleted: [false],
      months: ['', Validators.required]
    });

    setTimeout(() => {
      console.log(this.experience)
      if (!this.experience || !this.experience.bookingEngine) {
        this.experienceForm.get('bookingEngine').setValue(this.makerInfo?.bookingEngine);
        this.bookingEngine = this.makerInfo?.bookingEngine;
        this.experienceForm.get('bookingEngineWidget').setValue(this.makerInfo?.bookingEngineWidget);
      }
    }, 500);
  }

  setRelations() {
    this.categoryService
      .getCategories(this.translate.getDefaultLang())
      .subscribe((categories) => {

        this.allCategories = categories['hydra:member'];

        categories['hydra:member'].forEach((category) => {
          if (category.parents.length == 0) {
            this.categories.push(category);
          }
        });

        //this.categories = categories['hydra:member'];
      });
    this.tagService
      .getTags(this.translate.getDefaultLang())
      .subscribe((tags) => {
        this.tags = tags['hydra:member'];
      });
    this.languageService
      .getLanguages(this.translate.getDefaultLang())
      .subscribe((languages) => {
        this.languages = languages['hydra:member'];
      });
    this.countryService.getCountries().subscribe((countries) => {
      this.countries = countries;
    });
    this.locationService.getLocations().subscribe((locations) => {
      this.allLocations = locations['hydra:member'];
    });
    setTimeout(() => {
      this.loadPreviewCategories();
      this.loadPreviewSubcategories();
      this.loadPreviewLanguages();
      this.loadPreviewLocation();
    }, 3000);
  }

  deleteExperience(experienceId) {
    this.dialogRef = this.dialog.show(ConfirmationDialogComponent, {
      initialState: {
        confirmMessage: this.translate.instant(
          '¿Seguro que quieres eliminar esta experiencia?'
        ),
        callback: (confirm) => {
          if (confirm) {
            this.experienceService.deleteExperience(experienceId).subscribe(
              (result) => {
                this.alert.type = 1;
                this.alert.message = 'Experiencia eliminada correctamente';
                location.replace(
                  this.translate.getDefaultLang() + '/maker/admin/experiencias'
                );
                setTimeout(() => {
                  this.alert = { message: null, type: null };
                }, 5000);
              },
              (error) => {
                this.alert.message =
                  error.error.code + '- ' + error.error.message;
                this.alert.type = 2;
                setTimeout(() => {
                  this.alert = { message: null, type: null };
                }, 5000);
              }
            );
          }
          this.dialogRef = null;
        },
      },
    });
  }

  editExperience(experienceId) {
    this.experienceService
      .getExperience(experienceId, this.translate.getDefaultLang())
      .subscribe((experience: Experience) => {
        this.experience = experience;
        this.experienceForm.get('name').setValue(experience.name);
        this.experienceForm.get('promoCode').setValue(experience.promoCode);
        this.experienceForm
          .get('url')
          .setValue(experience.url ? experience.url : '');
        this.experienceForm
          .get('urlButtonText')
          .setValue(experience.urlButtonText);
        this.experienceForm.get('description').setValue(experience.description);
        this.experienceForm.get('duration').setValue(experience.duration);
        this.experienceForm
          .get('promotionType')
          .setValue(experience.promotionType);
        this.experienceForm.get('promotion').setValue(experience.promotion);
        this.experienceForm
          .get('sustainableSeal')
          .setValue(experience.sustainableSeal);
        this.experienceForm
          .get('reviewTripadvisor')
          .setValue(experience.reviewTripadvisor);
        this.experienceForm
          .get('reviewGetyourguide')
          .setValue(experience.reviewGetyourguide);
        this.experienceForm
          .get('seasonStartMonth')
          .setValue(experience.seasonStartMonth);
        this.experienceForm
          .get('seasonEndMonth')
          .setValue(experience.seasonEndMonth);
        const experiences = [];
        experience.languages.forEach((language) => {
          experiences.push(language['@id']);
        });
        this.experienceForm.get('languages').setValue(experiences);


        const categories = [];
        const categoriesObject = [];
        const subcategories = [];


        if (experience.mediaExperiencesVideos && experience.mediaExperiencesVideos.length > 0) {
          experience.mediaExperiencesVideos.forEach((video) => {
            this.uploadedVideos.push(video);
          });
        }


        experience.categories.forEach((category) => {

          if (category.parents.length == 0) {
            categories.push(category['@id']);
            categoriesObject.push(category);
          } else {

            subcategories.push(category['@id']);
            this.subcategories.push(category);
          }

        });

        this.experienceForm.get('categories').setValue(categories);

        this.experienceForm.get('subcategories').setValue(subcategories);

        setTimeout(() => {
          if (categories.length > 0) {
            this.loadSubcategories(categories);
          }
        }, 1000);

        const tags = [];
        if (experience.tags) {
          experience.tags.forEach((tag) => {
            tags.push(tag['@id']);
          });
          this.experienceForm.get('tags').setValue(tags);
        }
        this.experienceForm.get('country').setValue(experience.country);
        this.loadLocations(experience.country);
        this.experienceForm
          .get('location')
          .setValue(experience.location['@id']);

        if (this.experienceForm.get('location').value && experience.zones) {
          this.loadZones(this.experienceForm.get('location').value);

          var zonesIris = [];

          experience.zones.forEach((zone) => {
            zonesIris.push(zone['@id']);
          });

          this.experienceForm.get('zones').setValue(zonesIris);

        }

        this.mediaExperiences.length = 0;
        experience.mediaExperiences.forEach((mediaExperience) => {
          this.mediaExperiences.push({
            url: '/assets/experience/files/' + mediaExperience['contentUrl'],
            id: mediaExperience['@id'],
          });
          this.uploadedFiles.push(mediaExperience['@id']);
        });
        this.experienceForm
          .get('mediaExperiences')
          .setValue(this.uploadedFiles);

        // this.expandedMediaExperiences.length = 0;
        // experience.mediaExperiences.forEach((mediaExperience) => {
        //   this.expandedMediaExperiences.push({
        //     url: '/assets/experience/files/' + mediaExperience['contentUrl'],
        //     id: mediaExperience['@id'],
        //   });
        //   this.uploadedFiles.push(mediaExperience['@id']);
        // });
        // this.experienceForm
        //   .get('expandedMediaExperiences')
        //   .setValue(this.uploadedFiles);

        this.experienceForm.get('whyUs').setValue(experience.whyUs);
        this.experienceForm.get('includes').setValue(experience.includes);
        this.experienceForm.get('notIncludes').setValue(experience.notIncludes);
        this.experienceForm.get('moreInfo').setValue(experience.moreInfo);
        this.experienceForm.get('carryInfo').setValue(experience.carryInfo);
        this.experienceForm.get('persons').setValue(experience.persons);
        this.experienceForm
          .get('cancellation')
          .setValue(experience.cancellation);
        this.experienceForm.get('price').setValue(experience.price);
        this.experienceForm.get('slug').setValue(experience.slug);
        this.experienceForm.get('bookingEngine').setValue(experience.bookingEngine);
        this.experienceForm.get('bookingEngineWidget').setValue(experience.bookingEngineWidget);
        this.experienceForm
          .get('fareharborId')
          .setValue(experience.fareharborId);
        this.experienceForm.get('turitopId').setValue(experience.turitopId);
        this.experienceForm.get('bokunId').setValue(experience.bokunId);
        this.experienceForm.get('weekDays').setValue(experience.weekDays);
        this.experienceForm.get('typePrice').setValue(experience.typePrice);
        this.experienceForm.get('status').setValue(experience.status);
        this.experienceForm.get('currency').setValue(experience.currency);
        this.experienceForm
          .get('descriptionExtended')
          .setValue(experience.descriptionExtended);
        this.experienceForm.get('highlights').setValue(experience.highlights);
        // this.convertDate(
        //   this.experienceForm
        //     .get('toDate')
        //     .setValue(this.convertDate(experience.toDate))
        // );
        // this.convertDate(
        //   this.experienceForm
        //     .get('fromDate')
        //     .setValue(this.convertDate(experience.fromDate))
        // );

        if (experience.modalities.length > 0) {
          this.modalities = experience.modalities;

          experience.modalities.forEach((modality, index) => {
            modality.pickupStr = modality.pickup.toString();
            this.isExpanded.push(false);
          });

        }

        this.setRelations();
        this.setWeekDays(experience.weekDays);
        this.experienceForm.get('months').setValue(experience.months);
        this.setMonths(experience.weekDays);
        this.editorTemplate = this.experienceForm.get('descriptionExtended')
        this.title = 'Editar experiencia: '
      });


  }

  createModalities() {

    if (this.modalitiesDelete.length > 0) {
      this.modalitiesDelete.forEach((modality, index) => {
        this.experienceService.deleteModality(modality).subscribe((result) => {
        });
      });
    }

    if (this.modalities.length > 0) {

      this.modalities.forEach((modality, index) => {

        modality.bookingEngineId = Number(modality.bookingEngineId);

        if (modality.pickupStr == "true" || modality.pickupStr == "Si" || modality.pickupStr == "si" && modality.pickup != false) {
          modality.pickup = true;
          modality.pickupStr = null;

        } else {
          modality.pickup = false;
          modality.pickupStr = null;
        }

        if (modality['@id'] != null && modality['@id'] != undefined) {
          this.experienceService.putModality(modality['@id'], modality).subscribe((modality) => {

            this.modalitiesIris.push(modality['@id']);

            // if (this.modalities.length == this.modalitiesIris.length) {
            //   this.updateExperience();
            // } else {
            //   //todo: Update de la experiencia cuando la guardamos por segunda vez
            //   this.updateExperience();
            // }

          });

        } else {

          modality.experience = modality.experience ? modality.experience : null;

          if (modality.title != null && modality.title != undefined) {

            this.experienceService.postModality(modality).subscribe((modality) => {
              this.modalitiesIris.push(modality['@id']);
              // if (this.modalities.length == this.modalitiesIris.length) {
              //   this.updateExperience();
              // }
            });
          } else {
            // if (this.modalities.length == this.modalitiesIris.length) {
            //   this.updateExperience();
            // }
          }
        }
        if (index == this.modalities.length - 1) {
          this.updateExperience();
        }
      });
    } else {
      this.updateExperience();
    }

  }

  async updateExperience() {

    this.experienceForm.markAllAsTouched();

    let auxStatus = '';
    if (this.experienceForm.valid) {
      auxStatus = 'completa';
    } else {
      auxStatus = 'incompleta';
    }

    let slug = await this.convertSlug(this.experienceForm.get('name').value);

    let modalitiesIris = this.modalitiesIris;

    const categories = [];
    const subcategories = [];

    this.experienceForm.get('categories').value.forEach((category) => {
      categories.push(category);
    });

    this.experienceForm.get('subcategories').value.forEach((subcategory) => {
      categories.push(subcategory);
    });

    let months = [];

    if (this.experienceForm.get('months').value == 12) {
      months = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
    } else {
      months = this.experienceForm.get('months').value;
    }

    // If 'Todas las zonas' is selected, then push to the api all the IRI's of the location zones
    // If it's not selected, then push the value of the formControl
    let allZones = [];
    if (this.experienceForm.get('zones').value == 'all-zones') {
      this.zones.forEach(zone => {
        allZones.push(zone['@id'])
      });
    }

    const experience: any = {
      name: this.experienceForm.get('name').value,
      promoCode: this.experienceForm.get('promoCode').value,
      url: this.experienceForm.get('url').value,
      urlButtonText: this.experienceForm.get('urlButtonText').value,
      description: this.experienceForm.get('description').value,
      duration: this.experienceForm.get('duration').value,
      promotionType: this.experienceForm.get('promotionType').value,
      promotion: this.experienceForm.get('promotion').value,
      sustainableSeal: this.experienceForm.get('sustainableSeal').value,
      reviewTripadvisor: this.experienceForm.get('reviewTripadvisor').value,
      reviewGetyourguide: this.experienceForm.get('reviewGetyourguide').value,
      seasonStartMonth: this.experienceForm.get('seasonStartMonth').value,
      seasonEndMonth: this.experienceForm.get('seasonEndMonth').value,
      mediaExperiences: this.experienceForm.get('mediaExperiences').value,
      // maker: this.experienceForm.get('maker').value,
      languages: this.experienceForm.get('languages').value,
      categories: categories,
      tags: this.experienceForm.get('tags').value,
      country: this.experienceForm.get('country').value,
      location: this.experienceForm.get('location').value,
      zones: allZones.length == 0 ? this.experienceForm.get('zones').value : allZones,
      whyUs: this.experienceForm.get('whyUs').value,
      price: parseFloat(this.experienceForm.get('price').value),
      persons: this.experienceForm.get('persons').value,
      cancellation: this.experienceForm.get('cancellation').value,
      includes: this.experienceForm.get('includes').value,
      notIncludes: this.experienceForm.get('notIncludes').value,
      moreInfo: this.experienceForm.get('moreInfo').value,
      carryInfo: this.experienceForm.get('carryInfo').value,
      bookingEngine: this.experienceForm.get('bookingEngine').value,
      bookingEngineWidget: this.experienceForm.get('bookingEngineWidget').value,
      fareharborId: this.experienceForm.get('fareharborId').value,
      turitopId: this.experienceForm.get('turitopId').value,
      bokunId: this.experienceForm.get('bokunId').value,
      weekDays: this.experienceForm.get('weekDays').value,
      typePrice: this.experienceForm.get('typePrice').value,
      // toDate: this.convertDate(this.experienceForm.get('toDate').value),
      // fromDate: this.convertDate(this.experienceForm.get('fromDate').value),
      status: auxStatus,
      currency: this.experienceForm.get('currency').value,
      descriptionExtended: this.experienceForm.get('descriptionExtended').value,
      highlights: this.experienceForm.get('highlights').value,
      modalities: modalitiesIris,
      microsheetCompleted: this.isMicroSheetValid,
      sheetCompleted: this.isWebSheetValid,
      pinCompleted: this.isPinValid,
      months: months,
      monthsString: months.join(',')
      // tripAdv: this.experienceForm.get('tripAdv').value
    };

    if (this.translate.getDefaultLang() == 'es') {
      experience.slug = slug
    }

    var editedExperience = new Experience(experience);

    // if (this.mediaExperiences.length > 4) {
      this.experienceService
        .putExperience(
          this.experience.id,
          editedExperience,
          this.translate.getDefaultLang()
        )
        .subscribe(
          (result) => {
            // this.alert.type = 1;
            // this.alert.message = 'Experiencia guardada correctamente';
            // setTimeout(() => {
            //   this.alert = { message: null, type: null };
            // }, 5000);
            // Trigger modal opening
            this.openAlert('Experiencia Guardada!', 'w-300', true)
          },
          (error) => {
            // this.alert.message = error.message;
            // this.alert.type = 2;
            // setTimeout(() => {
            //   this.alert = { message: null, type: null };
            // }, 5000);
            this.openAlert('Ha habido un error!', 'w-300', false)
          },
          () => console.log('Complete')
        );
    // } else {
    //   this.openAlert('Mínimo 5 y máximo 10 imagenes', 'w-300', false)
    // }

  }

  async createExperience() {
    this.experienceForm.markAllAsTouched();

    let auxStatus = '';
    if (this.experienceForm.valid) {
      auxStatus = 'completa';
    } else {
      auxStatus = 'incompleta';
    }

    const categories = [];
    const categoriesObject = [];
    const subcategories = [];

    this.experienceForm.get('categories').value.forEach((category) => {
      categories.push(category);
    });

    this.experienceForm.get('subcategories').value.forEach((subcategory) => {
      categories.push(subcategory);
    });

    let months = [];

    if (this.experienceForm.get('months').value == 12) {
      months = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
    } else {
      months = this.experienceForm.get('months').value;
    }

    let allZones = [];
    if (this.experienceForm.get('zones').value == 'all-zones') {
      this.zones.forEach(zone => {
        allZones.push(zone['@id'])
      });
    }


    let slug = await this.convertSlug(this.experienceForm.get('name').value);
    const experience = {
      name: this.experienceForm.get('name').value,
      promoCode: this.experienceForm.get('promoCode').value,
      url: this.experienceForm.get('url').value,
      urlButtonText: this.experienceForm.get('urlButtonText').value,
      description: this.experienceForm.get('description').value,
      duration: this.experienceForm.get('duration').value,
      promotionType: this.experienceForm.get('promotionType').value,
      promotion: this.experienceForm.get('promotion').value,
      sustainableSeal: this.experienceForm.get('sustainableSeal').value,
      reviewTripadvisor: this.experienceForm.get('reviewTripadvisor').value,
      reviewGetyourguide: this.experienceForm.get('reviewGetyourguide').value,
      seasonStartMonth: this.experienceForm.get('seasonStartMonth').value,
      seasonEndMonth: this.experienceForm.get('seasonEndMonth').value,
      mediaExperiences: this.experienceForm.get('mediaExperiences').value,
      maker: this.experienceForm.get('maker').value,
      languages: this.experienceForm.get('languages').value,
      categories: categories,

      tags: this.experienceForm.get('tags').value,
      country: this.experienceForm.get('country').value,
      location: this.experienceForm.get('location').value,
      zones: allZones.length == 0 ? this.experienceForm.get('zones').value : allZones,
      whyUs: this.experienceForm.get('whyUs').value,
      persons: this.experienceForm.get('persons').value,
      cancellation: this.experienceForm.get('cancellation').value,
      includes: this.experienceForm.get('includes').value,
      notIncludes: this.experienceForm.get('notIncludes').value,
      moreInfo: this.experienceForm.get('moreInfo').value,
      carryInfo: this.experienceForm.get('carryInfo').value,
      price: parseFloat(this.experienceForm.get('price').value),
      slug: slug,
      bookingEngine: this.experienceForm.get('bookingEngine').value,
      bookingEngineWidget: this.experienceForm.get('bookingEngineWidget').value,
      fareharborId: this.experienceForm.get('fareharborId').value,
      bokunId: this.experienceForm.get('bokunId').value,
      turitopId: this.experienceForm.get('turitopId').value,
      weekDays: this.experienceForm.get('weekDays').value,
      typePrice: this.experienceForm.get('typePrice').value,
      // toDate: this.convertDate(this.experienceForm.get('toDate').value),
      // fromDate: this.convertDate(this.experienceForm.get('fromDate').value),
      status: auxStatus,
      currency: this.experienceForm.get('currency').value,
      descriptionExtended: this.experienceForm.get('descriptionExtended').value,
      highlights: this.experienceForm.get('highlights').value ? this.experienceForm.get('highlights').value : [],
      microsheetCompleted: this.isMicroSheetValid,
      sheetCompleted: this.isWebSheetValid,
      pinCompleted: this.isPinValid,
      months: months,
      monthsString: months.join(',')
      // tripAdv: this.experienceForm.get('tripAdv').value
    };
    this.experienceService.postExperience(experience).subscribe(
      (result) => {
        // this.alert.type = 1;
        // this.alert.message = 'Experiencia creada correctamente';
        // setTimeout(() => {
        //   this.alert = { message: null, type: null };
        // }, 5000);
        this.createForm();
        this.experience = result;
        this.saveModalitiesCreate();
        this.openAlert('Experiencia Creada!', 'w-300', true)
      },
      (error) => {
        // this.alert.message = error.message;
        // this.alert.type = 2;
        // setTimeout(() => {
        //   this.alert = { message: null, type: null };
        // }, 5000);
        this.openAlert('Ha habido un error!', 'w-300', false)
      }
    );
  }

  saveModalitiesCreate() {
    if (this.modalities.length > 0) {

      this.modalities.forEach((modality, index) => {

        modality.experience = this.experience['@id'];
        modality.bookingEngineId = Number(modality.bookingEngineId);

        if (modality.pickupStr == "true") {
          modality.pickup = true;
        } else {
          modality.pickup = false;
        }

        if (modality.title != null && modality.title != undefined && modality.title != "") {
          this.experienceService.postModality(modality).subscribe((modality) => {
            this.modalitiesIris.push(modality['@id']);
            if (index == this.modalities.length - 1) {
              location.replace(
                this.translate.getDefaultLang() + '/maker/admin/experiencias'
              );
            }
          });
        } else {
          location.replace(
            this.translate.getDefaultLang() + '/maker/admin/experiencias'
          );
        }

      });
    } else {
      location.replace(
        this.translate.getDefaultLang() + '/maker/admin/experiencias'
      );
    }
  }

  insertTag(event, type) {
    this[type + 'Selected'] = this.experienceForm.controls[type].value
      ? this.experienceForm.controls[type].value
      : [];

    if (event.target.value != '') {
      this[type + 'Selected'].push(event.target.value);
    }
    this.experienceForm.controls[type].setValue(this[type + 'Selected']);
    event.target.value = null;
    // this[type + 'Selected'] = this.experienceForm.controls[type].value;
  }

  onFileChanged(event) {
    if (
      event.target.files[0].type === 'image/jpeg' ||
      event.target.files[0].type === 'image/png'
    ) {
      this.selectedFile = event.target.files[0];
    }
  }

  onFileChange(eventFiles) {
    if (eventFiles.target.files && eventFiles.target.files[0]) {
      const filesAmount = eventFiles.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        const reader = new FileReader();
        this.selectedFiles.push(eventFiles.target.files[i]);

        reader.onload = (event: any) => {
          this.images.push({
            url: event.target.result,
            name: eventFiles.target.files[i].name,
          });
        };

        reader.readAsDataURL(eventFiles.target.files[i]);
      }
    }
  }


  onVideoSelected(event, type) {

    /* get the pdf file from the event input */
    const file = event.target.files[0];

    //file . size in MB



    file.typeOf = type;

    file.experience = this.experience['@id'];

    var extraData = {
      typeOf: type,
      experience: this.experience.id
    };




    // video formats: .avi, .mp4, .mov, .webp

    if (file.type != "video/mp4" && file.type != "video/webm" && file.type != "video/ogg" && file.type != "video/avi" && file.type != "video/mov" && file.type != "video/webp" && file.type != "video/quicktime") {
      this.alert.message = "El formato del video debe ser .mp4, .webm o .ogg";
      this.alert.type = 2;
      setTimeout(() => {
        this.alert = { message: null, type: null };
      }, 5000);
      return;
    }


    //postVideo
    this.postVideo(file, extraData);

    //si type está dentro de selectedPdfFiles.typeOf, borrarlo y sustituirlo por el nuevo
    /* if(this.selectedPdfFiles.length > 0){
      for(var i = 0; i < this.selectedPdfFiles.length; i++){
        if(this.selectedPdfFiles[i].typeOf == type){
          
          //delete PDF
          this.mediaMakerService.deleteMediaMakers(this.selectedPdfFiles[i].id).subscribe(() => {
            console.log("PDF deleted");
            this.selectedPdfFiles.splice(i, 1);
          });


        }
      }
      
    } */



  }

  postVideo(file, extraData) {

    this.loadingVideo = true;

    this.mediaExperienceService.mediaExperiencesVideosUpload(file, extraData).subscribe(
      (result) => {

        if (result.body) {
          this.uploadedVideos.push(result.body);
          this.loadingVideo = false;
        }

        this.progressVideo = Math.round((100 * result.loaded) / result.total);


      },
      (error) => {
        this.alert.message = error.error.code + '- ' + error.error.message;
        this.alert.type = 2;
        setTimeout(() => {
          this.alert = { message: null, type: null };
        }, 5000);
      }
    );

  }

  deleteVideo(video) {

    this.mediaExperienceService.deleteMediaExperiencesVideos(video['@id']).subscribe(() => {
      const index = this.uploadedVideos.indexOf(video);
      this.uploadedVideos.splice(index, 1);
    });

  }

  onUpload() {
    const index = 0;
    this.selectedFiles.forEach((file) => {
      this.mediaExperienceService.mediaExperiencesUpload(file).subscribe(
        (result) => {
          this.handleProgress(result, file.name, index);
        },
        (error) => {
          this.alert.message = error.error.code + '- ' + error.error.message;
          this.alert.type = 2;
          setTimeout(() => {
            this.alert = { message: null, type: null };
          }, 5000);
        }
      );
    });
  }

  handleProgress(event, fileName, index) {
    if (event.type === HttpEventType.DownloadProgress) {
      this.uploadingProgressing = true;
      this.uploadProgress = Math.round((100 * event.loaded) / event.total);
    }

    if (event.type === HttpEventType.UploadProgress) {
      this.uploadingProgressing = true;
      this.uploadProgress = Math.round((100 * event.loaded) / event.total);
    }

    if (event.type === HttpEventType.Response) {
      this.uploadComplete = true;
      this.uploadingProgressing = false;
      this.serverResponse = event.body;

      this.uploadedFiles.push(event.body['@id']);

      this.mediaExperiences.push({
        url: event.body['contentUrl'],
        id: event.body['@id'],
      });

      this.selectedFiles.splice(index, 1);
      this.images = this.images.filter((el) => el.name !== fileName);

      this.alert.type = 1;
      this.alert.message = 'imágen guardada correctamente';
      setTimeout(() => {
        this.alert = { message: null, type: null };
      }, 5000);

      this.experienceForm.get('mediaExperiences').setValue(this.uploadedFiles);
    }
  }

  loadLocations(countryCode: string) {
    this.locationService
      .getLocationsFiltered(
        { country: countryCode },
        this.translate.getDefaultLang()
      )
      .subscribe((locations) => {
        this.locations = locations;
        this.filteredLocations.next(this.locations.slice());
      });
  }

  loadZones(locationId: string) {
    this.locationService
      .getZonesFiltered(locationId, this.translate.getDefaultLang())
      .subscribe((zones) => {
        this.zones = zones;
        this.filteredZone.next(this.zones.slice());
      });
  }

  deleteMediaExperience(image, uploaded) {
    if (uploaded) {
      this.mediaExperienceService
        .deleteMediaExperiences(image)
        .subscribe(() => {
          const index = this.uploadedFiles.indexOf(image);
          this.uploadedFiles.splice(index, 1);
          this.mediaExperiences = this.mediaExperiences.filter(
            (el) => el.id !== image
          );
          this.experienceForm
            .get('mediaExperiences')
            .setValue(this.uploadedFiles);
        });
    } else {
      this.selectedFiles = this.selectedFiles.filter((el) => el.name !== image);
      this.images = this.images.filter((el) => el.name !== image);
    }
    console.log(this.mediaExperiences);
  }

  deleteExpandedMediaExperience(image, uploaded) {
    if (uploaded) {
      this.mediaExperienceService
        .deleteMediaExperiences(image)
        .subscribe(() => {
          const index = this.uploadedFiles.indexOf(image);
          this.uploadedFiles.splice(index, 1);
          this.expandedMediaExperiences = this.expandedMediaExperiences.filter(
            (el) => el.id !== image
          );
          this.experienceForm
            .get('expandedMediaExperiences')
            .setValue(this.uploadedFiles);
        });
    } else {
      this.selectedFiles = this.selectedFiles.filter((el) => el.name !== image);
      this.images = this.images.filter((el) => el.name !== image);
    }
    this.editExperience(this.activatedRoute.snapshot.params.id)
  }

  onUploadedImage(uploadedImage: object, formControlName: string) {
    this.mediaExperiences.push({
      url: uploadedImage['url'],
      id: uploadedImage['id'],
    });
    this.expandedMediaExperiences.push({
      url: uploadedImage['url'],
      id: uploadedImage['id'],
    });
    this.uploadedFiles.push(uploadedImage['id']);
    this.experienceForm.get(formControlName).patchValue(this.uploadedFiles);
  }

  onExpandedSheetUploadedImage(uploadedImage: object, formControlName: string) {
    if (this.mediaExperiences.length < 10) {
      this.mediaExperiences.push({
        url: uploadedImage['url'],
        id: uploadedImage['id'],
      });
    }
    this.expandedMediaExperiences.push({
      url: uploadedImage['url'],
      id: uploadedImage['id'],
    });
    this.uploadedFiles.push(uploadedImage['id']);
    this.experienceForm.get(formControlName).patchValue(this.uploadedFiles);
  }

  changeValidation(event) {
    if (event) {
      this.experienceForm.get('promotion').setValidators(Validators.required);
      this.experienceForm.updateValueAndValidity();
      this.experienceForm.get('promotion').setErrors({ incorrect: true });
    } else {
      this.experienceForm.get('promotion').clearValidators();
      this.experienceForm.updateValueAndValidity();
    }
  }

  showErrors(formGroup) {
    Object.keys(formGroup.controls).forEach((key) => {
      formGroup.get(key).markAsTouched();
    });
    return;
  }

  showError(formValue) {
    return (
      this.experienceForm.get(formValue).invalid &&
      this.experienceForm.get(formValue).touched
    );
  }

  changeLanguage(language) {
    this.translate.setDefaultLang(language);
    setTimeout(() => {
      this.editExperience(this.activatedRoute.snapshot.params.id);
    }, 100);
    setTimeout(() => {
      if (!this.experience.name) {
        this.translateAll();
      }
    }, 1000);
  }
  /* convertToSlug(text) {
    return text
      .toLowerCase()
      .normalize('NFD') // Normalizar caracteres con acentos
      .replace(/[\u0300-\u036f]/g, '') // Eliminar diacríticos
      .replace(/ /g, '-') // Reemplazar espacios con guiones
      .replace(/[^\wñáéíóúü-]+/g, '') // Eliminar caracteres especiales excepto guiones y letra "ñ"
      .replace(/--+/g, '-') // Reemplazar múltiples guiones con uno solo
      .replace(/^-+|-+$/g, '') // Eliminar guiones al principio y al final del texto
      .replace(/&/g, ''); //eliminar &

  } */

  convertDate(date) {
    var dateObj = new Date(date);
    var year = dateObj.getFullYear();
    var month = String(dateObj.getMonth() + 1).padStart(2, '0');
    var day = String(dateObj.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  }

  setWeekDays(event: any) {
    if (event.length > 0) {
      if (event[0] == 0) {
        this.weekDays = [{ value: 0, text: 'Todos los días' }];
      } else {
        this.weekDays = [
          { value: 1, text: 'Lun' },
          { value: 2, text: 'Mar' },
          { value: 3, text: 'Mié' },
          { value: 4, text: 'Jue' },
          { value: 5, text: 'Vie' },
          { value: 6, text: 'Sáb' },
          { value: 7, text: 'Dom' },
        ];
      }
    } else {
      this.weekDays = [
        { value: 0, text: 'Todos los días' },
        { value: 1, text: 'Lun' },
        { value: 2, text: 'Mar' },
        { value: 3, text: 'Mié' },
        { value: 4, text: 'Jue' },
        { value: 5, text: 'Vie' },
        { value: 6, text: 'Sáb' },
        { value: 7, text: 'Dom' },
      ];
    }
  }

  setMonths(event: any) {
    if (event.length > 0) {
      if (event[0] == 12) {
        this.monthsList = [{ value: 12, text: 'Todos los meses' }];
      } else {
        this.monthsList = [
          { value: 0, text: 'Enero' },
          { value: 1, text: 'Febrero' },
          { value: 2, text: 'Marzo' },
          { value: 3, text: 'Abril' },
          { value: 4, text: 'Mayo' },
          { value: 5, text: 'Junio' },
          { value: 6, text: 'Julio' },
          { value: 7, text: 'Agosto' },
          { value: 8, text: 'Septiembre' },
          { value: 9, text: 'Octubre' },
          { value: 10, text: 'Noviembre' },
          { value: 11, text: 'Diciembre' },
        ];
      }
    } else {
      this.monthsList = [
        { value: 12, text: 'Todos los meses' },
        { value: 0, text: 'Enero' },
        { value: 1, text: 'Febrero' },
        { value: 2, text: 'Marzo' },
        { value: 3, text: 'Abril' },
        { value: 4, text: 'Mayo' },
        { value: 5, text: 'Junio' },
        { value: 6, text: 'Julio' },
        { value: 7, text: 'Agosto' },
        { value: 8, text: 'Septiembre' },
        { value: 9, text: 'Octubre' },
        { value: 10, text: 'Noviembre' },
        { value: 11, text: 'Diciembre' }
      ];
    }
  }

  translateAll() {
    this.experienceService
      .getExperience(this.experience.id, 'es')
      .subscribe((experience: Experience) => {
        let name = experience.name;
        // let slug = experience.slug;
        let description = experience.description;
        let descriptionExtended = experience.descriptionExtended;
        let url = experience.url;
        let whyUs = experience.whyUs;
        let cancellation = experience.cancellation;
        let includes = experience.includes;
        let notIncludes = experience.notIncludes;
        let carryInfo = experience.carryInfo;
        let promotion = experience.promotion;

        this.translateIA([name], 'name');
        // this.translateIA([slug], 'slug');
        this.translateIA([description], 'description');
        this.translateIA([descriptionExtended], 'descriptionExtended');
        this.translateIA([whyUs], 'whyUs');
        this.translateIA([cancellation], 'cancellation');
        this.translateIA(includes, 'includes');
        this.translateIA(notIncludes, 'notIncludes');
        this.translateIA(carryInfo, 'carryInfo');
        this.translateIA([promotion], 'promotion');
      });
  }

  translateIA(field, type) {
    let isArray = ['includes', 'notIncludes', 'carryInfo'];
    this.experienceService.translateExperience({ text: field, language: this.translate.getDefaultLang() }).subscribe(
      (response) => {
        if (!isArray.includes(type)) {
          this.experienceForm
            .get(type)
            .setValue(response['response'][0]['text']);
          if (type == 'description') {
            this.descriptionText = response['response'][0]['text'];
          }
        } else {
          let value = [];
          response['response'].forEach((translation) => {
            value.push(translation.text);
            this.experienceForm.get(type).setValue(value);
          });
        }
        // Manejar la respuesta de la API aquí
      },
      (error) => {
        // Manejar errores de solicitud aquí
      }
    );
  }

  importFrom(web) {
    this.experienceService
      .getExperienceByCivitatisId(this.civitatisId)
      .subscribe((response) => {
        let experience = {
          maker: '/makers/b6e58985-d03d-11eb-be07-0a30d442b088',
        };
        const experienceData = new Experience(experience);
        this.experienceService
          .putExperience(response['hydra:member'][0]['id'], experienceData)
          .subscribe((response) => {
            this.router.navigateByUrl(
              this.translate.getDefaultLang() +
              '/maker/admin/editar-experiencia/' +
              response['id']
            );
          });
      });
  }

  addHighlight() {
    if (this.highlightsCount.length < 5) {
      this.highlightsCount.push(this.highlightsCount.length);
    }
  }

  pushHighlight(event) {
    this.highlights.push(event);
  }

  insertTagMax5(event, type) {
    this[type + 'Selected'] = this.experienceForm.controls[type].value
      ? this.experienceForm.controls[type].value
      : [];

    if (event.target.value != '' && this[type + 'Selected'].length < 5) {
      this[type + 'Selected'].push(event.target.value);
    }

    this.experienceForm.controls[type].setValue(this[type + 'Selected']);
    event.target.value = null;
    // this[type + 'Selected'] = this.experienceForm.controls[type].value;
  }

  addModality() {

    this.modalities.push({
      title: '',
      description: '',
      bookingEngineId: 0,
      prices: [{
        name: '',
        price: 0,
      }],
      pickup: false,
      pickupStr: '',
      duration: '',
      start: '',
      end: '',
      meetingPoint: '',
      location: '',
      experience: ''
    });

    this.isExpanded.push(false);

  }

  addNewPrice(index) {
    var objectPrice = {
      name: '',
      price: 0,
    };

    this.modalities[index].prices.push(objectPrice);

  }

  deletePrice(index, indexPrice) {
    this.modalities[index].prices.splice(indexPrice, 1);
  }

  deleteModality(index) {
    this.modalitiesDelete.push(this.modalities[index]['@id']);

    this.modalities.splice(index, 1);
  }

  loadSubcategories(categoriesId) {
    //avoid the variable categoriesSelected to be greater than 2 using categoriesId to find the new categories
    if (categoriesId.length > 2) {
      categoriesId.splice(2, 1);
      this.categoriesSelected = categoriesId;
      return;
    }

    var sub = [];
    this.subcategories = [];

    // get the this.categories from the array categoriesId
    this.allCategories.forEach((category) => {
      if (category.parents != null) {
        category.parents.forEach((parent) => {
          if (categoriesId.includes(parent['@id'])) {
            this.subcategories.push(category);
          }
        });
      }

      this.subcategories = this.subcategories.filter((thing, index, self) =>
        index === self.findIndex((t) => (
          t['@id'] === thing['@id']
        ))
      );
    });
  }

  validateTags(tags) {
    if (tags.length > 2) {
      tags.splice(2, 1);
      this.tagsSelected = tags;
      return;
    }
  }

  convertTimeToCustomFormat(inputTime: string): string {
    let formattedText;
    const [hours, minutes] = inputTime.split(':').map(part => parseInt(part, 10));

    if (minutes != 0) {
      formattedText = hours + ' h y ' + minutes + ' min'
    } else {
      formattedText = hours + ' h'
    }

    return formattedText;
  }

  openPreview(template: any, config: any) {
    this.modalRef = this.modalService.show(template, config);
  }

  loadPreviewCategories() {
    this.allCategories.forEach(category => {
      this.experienceForm.get('categories').value.forEach(categoryIri => {
        if (categoryIri == category['@id']) {
          this.myPreviewCategories.push(category.name);
        }
      });
    });
  }

  loadPreviewSubcategories() {
    this.allCategories.forEach(subcategory => {
      this.experienceForm.get('subcategories').value.forEach(categoryIri => {
        if (categoryIri == subcategory['@id']) {
          this.myPreviewSubcategories
            .push(subcategory.name);
        }
      });
    });
  }

  loadPreviewLanguages() {
    this.languages.forEach(language => {
      this.experienceForm.get('languages').value.forEach(languageIri => {
        if (languageIri == language['@id']) {
          this.myPreviewLanguages.push(language.name);
        }
      });
    });
  }

  loadPreviewLocation() {
    this.allLocations.forEach(location => {
      if (this.experienceForm.get('location').value == location['@id']) {
        this.myPreviewLocation = location.name;
      }
    });
  }

  findTextByValue(array, targetValueArray) {
    let shownDays = '';

    for (let i = 0; i < array.length; i++) {
      targetValueArray.forEach(targetValue => {
        if (array[i].value === targetValue) {
          shownDays += array[i].text + ', '
        }
      });
    }
    shownDays = shownDays.slice(0, -2);

    return shownDays
  }

  setCategoryTitle(experience) {
    let firstString = ''
    let secondString = ''
    if (experience.categories) {
      firstString = experience.categories.map(obj => obj.name).join(' / ')
    }
    if (experience.subcategories) {
      secondString = experience.subcategories.map(obj => obj.name).join(' / ')
    }
    return firstString + secondString;
  }

  setLanguagesTitle(experience) {
    return experience.languages.map(obj => obj.name).join(' , ')
  }

  checkMicroSheetContent() {
    let controlNames = ['name', 'url', 'description', 'duration', 'months', 'categories', 'languages', 'country', 'location', 'mediaExperiences']
    this.isMicroSheetValid = false;
    for (let controlName of controlNames) {
      if (this.experienceForm.get(controlName).valid) {
        this.isMicroSheetValid = true;
      } else {
        console.log(controlName);
        console.log(this.experienceForm.get(controlName).errors);

        this.isMicroSheetValid = false;
        break;
      }
    };
  }

  checkWebSheetContent() {
    let controlNames = ['descriptionExtended', 'whyUs', 'includes', 'notIncludes', 'carryInfo', 'persons']
    this.isWebSheetValid = false;
    for (let controlName of controlNames) {
      if (this.experienceForm.get(controlName).valid) {
        this.isWebSheetValid = true;
      } else {
        this.isWebSheetValid = false;
        break;
      }
    };
  }

  checkPinSheetContent() {

    var highlights = this.experienceForm.get('highlights').value;
    var currency = this.experienceForm.get('currency').value;

    if (this.experience != null && this.experience != undefined) {

      if (highlights && this.experience.modalities && this.experience.mediaExperiencesVideos && currency) {

        if (highlights.length > 0 && this.experience.modalities.length > 0 && this.experience.mediaExperiencesVideos.length > 0 && currency != null) {
          this.isPinValid = true;
        } else {
          this.isPinValid = false;
        }

      } else {
        this.isPinValid = false;
      }

    }

  }


  async convertSlug(slug) {
    var slugCleared = slug;
    //clear accents (A-U)
    slugCleared = slugCleared.replace(/[ÀÁÂÃÄÅ]/g, "A");
    slugCleared = slugCleared.replace(/[àáâãäå]/g, "a");
    slugCleared = slugCleared.replace(/[ÈÉÊË]/g, "E");
    slugCleared = slugCleared.replace(/[éèêë]/g, "e");
    slugCleared = slugCleared.replace(/[íìîï]/g, "i");
    slugCleared = slugCleared.replace(/[ÍÌÎÏ]/g, "I");
    slugCleared = slugCleared.replace(/[óòôõö]/g, "o");
    slugCleared = slugCleared.replace(/[ÓÒÔÕÖ]/g, "O");
    slugCleared = slugCleared.replace(/[úùûü]/g, "u");
    slugCleared = slugCleared.replace(/[ÚÙÛÜ]/g, "U");
    slugCleared = slugCleared.replace(/[ñ]/g, "n");
    slugCleared = slugCleared.replace(/[Ñ]/g, "N");
    slugCleared = slugCleared.replace(/[ç]/g, "c");
    slugCleared = slugCleared.replace(/[Ç]/g, "C");
    slugCleared = slugCleared.replace(/[ÿý]/g, "y");
    slugCleared = slugCleared.replace(/[Ý]/g, "Y");
    slugCleared = slugCleared.replace(/[’]/g, "");
    slugCleared = slugCleared.replace(/[‘]/g, "");
    slugCleared = slugCleared.replace(/[“]/g, "");
    slugCleared = slugCleared.replace(/[”]/g, "");
    slugCleared = slugCleared.replace(/[«]/g, "");
    slugCleared = slugCleared.replace(/[»]/g, "");
    slugCleared = slugCleared.replace(/[—]/g, "-");
    slugCleared = slugCleared.replace(/[·]/g, "-");
    slugCleared = slugCleared.replace(/[\/]/g, "-");
    slugCleared = slugCleared.replace(/[\\]/g, "-");
    slugCleared = slugCleared.replace(/[?]/g, "");
    slugCleared = slugCleared.replace(/[¿]/g, "");
    slugCleared = slugCleared.replace(/[!]/g, "");
    slugCleared = slugCleared.replace(/[¡]/g, "");
    slugCleared = slugCleared.replace(/[º]/g, "");
    slugCleared = slugCleared.replace(/[ª]/g, "");
    slugCleared = slugCleared.replace(/[°]/g, "");
    slugCleared = slugCleared.replace(/[&]/g, "-");
    slugCleared = slugCleared.replace(/[<]/g, "");
    slugCleared = slugCleared.replace(/[>]/g, "");
    slugCleared = slugCleared.replace(/[;]/g, "");
    slugCleared = slugCleared.replace(/[']/g, "");
    slugCleared = slugCleared.replace(/["]/g, "");
    slugCleared = slugCleared.replace(/[,]/g, "");
    slugCleared = slugCleared.replace(/[.]/g, "-");
    slugCleared = slugCleared.replace(/[:]/g, "");
    slugCleared = slugCleared.replace(/[%]/g, "");
    slugCleared = slugCleared.replace(/[@]/g, "-");
    slugCleared = slugCleared.replace(/[*]/g, "");
    slugCleared = slugCleared.replace(/[+]/g, "");
    slugCleared = slugCleared.replace(/[=]/g, "");
    slugCleared = slugCleared.replace(/[|]/g, "");
    slugCleared = slugCleared.replace(/[()]/g, "");
    slugCleared = slugCleared.replace(/[[]/g, "");
    slugCleared = slugCleared.replace(/[]]/g, "");
    slugCleared = slugCleared.replace(/[{}]/g, "");
    slugCleared = slugCleared.replace(/[~]/g, "");

    //clear spaces
    slugCleared = slugCleared.replace(/ /g, "-");
    //clear double spaces and souble --
    slugCleared = slugCleared.replace(/--/g, "-");
    //clear begin and end -
    slugCleared = slugCleared.replace(/^-/g, "");
    slugCleared = slugCleared.replace(/-$/g, "");

    //convert to lowercase
    slugCleared = slugCleared.toLowerCase();

    //promise boolean

    var slugExist = await this.verifySlugExists(slugCleared);

    if (slugExist) {
      if (this.maker.slug == slugCleared) {
        return slugCleared;
      } else {
        slugCleared = slugCleared + "-1";

        return slugCleared;
      }

    } else {
      return slugCleared;
    }
  }


  async verifySlugExists(slug: string) {

    var slugExist = false;

    var experience = await this.experienceService.getExperienceBySlug(slug).toPromise();

    experience = experience['hydra:member'];

    if (experience != undefined && experience != null) {
      slugExist = true;

      return slugExist;

    } else {
      slugExist = false;

      return slugExist;
    }
  }

  openAlert(modalText: string, className: string, status: boolean) {
    let iconClass: string;
    if (status) {
      iconClass = 'vi-check';
    } else {
      iconClass = 'vi-sad';
    }
    const modalConfig = {
      class: className,
      initialState: {
        alertMessage: this.translate.instant(
          modalText
        ),
        iconClass,
        status
      }
    }
    this.dialogRef = this.dialog.show(ModalAlertComponent, modalConfig);
    setTimeout(() => {
      this.dialogRef.hide();
    }, 2500);
  }
}
