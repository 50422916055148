<div class="row justify-content-between">
    <div class="col-md-8 d-md-flex align-items-center">
        <h1 class="page-title m-0 mr-3">
            <i class="fa fa-binoculars small mr-3"></i>{{ translate.instant(title) }}
        </h1>
    </div>

    <div class="col-4">

        <!-- Importar reviews Get Your Guide -->
        <button class="btn btn-primary btn-sm float-right" (click)="importReviews(maker.id)"
            [tooltip]="translate.instant('Importar reviews')" placement="bottom">
            Importar reviews Get Your Guide
            <i class="fa fa-download"></i>
        </button>

    </div>

</div>
<div class="my-5 d-flex justify-content-between">
    <div class="d-flex flex-column justify-content-between">
        <h5>{{ 'Buscar por:' | translate}} </h5>
        <form [formGroup]="searchForm" (submit)="applyFilter()">
            <div class="d-flex flex-column flex-lg-row mr-3 mr-lg-0">
                <div class="mr-lg-3 mb-2 mb-lg-0">
                    <input [placeholder]="translate.instant('Experiencia')" type="text" formControlName="name"
                        class="form-control form-control-sm">
                </div>
                <button type="submit" class="btn btn-success btn-sm mt-sm-0 mt-2 search-button mr-3">{{ 'Buscar' |
                    translate}}</button>
            </div>
        </form>
    </div>
</div>
<div class="card mt-2">
    <div class="card-header">
        <strong>Reviews por producto:</strong>
    </div>
    <div class="card-body p-0">
        <ngx-datatable #table class="bootstrap expandable d-none d-lg-block" [rowHeight]="'auto'" [columns]="columns"
            [columnMode]="'force'" [headerHeight]="90" [footerHeight]="50" [limit]="maxTableRows" [rows]="rows">
            <ngx-datatable-column *ngFor="let column of columns; let i = index;"
                name="{{translate.instant(column.name)}}" prop="{{column.prop}}" [minWidth]="100">
            </ngx-datatable-column>
            <ngx-datatable-column [width]="90" [resizeable]="true" [sortable]="false" [draggable]="false"
                [canAutoResize]="true" headerClass="d-none d-sm-block text-right" cellClass="text-right"
                [name]="translate.instant('Acciones')" prop="actions">
                <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                    <span class="datatable-header-cell-label d-none d-sm-block">{{ column.name }}</span>
                </ng-template>
                <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                    <button [routerLink]="row.id" class="btn btn-sm btn-view d-none d-sm-inline-block mr-1"
                        [tooltip]="translate.instant('Ver reviews')" placement="bottom">
                        <i class="fa fa-eye"></i>
                    </button>
                    <button class="btn btn-sm btn-edit mr-1"
                        [routerLink]="'../editar-experiencia/'+row['@id'].replace('/experiences/', '')"
                        [tooltip]="translate.instant('Ir al producto')" placement="bottom">
                        <i class="fa fa-file"></i>
                    </button>
                </ng-template>
            </ngx-datatable-column>
        </ngx-datatable>
        <ngx-datatable #table class="bootstrap expandable d-lg-none d-block" [rowHeight]="'auto'" [columns]="columns"
            [columnMode]="'force'" [headerHeight]="90" [footerHeight]="50" [limit]="maxTableRows" [rows]="rows">
            <ngx-datatable-column name="{{'Nombre'}}" prop="{{'name'}}">
            </ngx-datatable-column>
            <ngx-datatable-column [resizeable]="true" [sortable]="false" [draggable]="false" [canAutoResize]="true"
                [name]="translate.instant('Acciones')" prop="actions">
                <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                    <span class="datatable-header-cell-label">{{ column.name }}</span>
                </ng-template>
                <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                    <button *ngIf="this.maker['makerPlan']['plan']['sku'] != 'FREE'" class="btn btn-sm btn-delete mr-1"
                        [tooltip]="translate.instant('Compartir')" placement="bottom">
                        <i class="fa fa-upload"></i>
                    </button>
                    <button class="btn btn-sm btn-edit mr-1"
                        [routerLink]="'../editar-experiencia/'+row['@id'].replace('/experiences/', '')"
                        [tooltip]="translate.instant('Editar')" placement="bottom">
                        <i class="fa fa-pencil"></i>
                    </button>
                    <button class="btn btn-sm btn-delete" [tooltip]="translate.instant('Eliminar')" placement="bottom">
                        <i class="fa fa-trash"></i>
                    </button>
                </ng-template>
            </ngx-datatable-column>
        </ngx-datatable>
    </div>
</div>