import { ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { LocationService } from '../../shared/locations/location.service';
import { FormArray, UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { interval } from 'rxjs';
import { take } from 'rxjs/operators';
import { ExperiencesService } from '../experiences/experiences/experiences.service';
import { MakerService } from '../shared/maker.service';
import * as data from 'home-structure.json';
import KeenSlider, { KeenSliderInstance } from "keen-slider"
import { Location } from '@angular/common';

@Component({
  selector: 'app-home',
  styleUrls: ['./home.component.scss', '../../../../node_modules/keen-slider/keen-slider.min.css'],
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit {
  homeData: any = data;
  @ViewChild('imagesContainer') imagesContainer!: ElementRef;
  @ViewChild('scrollingIndicator') scrollingIndicator!: ElementRef;
  experienceSearchForm: UntypedFormGroup;
  destinySearchForm: UntypedFormGroup;
  makerSearchForm: UntypedFormGroup;

  @ViewChild("qualitySliderRef") qualitySliderRef: ElementRef<HTMLElement>;
  @ViewChild("lastSliderRef") lastSliderRef: ElementRef<HTMLElement>;
  @ViewChild("loveSliderRef") loveSliderRef: ElementRef<HTMLElement>;

  //Sliders
  qualitySlider: KeenSliderInstance = null
  lastSlider: KeenSliderInstance = null
  loveSlider: KeenSliderInstance = null
  currentSlide: number = 0
  lastCurrentSlide: number = 0
  loveCurrentSlide: number = 0
  dotHelper: Array<Number> = []
  lastDotHelper: Array<Number> = []
  loveDotHelper: Array<Number> = []
  slidesLength: number = 0;
  lastSlidesLength: number = 0;
  loveSlidesLength: number = 0;

  experiencesCities = null;
  makersCities = null;
  searchUrl = null;
  citySelected = null;
  searchHome = true;
  loaded = 0;

  imagesMobile = ['imagen-globos-mobile.jpg', 'imagen-quads-mobile.jpg', 'imagen-salto-mobile.jpg', 'imagen-Kitesurf-mobile.jpg'];
  imagesTablet = ['imagen-globos-tablet.jpg', 'imagen-quads-tablet.jpg', 'imagen-salto-tablet.jpg', 'imagen-Kitesurf-tablet.jpg'];
  imagesDesktop = ['imagen-globos-desktop.jpg', 'imagen-quads-desktop.jpg', 'imagen-salto-desktop.jpg', 'imagen-Kitesurf-desktop.jpg'];
  images: string[] = [];
  preloadedImages: HTMLImageElement[] = [];
  locations: any;

  experiences: any = [];
  qualityExperiences: any = [];
  lastExperiences: any = [];
  qualityCardsIndex = 0;
  lastExperiencesIndex = 0;
  whatsNewCardsIndex = 0;
  whatPeopleLoveIndex = 0;

  currentIndex = 0;
  searchType = 'experiences';

  constructor(
    private titleService: Title,
    private location: Location,
    private formBuilder: UntypedFormBuilder,
    private experiencesService: ExperiencesService,
    private makerService: MakerService,
    public locationService: LocationService,
    private activatedRoute: ActivatedRoute,
    public translate: TranslateService,
    private renderer: Renderer2,
    private meta: Meta,
    private cdref: ChangeDetectorRef
  ) {

    this.createForm();

    this.searchHome = this.activatedRoute.snapshot.routeConfig.path === ':lang'
      || this.activatedRoute.snapshot.routeConfig.path === '';
    this.locationService.getActiveLocations().subscribe(locations => {
      this.experiencesCities = locations['hydra:member'];
      if (this.activatedRoute.snapshot.queryParams['localidad']) {
        this.experienceSearchForm.get('location').setValue(parseInt(this.activatedRoute.snapshot.queryParams['localidad']));
      }
    });

    this.locationService.getMakerActiveLocations().subscribe(locations => {
      this.makersCities = locations['hydra:member'];
      if (this.activatedRoute.snapshot.queryParams['localidad']) {
        this.experienceSearchForm.get('location').setValue(parseInt(this.activatedRoute.snapshot.queryParams['localidad']));
      }
    });
  }

  ngOnInit(): void {
    this.locationService.getActiveLocations().subscribe((locations: any) => {
      this.locations = locations['hydra:member'];
    })
    console.log(this.translate.get);

    this.experiencesService.getExperiencesById(this.homeData.modules.find(item => item.code === 'quality').extra, this.location.path().substring(1, 3)).subscribe((experiences: any) => {
      const ids = this.homeData.modules.find(item => item.code === 'quality').extra;
      this.qualityExperiences = experiences['hydra:member'].sort((a, b) => {
        return ids.indexOf(a.id) - ids.indexOf(b.id);
      });
      setTimeout(() => {
        this.qualitySlider = new KeenSlider(this.qualitySliderRef.nativeElement, {
          initial: this.currentSlide,
          loop: true,
          slideChanged: (s) => {
            this.currentSlide = s.track.details.rel;
            this.cdref.detectChanges();
          },
          breakpoints: {
            "(min-width: 0px)": {
              slides: { perView: 1, spacing: 0 },
            },
            "(min-width: 768px)": {
              slides: { perView: 2, spacing: 0 },
            },
            "(min-width: 992px)": {
              slides: { perView: 3, spacing: 0 },
            }
          },
          slides: { perView: 1 },
        })
        this.dotHelper = Array.from({ length: Math.ceil(this.qualitySlider.track.details.slides.length) });
        this.slidesLength = Math.ceil(this.qualitySlider.track.details.slides.length);
        this.cdref.detectChanges();
      });
    })
    this.experiencesService.getLastExperiences(this.location.path().substring(1, 3)).subscribe((experiences: any) => {
      this.lastExperiences = experiences['hydra:member'].slice(-9);
      setTimeout(() => {
        this.lastSlider = new KeenSlider(this.lastSliderRef.nativeElement, {
          initial: this.lastCurrentSlide,
          loop: true,
          slideChanged: (s) => {
            this.lastCurrentSlide = s.track.details.rel;
            this.cdref.detectChanges();
          },
          breakpoints: {
            "(min-width: 0px)": {
              slides: { perView: 1, spacing: 0 },
            },
            "(min-width: 768px)": {
              slides: { perView: 2, spacing: 0 },
            },
            "(min-width: 992px)": {
              slides: { perView: 3, spacing: 0 },
            }
          },
          slides: { perView: 1 },
        })
        this.lastDotHelper = Array.from({ length: Math.ceil(this.lastSlider.track.details.slides.length) });
        this.lastSlidesLength = Math.ceil(this.lastSlider.track.details.slides.length);
        this.cdref.detectChanges();
      });
    })
    this.loadLoveSlider();
    // var myHeaders = new Headers();
    // myHeaders.append("Content-Type", "application/json");
    // myHeaders.append("key", "F18C23C616E9436A8B3EDA0DF0DAA06B");

    // var requestOptions:any = {
    //   method: 'GET',
    //   headers: myHeaders,
    //   redirect: 'follow'
    // };

    // fetch("https://api.content.tripadvisor.com/api/v1/location/search?key=F18C23C616E9436A8B3EDA0DF0DAA06B&searchQuery=mallorca&language=en", requestOptions)
    //   .then(response => response.text())
    //   .then(result => console.log(result))
    //   .catch(error => console.log('error', error));
    this.removeMetaTags();
    this.titleService.setTitle('Venntur | Las experiencias nos unen');


    interval(5000)
      .pipe(take(Infinity))
      .subscribe(() => {
        this.currentIndex = (this.currentIndex + 1) % this.images.length;
        this.updateBackgroundImage();
      });

    this.meta.addTags([
      { name: 'description', content: 'Venntur | Las experiencias nos unen' },
      { name: 'lang', content: 'es' },
      { name: 'robots', content: 'index, follow' },
      { property: 'og:title', content: 'Venntur | Las experiencias nos unen' },
      { property: 'og:description', content: 'Venntur | Las experiencias nos unen' },
      { property: 'og:image', content: 'https://venntur.com/assets/venntur-isotype-70.png' },
      { property: 'og:url', content: 'https://venntur.com/' },
      { property: 'og:site_name', content: 'Venntur' }
    ]);

    if (this.activatedRoute.snapshot.queryParams['maker']) {
      localStorage.setItem('token', this.activatedRoute.snapshot.queryParams['token']);
      localStorage.setItem('user', this.activatedRoute.snapshot.queryParams['user']);
      localStorage.setItem('maker', this.activatedRoute.snapshot.queryParams['maker'].replaceAll('&', '-'));
      if (this.activatedRoute.snapshot.queryParams['experience']) {
        location.href = "https://venntur.com/es/maker/admin/editar-experiencia/" + this.activatedRoute.snapshot.queryParams['experience'];
      } else {
        location.href = "https://venntur.com/es/maker/admin/perfil";
      }
    }
  }

  ngAfterViewInit() {
    this.updateImagesBasedOnWindowSize();
    // Preload all images
    this.preloadImages();
    // Set the initial background image
    this.updateBackgroundImage();
    this.getExperiences();
  }

  ngOnDestroy() {
    if (this.qualitySlider) this.qualitySlider.destroy()
  }

  // Hides scrolling indicator after scroll has been done
  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.renderer.removeClass(this.scrollingIndicator.nativeElement, 'animated')
    this.renderer.addClass(this.scrollingIndicator.nativeElement, 'faded')
  }

  private updateImagesBasedOnWindowSize(): void {

    const windowSize = window.innerWidth;

    if (windowSize > 992) {
      this.images = this.imagesDesktop;
    } else if (windowSize < 992 && windowSize > 768) {
      this.images = this.imagesTablet;
    } else {
      this.images = this.imagesMobile;
    }

  }

  private preloadImages(): void {
    this.images.forEach(imageUrl => {
      const img = new Image();
      img.src = `../../../assets/images/images-home/new-home-images/${imageUrl}`;
      this.preloadedImages.push(img);
    });
  }

  private updateBackgroundImage(): void {
    const imageUrl = this.images[this.currentIndex];
    this.renderer.setStyle(
      this.imagesContainer.nativeElement,
      'background',
      `url(../../../assets/images/images-home/new-home-images/${imageUrl}) center/cover no-repeat`
    );
  }

  // Add the HostListener decorator to listen for window resize events
  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    let windowSize = event.target['innerWidth'];
    if (windowSize > 992) {
      this.images = this.imagesDesktop;
    } else if (windowSize < 992 && windowSize > 768) {
      this.images = this.imagesTablet;
    } else {
      this.images = this.imagesMobile;
    }
  }

  createForm() {
    this.experienceSearchForm = this.formBuilder.group({
      location: ['',
        Validators.required
      ],
      location2: ['',
        Validators.required
      ],
      byLand: [false,
        Validators.required
      ],
      bySea: [false,
        Validators.required
      ],
      byAir: [false,
        Validators.required
      ]
    });

    this.destinySearchForm = this.formBuilder.group({
      location: ['',
        Validators.required
      ],
    });

    this.makerSearchForm = this.formBuilder.group({
      location: ['',
        Validators.required
      ]
    });

  }

  search(isClicked, param) {
    this.searchUrl = this.translate.getDefaultLang() + '/' + param + '?';
    if (this.searchHome && isClicked || !this.searchHome) {
      let categories = [];
      let tags = [];
      if (this.experienceSearchForm.get('location').value) {
        this.searchUrl += 'localidad=' + this.experienceSearchForm.get('location').value;
      }
      if (this.destinySearchForm.get('location').value) {
        this.searchUrl += 'localidad=' + this.destinySearchForm.get('location').value;
        console.log(this.destinySearchForm.get('location'));
      }
      if (this.searchHome && isClicked) {
        location.replace(this.searchUrl);
      }
    }
  }

  removeMetaTags() {
    const metaTags = Array.from(document.querySelectorAll('meta'));

    metaTags.forEach((metaTag: Element) => {
      this.renderer.removeChild(document.head, metaTag);
    });
  }

  getExperiences() {
    this.experiencesService.getAllExperiencesByMakerId(this.makerService.getId(), undefined, this.location.path().substring(1, 3)).subscribe(((resp: any) => {
      resp['hydra:member'].forEach(experience => {
        if (experience.microsheetCompleted && experience.maker.directoryCompleted) {
          this.experiences.push(experience);
        }
      });
      console.log(this.experiences)
    }))
  }

  searchExperiences(value) {
    let searchUrl = this.translate.getDefaultLang() + '/experiencias?';

    searchUrl += 'localidad=' + value;

    location.replace(searchUrl);
  }

  loadLoveSlider() {
    setTimeout(() => {
      this.loveSlider = new KeenSlider(this.loveSliderRef.nativeElement, {
        initial: this.loveCurrentSlide,
        loop: true,
        slideChanged: (s) => {
          this.loveCurrentSlide = s.track.details.rel;
          this.cdref.detectChanges();
        },
        breakpoints: {
          "(min-width: 0px)": {
            slides: { spacing: 32 },
          },
          "(min-width: 992px)": {
            slides: { spacing: 37 },
          }
        },
        slides: { perView: 1, spacing: 37 },
      })
      this.loveDotHelper = Array.from({ length: Math.ceil(this.loveSlider.track.details.slides.length) });
      this.loveSlidesLength = Math.ceil(this.loveSlider.track.details.slides.length);
      this.cdref.detectChanges();
    });
  }

  getLang() {
    if(this.location.path().substring(1, 3)){
      return this.location.path().substring(1, 3) + '/';
    } else {
      return ''
    }
  }

}
