<div class="row justify-content-between">
  <div class="col-md-8 d-md-flex align-items-center">
    <h1 class="page-title m-0 mr-3">
      <i class="fa fa-binoculars small mr-3"></i>{{ translate.instant(title) }}
    </h1>
    <button *ngIf="experience" class="previewButton mr-3"
      (click)="openPreview(webSheetTemplate,{class: 'webSheetTemplate-modal'})" [disabled]="!isWebSheetValid">
      <span class="vi-globe"></span>
      Ver en Web
    </button>
    <button *ngIf="experience" class="previewButton"
      (click)="openPreview(miniSheetTemplate,{class: 'miniSheetTemplate-modal'})" [disabled]="!isMicroSheetValid">
      <span class="vi-calendar"></span>
      Ver Microficha
    </button>
    <!-- <div class="row">
      <div class="col-7">
        <button [disabled]="!civitatisId" type="button" class="btn btn-primary ml-4 mb-2" *ngIf="!experience"
          (click)="importFrom('civitatis')">
          {{ "Importar desde civitatis" }}
        </button>
      </div>
      <div class="col-5">
        <input [placeholder]="'id'" *ngIf="!experience" type="text" class="form-control" [(ngModel)]="civitatisId" />
      </div>
    </div> -->
  </div>

  <div class="col-md-2 justify-content-end form-group">
    <label>{{ "" | translate }} <span class="form-required text-danger"></span></label>
    <ng-select [readonly]="!experience" (change)="changeLanguage($event)"
      [placeholder]="translate.instant('Lenguaje Seleccionado')">
      [(ngModel)]="languagesSelected">
      <ng-option [value]="'es'" selected>
        <a>Español</a>
        <!-- *ngFor="let languageWeb of languagesWeb['languageWeb'].value">{{languageWeb}} -->
      </ng-option>
      <ng-option
        [value]="'en'">
        <a>English</a>
      </ng-option>
      <ng-option
        [value]="'de'">
        <a>Deutsch</a>
      </ng-option>

      <!-- <ng-option
        *ngIf="experience && experience.maker && experience.maker['makerPlan']['plan']['shortName'] == 'Plan Premium'"
        [value]="'en'">
        <a>English</a>
      </ng-option>
      <ng-option
        *ngIf="experience && experience.maker && experience.maker['makerPlan']['plan']['shortName'] == 'Plan Premium'"
        [value]="'de'">
        <a>Deutsch</a>
      </ng-option> -->
    </ng-select>
  </div>
</div>
<div class="row mb-3" *ngIf="experience">
  <div class="col">
    <h3>{{experience.name}}</h3>
  </div>
</div>
<ul class="nav nav-tabs mb-n1 small">
  <li class="nav-item">
    <a class="nav-link"
      [ngClass]="{ 'active font-weight-bold': showTab == 'microSheet', 'green': isMicroSheetValid == true, 'red': isMicroSheetValid == false }"
      (click)="showTab = 'microSheet'">{{ translate.instant("Microficha") }}</a>
  </li>
  <li class="nav-item">
    <a class="nav-link"
      [ngClass]="{ 'active font-weight-bold': showTab == 'sheet', 'green': isWebSheetValid == true, 'red': isWebSheetValid == false }"
      (click)="showTab = 'sheet'">{{
      translate.instant("Ficha") }}</a>
  </li>
  <!-- <li class="nav-item">
    <a *ngIf="experience && experience.maker && experience.maker['makerPlan']['plan']['shortName'] != 'Plan Free'" class="nav-link"
      [ngClass]="{ 'active font-weight-bold': showTab == 'pin', 'green': isPinValid == true, 'red': isPinValid == false }"
      (click)="showTab = 'pin'">Ficha ampliada</a>
  </li> -->
</ul>
<div class="card">
  <div class="card-body p-4">
    <form [formGroup]="experienceForm" class="maker-admin-form">
      <div *ngIf="showTab == 'microSheet'">
        <div class="row">
          <div class="col-lg-6 form-group">
            <label>{{ "Título de la experiencia" | translate
              }}<span class="form-required text-danger">*</span></label>
            <input [placeholder]="translate.instant('Título de la experiencia')" type="text" class="form-control"
              formControlName="name" />
            <small class="text-danger" *ngIf="showError('name')">
              {{ translate.instant(errors["experienceName"]) }}
            </small>
          </div>

          <div class="col-lg-6 form-group">
            <label>{{ "URL de la experiencia" | translate
              }}<span class="form-required text-danger">*</span></label>
            <input [placeholder]="translate.instant('URL de la experiencia')" type="url" class="form-control"
              formControlName="url" />
            <small class="text-danger" *ngIf="showError('url')">
              {{ translate.instant(errors["experienceUrl"]) }}
            </small>
          </div>
          <!-- <div class="col-md-6 form-group">
            <label>{{ 'Texto del botón' | translate}}<small> {{ '(URL experiencia)' | translate}}</small>&nbsp;<span
                class="form-required text-danger"></span></label>
            <ng-select [placeholder]="translate.instant('Seleccionar texto')" formControlName="urlButtonText">
              <ng-option *ngFor="let buttonText of urlButtonTexts" [value]="buttonText.value">{{ buttonText.text
                }}</ng-option>
            </ng-select>
            <small class="text-danger" *ngIf="showError('urlButtonText')">
              {{translate.instant(errors['experienceUrlButtonText'])}}
            </small>
          </div> -->
          <div class="col-12 form-group">
            <label>{{ "Breve descripción de tu producto" | translate }}
              <span class="form-required text-danger">*</span></label>
            <textarea [placeholder]="translate.instant('Descripción')" class="form-control"
              formControlName="description" [(ngModel)]="descriptionText" maxlength="{{ descriptionMaxChar }}"
              rows="4"></textarea>
            <span class="pull-right badge badge-dark mt-1">{{ descriptionText?.length }} / {{ descriptionMaxChar
              }}</span>
            <small class="form-text text-muted">{{
              "Mín. 120 y máx. 600 caracteres" | translate
              }}</small>
            <small class="text-danger" *ngIf="showError('description')">
              {{ translate.instant(errors["description"]) }}
            </small>
          </div>
        </div>
        <!-- <div class="row">
          <div class="col-md-6 form-group">
            <label>Inicio de temporada</label>
            <ng-select placeholder="Seleccionar mes" formControlName="seasonStartMonth">
              <ng-option *ngFor="let month of monthList" [value]="month">{{ month }}</ng-option>
            </ng-select>
          </div>
          <div class="col-md-6 form-group">
            <label>Fin de temporada</label>
            <ng-select placeholder="Seleccionar mes" formControlName="seasonEndMonth">
              <ng-option *ngFor="let month of monthList" [value]="month">{{ month }}</ng-option>
            </ng-select>
          </div>
        </div> -->
        <div class="row"></div>
        <div class="row">
          <div class="col-md-2 form-group">
            <label>{{ "Duración experiencia" | translate }}
              <span class="form-required text-danger">*</span></label>
            <input [placeholder]="translate.instant('Duración')" type="time" class="form-control"
              formControlName="duration" />
            <small class="text-danger" *ngIf="showError('duration')">
              {{ translate.instant(errors["experienceDuration"]) }}
            </small>
          </div>
          <!-- <div class="col-md-3 form-group">
            <label>{{ "Desde" | translate }}
              <span class="form-required text-danger"></span></label>
            <input [placeholder]="translate.instant('Desde')" type="date" class="form-control"
              formControlName="fromDate" [value]="convertDate(experienceForm.get('fromDate').value)" />
            <small class="text-danger" *ngIf="showError('fromDate')">
              {{ translate.instant(errors["experienceFromDate"]) }}
            </small>
          </div>

          <div class="col-md-3 form-group">
            <label>{{ "Hasta" | translate }}
              <span class="form-required text-danger"></span></label>
            <input [placeholder]="translate.instant('Hasta')" type="date" class="form-control" formControlName="toDate"
              [value]="convertDate(experienceForm.get('toDate').value)" />
            <small class="text-danger" *ngIf="showError('toDate')">
              {{ translate.instant(errors["experienceToDate"]) }}
            </small>
          </div> -->
          <div class="col-md-5 form-group">
            <label [title]="'Meses de la experiencia' | translate ">{{ "Meses de la experiencia"
              | translate }}
              <span class="form-required text-danger">*</span></label>
            <ng-select bindLabel="months" (change)="setMonths($event)" [placeholder]="
                translate.instant('Meses de la experiencia')
              " multiple="true" [closeOnSelect]="false" formControlName="months" [(ngModel)]="monthSelected">
              <ng-option [value]="month.value" *ngFor="let month of monthsList">{{ translate.instant(month.text)
                }}</ng-option>
            </ng-select>

            <small class="text-danger" *ngIf="showError('weekDays')">
              {{ translate.instant(errors["experienceWeekDays"]) }}
            </small>
          </div>
          <div class="col-md-5 form-group">
            <label [title]="'Días de la semana de la experiencia' | translate ">{{ "Días de la semana de la experiencia"
              | translate }}
              <span class="form-required text-danger"></span></label>
            <ng-select bindLabel="weekDays" (change)="setWeekDays($event)" [placeholder]="
                translate.instant('Días de la semana de la experiencia')" multiple="true" [closeOnSelect]="false"
              formControlName="weekDays" [(ngModel)]="weekDaySelected">
              <ng-option [value]="weekDay.value" *ngFor="let weekDay of weekDays">{{ translate.instant(weekDay.text)
                }}</ng-option>
            </ng-select>

            <small class="text-danger" *ngIf="showError('weekDays')">
              {{ translate.instant(errors["experienceWeekDays"]) }}
            </small>
          </div>
        </div>
        <div class="row">

          <div class="col-md-6 form-group">
            <label>{{ "Categorías" | translate }}
              <span class="form-required text-danger">*</span></label>
            <ng-select bindLabel="Categorías" [placeholder]="translate.instant('Seleccionar categoría')" multiple="true"
              [closeOnSelect]="false" formControlName="categories" [(ngModel)]="categoriesSelected"
              (change)="loadSubcategories($event)">
              <ng-option [value]="category['@id']" *ngFor="let category of categories">{{ category.name }}</ng-option>
            </ng-select>
            <small class="text-danger" *ngIf="showError('categories')">
              {{ translate.instant(errors["experienceCategories"]) }}
            </small>
          </div>

          <div class="col-md-6 form-group">
            <label>{{ "Subcategorías" | translate }}
              <span class="form-required text-danger"></span></label>
            <ng-select bindLabel="Subcategorías" [placeholder]="translate.instant('Seleccionar subcategorías')"
              multiple="true" [closeOnSelect]="false" [(ngModel)]="subcategoriesSelected"
              formControlName="subcategories">
              <ng-option [value]="category['@id']" *ngFor="let category of subcategories">{{ category.name
                }}</ng-option>
            </ng-select>
            <small class="text-danger" *ngIf="showError('categories')">
              {{ translate.instant(errors["experienceCategories"]) }}
            </small>
          </div>


          <div class="col-md-4 form-group">
            <label>{{ "Etiquetas" | translate }}
              <span class="form-required text-danger"></span></label>
            <ng-select bindLabel="Etiquetas" [placeholder]="translate.instant('Seleccionar etiquetas')" multiple="true"
              [closeOnSelect]="false" formControlName="tags" [(ngModel)]="tagsSelected">
              <ng-option [value]="tag['@id']" *ngFor="let tag of tags">{{
                tag.name
                }}</ng-option>
            </ng-select>
            <small class="text-danger" *ngIf="showError('tags')">
              {{ translate.instant(errors["experienceTags"]) }}
            </small>
          </div>
          <div class="col-md-4 form-group">
            <label>{{ "Idiomas" | translate }}
              <span class="form-required text-danger">*</span></label>
            <ng-select bindLabel="Idiomas" [placeholder]="translate.instant('Seleccionar idioma')" multiple="true"
              [closeOnSelect]="false" [closeOnSelect]="false" formControlName="languages"
              [(ngModel)]="languagesSelected">
              <ng-option [value]="language['@id']" *ngFor="let language of languages">{{ language.name }}</ng-option>
            </ng-select>
            <small class="text-danger" *ngIf="showError('languages')">
              {{ translate.instant(errors["experienceLanguages"]) }}
            </small>
          </div>
          <div class="col-md-4 form-group">
            <label>{{ "Precio de la experiencia" | translate
              }}<span class="form-required text-danger"></span></label>
            <!-- <small class="text-muted pl-2 align-text-top">{{
            'Máx. 160 caracteres' | translate}}</small> -->
            <input [placeholder]="
                translate.instant('Introducir precio de la experiencia')
              " type="number" class="form-control" formControlName="price" maxlength="160" />
            <small class="text-danger" *ngIf="showError('price')">
              {{ translate.instant(errors["experiencePrice"]) }}
            </small>
          </div>
          <!-- <div class="col-md-4 form-group">
            <label>{{ "Tipo de precio" | translate }}
              <span class="form-required text-danger"></span></label>
            <ng-select bindLabel="typePrice" [placeholder]="translate.instant('Tipo de precio')"
              formControlName="typePrice" [(ngModel)]="typePriceSelected">
              <ng-option [value]="typePrice.value" *ngFor="let typePrice of typePrices">{{
                translate.instant(typePrice.text) }}</ng-option>
            </ng-select>
            <small class="text-danger" *ngIf="showError('typePrice')">
              {{ translate.instant(errors["experienceTypePrice"]) }}
            </small>
          </div> -->
        </div>
        <div class="row">
          <div class="col-md-4 form-group">
            <label>{{ "País" | translate }}
              <span class="form-required text-danger">*</span></label>
            <ng-select [placeholder]="translate.instant('Seleccionar el país')" aria-label="Country"
              formControlName="country" [(ngModel)]="countrySelected" (change)="loadLocations($event)" required>
              <ng-option *ngFor="let country of countries" [value]="country.code">{{ country.text }}</ng-option>
            </ng-select>
            <small class="text-danger" *ngIf="showError('country')">
              {{ translate.instant(errors["experienceCountry"]) }}
            </small>
          </div>
          <div class="col-md-4 form-group">
            <label>{{ "Ubicación" | translate }}
              <span class="form-required text-danger">*</span></label>
            <ng-select [placeholder]="translate.instant('Seleccionar la ubicación')" aria-label="Location"
              (change)="loadZones($event)" formControlName="location" [(ngModel)]="locationSelected" required
              [readonly]="!experienceForm.get('country').value">
              <ng-option *ngFor="let location of filteredLocations | async" [value]="location['@id']">{{
                location.getName() }}</ng-option>
            </ng-select>
            <!-- <small class="text-danger" *ngIf="showError('location')">
              {{ translate.instant(errors["experienceLocation"]) }}
            </small> -->
          </div>
          <div class="col-md-4 form-group">

            <label class="mr-1">{{ "Zonas" | translate }}
              <span class="form-required text-danger">*</span><a
                [tooltip]="translate.instant('Si ofreces un servicio de traslado, selecciona aquí tus zonas de recogida. Si no tienes recogidas, marca Todas las zonas. ')"
                target="_blank" rel="noopener noreferrer"><i-feather name="help-circle"></i-feather></a></label>


            <ng-select [placeholder]="translate.instant('Selecciona la zona')" aria-label="Zones"
              formControlName="zones" [multiple]="true" [(ngModel)]="zoneSelected"
              [readonly]="!experienceForm.get('location').value">
              <ng-option *ngIf="zoneSelected && zoneSelected.length == 0" [value]="'all-zones'">Todas las
                zonas</ng-option>
              <ng-option *ngIf="!zoneSelected" [value]="'all-zones'">Todas las zonas</ng-option>
              <ng-container *ngIf="zoneSelected != 'all-zones'">
                <ng-option *ngFor="let zone of filteredZone | async" [value]="zone['@id']">{{ zone.getName()
                  }}</ng-option>
              </ng-container>
            </ng-select>
            <small class="text-danger" *ngIf="showError('zones')">
              {{ translate.instant(errors["experienceZone"]) }}
            </small>
          </div>
          <!-- <div class="col-md-6 form-group">
            <label>{{ 'Zona' | translate}} <span class="form-required text-danger">*</span></label>
            <ng-select [placeholder]="translate.instant('Seleccionar la ubicación')" aria-label="Zone"
              formControlName="zone" [(ngModel)]="zoneSelected" required
              [readonly]="!experienceForm.get('country').value">
              <ng-option *ngFor="let zone of zoneLocations | async" [value]="zone['@id']">{{
                zone.getName() }}</ng-option>
            </ng-select>
            <small class="text-danger" *ngIf="showError('location')">
              {{translate.instant(errors['experienceLocation'])}}
            </small>
          </div> -->
        </div>
        <div class="row">
          <div class="col form-group">
            <label for="mediaCropper" class="mb-0">{{ "Seleccionar Imagenes" | translate }}
              <span class="form-required text-danger">*</span></label>
            <small class="d-block text-muted mb-2">{{
              "Selecciona Imagenes para mostrar en el listado de Experiencias."
              | translate
              }}
              <strong>{{ "Mín. 5 y máximo 10 imagenes." | translate }}</strong>
              {{ "Formatos aceptados: .jpeg, .jpg, .png" | translate }}
            </small>
            <app-cropper id="mediaCropper" [loadedImages]="mediaExperiences.length" [type]="'experience'"
              [preview]="'preview2'" [aspectratio]="1" (uploadedImage)="onUploadedImage($event, 'mediaExperiences')"
              (isTooManyImages)="isTooManyImages = true"></app-cropper>
            <small class="text-danger" *ngIf="showError('mediaExperiences')">
              {{ translate.instant(errors["mediaExperiences"]) }}
            </small>
            <small class="text-danger" *ngIf="isTooManyImages">
              {{translate.instant('Máximo 10 imágenes')}}
            </small>
          </div>
        </div>
        <div *ngIf="mediaExperiences" class="container__uploaded-images">
          <h5 *ngIf="mediaExperiences?.length">
            <i class="fa fa-image small"></i>
            {{ "Imagenes subidas" | translate }}
          </h5>
          <div class="row">
            <div class="col-lg-2 col-md-4" *ngFor="let mediaExperience of mediaExperiences.slice(-10)">
              <img [src]="'https://venntur.com' + mediaExperience.url" alt="Imagen de la experiencia"
                class="img-fluid" /><br />
              <button class="btn btn-outline-primary btn--delete-image"
                (click)="deleteMediaExperience(mediaExperience.id, true)">
                <i class="fa fa-trash"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="showTab == 'sheet'">
        <!-- <div class="row">
          <div class="col-md-6 form-group">
            <label>{{ "Slug SEO" | translate
              }}<span class="form-required text-danger"> </span></label>
            <input [placeholder]="" [ngModel]="this.experienceForm.get('slug').value" type="text" class="form-control"
              formControlName="slug" maxlength="160" />
            <small class="text-danger" *ngIf="showError('slug')">
              {{ translate.instant(errors["experienceSlug"]) }}
            </small>
          </div>
        </div> -->
        <div class="row">

          <div class="col-12 form-group">
            <label>{{ "Descripción extendida" | translate }}<span class="form-required text-danger">*</span></label>
            <!-- <textarea [placeholder]="translate.instant('Descripción extendida')" type="text" class="form-control"
              formControlName="descriptionExtended" maxlength="1200">
              </textarea> -->
            <!-- <editor apiKey="sjgwd4uj0fldb1yk4wv7846bmubr5yqqcq94k63mz61deiz3" [init]="{ 
                    plugins: 'lists link image table code help wordcount', contextmenu: false}"
              [initialValue]="editorTemplate" formControlName="descriptionExtended"></editor> -->
            <ckeditor [editor]="Editor" formControlName="descriptionExtended"></ckeditor>
            <div class="mt-3" [innerHTML]="editorTemplateStaticData"></div>
            <!-- <textarea id="mytextarea">Hello, World!</textarea> -->
            <!-- <textarea class="w-100" type="textarea" placeholder="Introduce aquí tu mensaje..."
                    formControlName="message"></textarea> -->
            <small class="text-muted pl-2 align-text-top">{{
              "Máx. 1200 caracteres" | translate
              }}</small>
            <small class="text-danger" *ngIf="showError('descriptionExtended')">
              <!-- {{ translate.instant(errors["descriptionExtended"]) }} -->
            </small>
          </div>

          <div class="col-12 form-group">
            <!-- <label>{{ "¿Por qué elegir este tour?" | translate }}
              <span class="form-required text-danger"></span></label>
            <textarea [placeholder]="translate.instant('¿Por qué elegir este tour?')" class="form-control"
              formControlName="whyUs" [ngModel]="this.experienceForm.get('whyUs').value"
              maxlength="{{ descriptionMaxChar }}" rows="4"></textarea> -->
            <label>{{ "Información importante" | translate }}
              <span class="form-required text-danger">*</span></label>
            <textarea [placeholder]="translate.instant('Información importante')" class="form-control"
              formControlName="whyUs" [ngModel]="this.experienceForm.get('whyUs').value"
              maxlength="{{ descriptionMaxChar }}" rows="4"></textarea>
            <span class="pull-right badge badge-dark mt-1">{{
              this.experienceForm.get("whyUs").value
              ? this.experienceForm.get("whyUs").value.length
              : 0
              }}
              / {{ descriptionMaxChar }}</span>
            <small class="form-text text-muted">{{
              "Mín. 120 y máx. 600 caracteres" | translate
              }}</small>
            <!-- <small class="text-danger" *ngIf="showError('whyUs')">
              {{ translate.instant(errors["experienceWhyUs"]) }}
            </small> -->
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 form-group">
            <label class="mr-1">{{ "¿Qué incluye el tour?" | translate }}
              <span class="form-required text-danger">*</span><a
                [tooltip]="translate.instant('Escribe la opción y pulsa Enter')" target="_blank"
                rel="noopener noreferrer"><i-feather name="help-circle"></i-feather></a></label>

            <ng-select appendTo="invisibleContainerIncludes" (blur)="insertTag($event, 'includes')"
              (keydown.enter)="insertTag($event, 'includes')" [placeholder]="
                translate.instant('Introduce etiquetas de la experiencia')
              " multiple="true" [closeOnSelect]="false" formControlName="includes">
              <!-- <ng-option [value]="include"
                *ngFor="let include of experienceForm.controls['includes'].value">{{include}}</ng-option> -->
            </ng-select>
            <div id="invisibleContainerIncludes" style="display: none"></div>
            <small class="text-danger" *ngIf="showError('tags')">
              {{ translate.instant(errors["experienceTags"]) }}
            </small>
          </div>
          <div class="col-md-6 form-group">
            <label class="mr-1">{{ "¿Qué no incluye el tour?" | translate }}
              <span class="form-required text-danger">*</span><a
                [tooltip]="translate.instant('Escribe la opción y pulsa Enter')" target="_blank"
                rel="noopener noreferrer"><i-feather name="help-circle"></i-feather></a></label>

            <ng-select appendTo="invisibleContainerNotIncludes" (keydown.enter)="insertTag($event, 'notIncludes')"
              [placeholder]="
                translate.instant('Introduce etiquetas de la experiencia')
              " multiple="true" [closeOnSelect]="false" formControlName="notIncludes"
              [ngModel]="this.experienceForm.get('notIncludes').value">
              <!-- <ng-option [value]="notInclude"
                *ngFor="let notInclude of experienceForm.controls['notIncludes'].value">{{notInclude}}</ng-option> -->
            </ng-select>
            <div id="invisibleContainerNotIncludes" style="display: none"></div>
            <small class="text-danger" *ngIf="showError('tags')">
              <!-- {{ translate.instant(errors["experienceTags"]) }} -->
            </small>
          </div>
        </div>
        <div class="row">
          <!-- <div class="col-md-6 form-group">
            <label class="mr-1">{{
              "Que debes saber de la experiencia" | translate
              }}
              <span class="form-required text-danger"></span></label>
            <a [tooltip]="translate.instant('Escribe la opción y pulsa Enter')" target="_blank"
              rel="noopener noreferrer"><i-feather name="help-circle"></i-feather></a>
            <ng-select appendTo="invisibleContainerMoreInfo" (keydown.enter)="insertTag($event, 'moreInfo')"
              [placeholder]="
                translate.instant('Introduce etiquetas de la experiencia')
              " multiple="true" formControlName="moreInfo" [ngModel]="this.experienceForm.get('moreInfo').value">
            </ng-select>
            <div id="invisibleContainerMoreInfo" style="display: none"></div>
            <small class="text-danger" *ngIf="showError('moreInfo')">
              {{ translate.instant(errors["moreInfo"]) }}
            </small>
          </div> -->

        </div>
        <div class="row">

          <div class="col-md-6 form-group">
            <label class="mr-1">{{
              "Qué debes llevar a la experiencia" | translate
              }}
              <span class="form-required text-danger">*</span><a
                [tooltip]="translate.instant('Escribe la opción y pulsa Enter')" target="_blank"
                rel="noopener noreferrer"><i-feather name="help-circle"></i-feather></a></label>

            <ng-select appendTo="invisibleContainerCarryInfo" (keydown.enter)="insertTag($event, 'carryInfo')"
              [placeholder]="
                translate.instant('Introduce etiquetas de la experiencia')
              " multiple="true" [closeOnSelect]="false" formControlName="carryInfo"
              [ngModel]="this.experienceForm.get('carryInfo').value">
            </ng-select>
            <div id="invisibleContainerCarryInfo" style="display: none"></div>
            <small class="text-danger" *ngIf="showError('carryInfo')">
              <!-- {{ translate.instant(errors["carryInfo"]) }} -->
            </small>
          </div>

          <div class="col-md-6 form-group">
            <label>{{ "Máximo de personas" | translate
              }}<span class="form-required text-danger"> </span></label>
            <!-- <small class="text-muted pl-2 align-text-top">{{
              'Máx. 160 caracteres' | translate}}</small> -->
            <input [placeholder]="
                translate.instant('Introducir máximo de personas')
              " type="number" class="form-control" formControlName="persons" maxlength="160" />
            <small class="text-danger" *ngIf="showError('persons')">
              <!-- {{ translate.instant(errors["experiencePersons"]) }} -->
            </small>
          </div>
          <div class="col-md-4 form-group">
            <label>{{ "Tipo de promoción" | translate }}</label>
            <ng-select bindLabel="Tipo de promoción" [placeholder]="translate.instant('Seleccionar tipo de promoción')"
              formControlName="promotionType" (change)="changeValidation($event)">
              [(ngModel)]="promotionTypeSelected">
              <ng-option [value]="''">Ninguna</ng-option>
              <ng-option [value]="promotionType" *ngFor="let promotionType of promotionTypes">{{ promotionType |
                translate }}</ng-option>
            </ng-select>
          </div>

          <div class="col-md-4 form-group">
            <label>{{ "Descripción de la promoción" | translate }}</label>
            <small class="text-muted pl-2 align-text-top">{{
              "Máx. 160 caracteres" | translate
              }}</small>
            <input [placeholder]="translate.instant('Promoción')" type="text" class="form-control"
              formControlName="promotion" maxlength="160" />
            <small class="text-danger" *ngIf="showError('promotion')">
              <!-- {{ translate.instant(errors["promotion"]) }} -->
            </small>
          </div>

          <div class="col-md-4 form-group">
            <label>{{ "Código promocional" | translate
              }}<span class="form-required text-danger"></span></label>
            <input [placeholder]="translate.instant('Código promocional')" type="text" class="form-control"
              formControlName="promoCode" />
            <small class="text-danger" *ngIf="showError('promoCode')">
              <!-- {{ translate.instant(errors["experiencePromoCode"]) }} -->
            </small>
          </div>

        </div>
        <div class="row">


          <div class="col-md-4 form-group">
            <label>{{ 'Sistema motor de reservas' | translate}} <span class="form-required text-danger">*</span></label>
            <ng-select bindLabel="Booking Engine" [placeholder]="translate.instant('Seleccionar motor de reservas')"
              formControlName="bookingEngine" (change)="bookingEngine = experienceForm.get('bookingEngine').value">
              <ng-option value="Turitop">Turitop</ng-option>
              <ng-option value="Fareharbor">Fareharbor</ng-option>
              <ng-option value="Bokun">Bokun</ng-option>
              <ng-option value="Propio">Sistema propio</ng-option>
              <ng-option value="Others">Otros</ng-option>
            </ng-select>
            <small class="text-danger" *ngIf="showError('bookingEngine')">
            </small>
          </div>
          <div class="col-md-4 form-group">
            <label>
              {{ 'ID Sistema motor de reservas' | translate}}
              ({{ bookingEngine | translate }})
              <span class="form-required text-danger"></span></label>
            <input [placeholder]="translate.instant('ID Sistema motor de reservas')" type="text" class="form-control"
              formControlName="bookingEngineWidget">
            <small class="text-danger" *ngIf="showError('bookingEngineWidget')">
              {{translate.instant(errors['bookingEngineWidget'])}}
            </small>
          </div>
          <div class="col-md-4 form-group"
            *ngIf="experienceForm.get('bookingEngine').value == 'Fareharbor' || !experienceForm.get('bookingEngine').value && makerInfo && makerInfo.bookingEngine == 'Fareharbor'">
            <label>{{ "Farehabor ID de la experiencia" | translate
              }}<span class="form-required text-danger"> </span></label>
            <!-- <small class="text-muted pl-2 align-text-top">{{
              'Máx. 160 caracteres' | translate}}</small> -->
            <input [placeholder]="
                translate.instant('Introducir Farehabor ID de la experiencia')
              " type="text" class="form-control" formControlName="fareharborId" maxlength="160" />
            <small class="text-danger" *ngIf="showError('fareharborId')">
              <!-- {{ translate.instant(errors["experienceFareharbor"]) }} -->
            </small>
          </div>

          <div class="col-md-4 form-group"
            *ngIf="experienceForm.get('bookingEngine').value == 'Bokun' || !experienceForm.get('bookingEngine').value && makerInfo && makerInfo.bookingEngine == 'Bokun'">
            <label>{{ "Bokun ID de la experiencia" | translate
              }}<span class="form-required text-danger"> </span></label>
            <!-- <small class="text-muted pl-2 align-text-top">{{
              'Máx. 160 caracteres' | translate}}</small> -->
            <input [placeholder]="
                translate.instant('Introducir Bokun ID de la experiencia')
              " type="text" class="form-control" formControlName="bokunId" maxlength="160" />
            <small class="text-danger" *ngIf="showError('bokunId')">
              <!-- {{ translate.instant(errors["experienceFareharbor"]) }} -->
            </small>
          </div>

          <div class="col-md-4 form-group"
            *ngIf="experienceForm.get('bookingEngine').value == 'Turitop' || !experienceForm.get('bookingEngine').value && makerInfo && makerInfo.bookingEngine == 'Turitop'">
            <label>{{ "Turitop ID de la experiencia" | translate
              }}<span class="form-required text-danger"> </span></label>
            <!-- <small class="text-muted pl-2 align-text-top">{{
              'Máx. 160 caracteres' | translate}}</small> -->
            <input [placeholder]="
                translate.instant('Introducir Turitop ID de la experiencia')
              " type="text" class="form-control" formControlName="turitopId" maxlength="100" />
            <small class="text-danger" *ngIf="showError('turitopId')">
              <!-- {{ translate.instant(errors["turitopId"]) }} -->
            </small>
          </div>
          <div class="col-md-4 form-group">
            <label>Tripadvisor</label>
            <input placeholder="Tripadvisor" type="text" class="form-control" formControlName="reviewTripadvisor" />
          </div>

          <div class=".col-12 col-md-4 form-group">
            <label>{{ "ID Reseñas actividad Get Your Guide" | translate }}</label>
            <input placeholder="Reviews Get Your Guide" type="text" class="form-control"
              formControlName="reviewGetyourguide" />
          </div>

          <!-- <div class="row">
            <div class="col-md-6 form-group">
              <label>{{ 'Tripadvisor' }}</label>
              <div class="form-check radio-inline">
                <input class="form-check-input radio-inline" type="radio" formControlName="tripAdv" id="flexRadioDefault1" value="si">
                <label class="form-check-label" for="flexRadioDefault1">Sí</label><br>
                <input class="form-check-input radio-inline" type="radio" formControlName="tripAdv" id="flexRadioDefault2" value="no" checked>
                <label class="form-check-label" for="flexRadioDefault2">No</label>
              </div>
            </div>
          </div>
          <div class="col-md-6 form-group form-check">
            <label class="form-check-label" for="sustainableSeal">Experiencia sostenible</label>
            <div class="form-group form-check mt-2">
              <input type="checkbox" class="form-check-input" formControlName="sustainableSeal" id="sustainableSeal">
              <label class="form-check-label" for="sustainableSeal">Sello sostenibilidad</label>
            </div>
          </div> -->
        </div>
        <div class="row">
          <div class="col form-group">
            <label>{{ "Cancelación de la experiencia" | translate
              }}<span class="form-required text-danger"> </span></label>
            <!-- <small class="text-muted pl-2 align-text-top">{{
              'Máx. 160 caracteres' | translate}}</small> -->
            <input [placeholder]="
                translate.instant(
                  'Escribir detalles de cancelación de la experiencia'
                )
              " type="text" class="form-control" formControlName="cancellation" maxlength="160" />
            <small class="text-danger" *ngIf="showError('cancellation')">
              <!-- {{ translate.instant(errors["experienceCancellation"]) }} -->
            </small>
          </div>
        </div>
      </div>
      <div *ngIf="showTab == 'pin'">
        <div class="row">


          <!--  <div class="col-md-4 form-group">
            <label>{{ 'Categorías' | translate}} <span class="form-required text-danger"></span></label>
            <ng-select bindLabel="Categorías" [placeholder]="translate.instant('Seleccionar categoría')" multiple="true"
              appendTo="body" formControlName="categories" [(ngModel)]="categoriesSelected">
              <ng-option [value]="category['@id']" *ngFor="let category of categories">{{category.name}}</ng-option>
            </ng-select>
            <small class="text-danger" *ngIf="showError('categories')">
              {{translate.instant(errors['experienceCategories'])}}
            </small>
          </div> -->

          <!-- Currency / Moneda -->

          <!-- highlights -->





          <div class="col-md-12 form-group row">
            <div class="col-md-10 form-group">
              <label>{{ "Highlights" | translate }}<a [tooltip]="translate.instant('Escribe la opción y pulsa Enter')"
                  target="_blank" rel="noopener noreferrer"><i-feather name="help-circle"></i-feather></a></label>
              <ng-select [appendTo]="'invisibleContainerHighlight'"
                (keydown.enter)="insertTagMax5($event, 'highlights')" [placeholder]="" multiple="true"
                [closeOnSelect]="false" formControlName="highlights"
                [ngModel]="this.experienceForm.get('highlights').value">
                <!-- <ng-option [value]="moreInfo"
                *ngFor="let moreInfo of experienceForm.controls['moreInfo'].value">{{moreInfo}}</ng-option> -->
              </ng-select>
              <small class="text-danger" *ngIf="showError('highlights')">
                <!-- {{ translate.instant(errors["highlights"]) }} -->
              </small>
              <div [id]="'invisibleContainerHighlight'" style="display: none"></div>

              <!--   <input [placeholder]="translate.instant('Introducir highlight '+(i+1))" type="text" class="form-control my-2" maxlength="160"
                (change)="pushHighlight($event)"> -->
            </div>

            <div class="col-md-2 form-group">
              <label>{{ "Moneda" | translate }}</label>
              <ng-select bindLabel="Moneda" [placeholder]="translate.instant('Seleccionar moneda')"
                formControlName="currency" (change)="changeValidation($event)">
                [(ngModel)]="currencySelected">
                <ng-option [value]="currency" *ngFor="let currency of currencies">{{ currency | translate }}</ng-option>
              </ng-select>
              <small class="text-danger" *ngIf="showError('currency')">
                <!-- {{ translate.instant(errors["currency"]) }} -->
              </small>
            </div>
          </div>


          <div class="col-md-12 form-group row">
            <div class="col-12 mb-2 d-flex flex-row no-wrap justify-content-between">
              <label *ngIf="modalities?.length" class="font-weight-bold">{{ "Modalidades de la experiencia" | translate
                }}</label>
              <button type="button" class="btn btn-primary d-flex flex-row align-items-center" (click)="addModality()">
                <i-feather name="plus"></i-feather>
                Añadir modalidad
              </button>
            </div>
            <hr />


            <input type="hidden" formControlName="modalities" />

            <!-- mat accordion -->
            <div id="accordion" class="col-12" *ngFor="let modality of modalities; let i = index">
              <div class="accordeon-container">
                <div id="headingOne" class="accordeon-header-container row justify-content-between">

                  <div class="col-6">
                    <div class="d-flex flex-row justify-content-start">
                      <p class="mb-0 cursor-pointer" data-toggle="collapse" data-target="#collapseOne"
                        [attr.aria-expanded]="isExpanded" aria-controls="collapseOne"
                        (click)="isExpanded[i] = !isExpanded[i]">
                        Modalidad {{ i + 1 }}
                        <!-- Arrow down/up  -->
                        <i-feather [name]="isExpanded[i] ? 'chevron-up' : 'chevron-down'"></i-feather>
                      </p>


                    </div>
                  </div>
                  <div class="col-6">
                    <div class="d-flex flex-row justify-content-end">
                      <!-- <button type="button" class="btn btn-primary" *ngIf="i == modalities.length - 1"
                        (click)="addModality()">
                        <i-feather name="plus"></i-feather>
                      </button> -->


                      <!-- Eliminar modalidad -->
                      <button *ngIf="modalities.length > 1" type="button" class="btn btn-danger mx-2"
                        (click)="deleteModality(i)">
                        <i class="fa fa-trash"></i>
                      </button>


                    </div>
                  </div>

                </div>

                <div id="collapseOne" class="collapse" [ngClass]="{'show': isExpanded[i]}" aria-labelledby="headingOne"
                  data-parent="#accordion">
                  <div class="card-body p-5 row">



                    <div class="row">
                      <div class="col-12 col-md-6 form-group">
                        <label>{{ "Título" | translate }}</label>
                        <input [placeholder]="translate.instant('Título')" type="text" class="form-control"
                          [value]="modality.title" (change)="modality.title = $event.target.value" />
                      </div>

                      <div class="col-12 col-md-6 form-group">
                        <label>{{ "Descripción" | translate }}</label>
                        <input [placeholder]="translate.instant('Descripción')" type="text" class="form-control"
                          [value]="modality.description" (change)="modality.description = $event.target.value" />
                      </div>

                      <!-- BookingEngine, prices (array), pickup (bool), duration, start, end, meetingPoint, location -->

                      <div class="col-6 col-md-3 form-group">
                        <label>{{ "Booking Engine ID" | translate }}</label>
                        <input [placeholder]="translate.instant('BookingEngine')" class="form-control" type="number"
                          [value]="modality.bookingEngineId"
                          (change)="modality.bookingEngineId = $event.target.value" />
                      </div>

                      <!-- Select booleano con recogida true or false -->
                      <!-- <div class="col-md-3 form-group">
                                            <label>{{ "¿Tiene recogida?" | translate }}</label>
                                           <select [value]="false" class="form-control" (change)="modality.pickupStr = $event.target.value"
                                              bindLabel="name" bindValue="value">
                                              <option *ngFor="let option of [{name: 'Si', value: true}, {name: 'No', value: false}]">
                                              {{ translate.instant(option.name) }}
                                              </option>
                                            </select>
                                          </div> -->

                      <div class="col-6 col-md-3 form-group">
                        <label>{{ "¿Tiene recogida?" | translate }}</label>
                        <!-- Radio buttons Si/no -->

                        <div class="row flex-row justify-content-start">
                          <label class="mx-2">
                            <input type="radio" name="pickup" value="true"
                              (change)="modality.pickup = true && modality.pickupStr = 'true'"
                              [checked]="modality.pickup">
                            {{ "Si" | translate }}
                          </label>
                          <label class="mx-2">
                            <input type="radio" name="pickup" value="false"
                              (change)="modality.pickup = false && modality.pickupStr = 'false'"
                              [checked]="!modality.pickup">
                            {{ "No" | translate }}
                          </label>

                        </div>


                      </div>

                      <!-- <div class="col-md-3 form-group">
                                            <label>{{ "Con recogida" | translate }}</label>
                                            <ng-select [placeholder]="translate.instant('Con recogida')" appendTo="body">
                                              <ng-option [value]="true" *ngFor="let option of [true, false]" [selected]="modality.pickup === option"
                                                (change)="modality.pickup = option">
                                                {{ translate.instant(option ? 'Si' : 'No') }}
                                              </ng-option>
                                            </ng-select>
                                          </div> -->

                      <!-- <div class="col-md-3 form-group">
                                            <label>{{ "Con recogida" | translate }}</label>
                                            <ng-select [placeholder]="translate.instant('Con recogida')" appendTo="body">
                                              <ng-option [value]="modality.pickup" *ngFor="let option of ['Si', 'No']" 
                                              (change)="modality.pickupStr = $event.target.value">
                                                {{translate.instant(option) }}
                                              </ng-option>
                                            </ng-select>
                          
                                          </div> -->

                      <div class="col-4 col-md-2 form-group">
                        <label>{{ "Duración" | translate }}</label>
                        <input [placeholder]="translate.instant('Duración')" type="time" class="form-control"
                          [value]="modality.duration" (change)="modality.duration = $event.target.value" />
                      </div>

                      <div class="col-4 col-md-2 form-group">
                        <label>{{ "Inicio" | translate }}</label>
                        <input [placeholder]="translate.instant('Inicio')" type="time" class="form-control"
                          [value]="modality.start" (change)="modality.start = $event.target.value" />
                      </div>

                      <div class="col-4 col-md-2 form-group">
                        <label>{{ "Fin" | translate }}</label>
                        <input [placeholder]="translate.instant('Fin')" type="time" class="form-control"
                          [value]="modality.end" (change)="modality.end = $event.target.value" />
                      </div>

                      <div class="col-12 col-md-6 form-group">
                        <label>{{ "Punto de encuentro" | translate }}</label>
                        <input [placeholder]="translate.instant('Punto de encuentro')" type="text" class="form-control"
                          [value]="modality.meetingPoint" (change)="modality.meetingPoint = $event.target.value" />
                      </div>

                      <div class="col-12 col-md-6 form-group">
                        <label>{{ "Ubicación" | translate }}</label>
                        <input [placeholder]="translate.instant('Ubicación')" type="text" class="form-control"
                          [value]="modality.location" (change)="modality.location = $event.target.value" />
                      </div>

                      <div class="col-md-12 mt-3">
                        <!-- <h4>{{ "Precios" | translate }}</h4> -->
                        <!-- <hr /> -->

                        <div class="row mb-2" *ngFor="let price of modality.prices; let j = index">
                          <div class="col-12">
                            <!-- <hr /> -->

                            <label><strong>{{ "Precio " + (j + 1) }}</strong></label>
                            <button *ngIf="j == modality.prices.length - 1" type="button" class="btn btn-primary mx-4"
                              (click)="addNewPrice(i)">
                              + Añadir precio
                            </button>

                            <!-- Eliminar precio -->
                            <button type="button" class="btn btn-danger mx-4" (click)="deletePrice(i, j)"
                              *ngIf="modality.prices.length > 1">
                              Eliminar precio
                            </button>
                          </div>

                          <div class="col-md-6 form-group">
                            <label>{{ "Título" | translate }}</label>
                            <input [placeholder]="translate.instant('Título')" type="text" class="form-control"
                              [value]="price.name" (change)="price.name = $event.target.value" />
                          </div>

                          <div class="col-md-6 form-group">
                            <label>{{ "Precio" | translate }}</label>
                            <input [placeholder]="translate.instant('Precio')" type="number" class="form-control"
                              [value]="price.price" (change)="price.price = $event.target.value" />
                          </div>
                        </div>
                      </div>
                    </div>




                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>

        <!-- <div class="row">
          <div class="col form-group">
            <label for="mediaCropper" class="mb-0">{{ "Seleccionar Imagenes (Ficha ampliada)" | translate }}
              <span class="form-required text-danger">*</span></label>
            <small class="d-block text-muted mb-2">{{
              "Selecciona Imagenes para mostrar en el listado de Experiencias."
              | translate
              }}
              <strong>{{ "Mín. 5 imagenes." | translate }}</strong>
              {{ "Formatos aceptados: .jpeg, .jpg, .png" | translate }}
            </small>
            <app-cropper id="mediaCropper" [type]="'experience'" [preview]="'preview2'" [aspectratio]="920/610"
              (uploadedImage)="onExpandedSheetUploadedImage($event, 'expandedMediaExperiences')"></app-cropper>
            <small class="text-danger" *ngIf="showError('expandedMediaExperiences')">
              {{ translate.instant(errors["expandedMediaExperiences"]) }}
            </small>
          </div>
        </div> -->
        <!-- <div *ngIf="expandedMediaExperiences" class="container__uploaded-images">
          <h5 *ngIf="expandedMediaExperiences?.length">
            <i class="fa fa-image small"></i>
            {{ "Imagenes subidas" | translate }}
          </h5>
          <div class="row">
            <div class="col-lg-2 col-md-4" *ngFor="let mediaExperience of expandedMediaExperiences">
              <img [src]="'https://venntur.com' + mediaExperience.url" alt="Imagen de la experiencia"
                class="img-fluid" /><br />
              <button class="btn btn-outline-primary btn--delete-image"
                (click)="deleteExpandedMediaExperience(mediaExperience.id, true)">
                <i class="fa fa-trash"></i>
              </button>
            </div>
          </div>
        </div> -->
        <div class="row my-3">
          <div class="col-12 form-group">
            <label for="mediaCropper" class="mb-0">{{ 'Subir vídeos de la experiencia' | translate }}
              <span class="form-required text-danger"></span></label>
            <small class="d-block text-muted mb-2">
              {{ 'Selecciona un vídeo para mostrar en el pin de producto. Formatos aceptados: .mp4, .avi, .webp, .mov' |
              translate}}</small>

            <!-- Important size info: 100MB -->
            <small class="d-block text-danger mb-2">
              {{ 'Tamaño máximo: 100MB' | translate}}</small>

            <!-- input type file pdf -->
            <div class="custom-file">
              <input type="file" class="custom-file-input" id="experienceVideo"
                (change)="onVideoSelected($event, 'mediaVideo')">
              <label id="experienceVideoLabel" for="experienceVideo" class="custom-file-label">{{ 'Seleccionar vídeo' |
                translate }}</label> <!-- [ngClass]="validExperiencesVideo ? 'success-border' : ''" -->

            </div>

          </div>

          <div class="col-12" *ngIf="uploadedVideos.length > 0">
            <h5 style="margin-top: 5px;">
              <i class="fa fa-video small"></i>
              {{ 'Vídeos subidos' | translate }}
            </h5>
            <div class="row">
              <div class="col-lg-3 col-md-4 col-12" *ngFor="let video of uploadedVideos">
                <video controls class="img-fluid">
                  <source [src]="video.contentUrl" type="video/mp4">
                  <source [src]="video.contentUrl" type="video/avi">
                  <source [src]="video.contentUrl" type="video/webp">
                  <source [src]="video.contentUrl" type="video/mov">
                  {{ 'Tu navegador no soporta la reproducción de vídeos' | translate }}
                </video>
                <br />
                <button class="btn btn-outline-primary btn--delete-image" (click)="deleteVideo(video)">
                  <i class="fa fa-trash"></i>
                </button>
              </div>

              <div class="col-lg-3 col-md-4 col-12" *ngIf="loadingVideo">

                <div class="d-flex justify-content-center my-4">
                  <div class="spinner-border" role="status">
                    <span class="sr-only">{{ 'Cargando...' | translate }}</span>
                  </div>
                </div>

                <div class="progress">
                  <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
                    [style.width]="progressVideo + '%'" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                    {{progressVideo}}%
                  </div>
                </div>

              </div>

            </div>


          </div>

          <div class="col-12" *ngIf="uploadedVideos.length == 0">
            <h5 style="margin-top: 5px;">
              <i class="fa fa-video small"></i>
              {{ 'Vídeos subidos' | translate }}
            </h5>
            <div class="row">
              <div class="col-12">
                <p class="text-center">{{ 'No hay vídeos subidos' | translate }}</p>
              </div>

              <div class="col-12" *ngIf="loadingVideo">

                <div class="d-flex justify-content-center my-4">
                  <div class="spinner-border" role="status">
                    <span class="sr-only">{{ 'Cargando...' | translate }}</span>
                  </div>
                </div>

                <div class="progress">
                  <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
                    [style.width]="progressVideo + '%'" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                    {{progressVideo}}%
                  </div>
                </div>

              </div>

            </div>


          </div>

        </div>





      </div>
      <!--      <div class="row">-->
      <!--        <div class="col-md-6 form-group">-->
      <!--          <label>{{ 'Tripadvisor' }}</label>-->
      <!--          <div class="form-check radio-inline">-->
      <!--            <input class="form-check-input radio-inline" type="radio" formControlName="tripAdv" id="flexRadioDefault1" value="si">-->
      <!--            <label class="form-check-label" for="flexRadioDefault1">Sí</label><br>-->
      <!--            <input class="form-check-input radio-inline" type="radio" formControlName="tripAdv" id="flexRadioDefault2" value="no" checked>-->
      <!--            <label class="form-check-label" for="flexRadioDefault2">No</label>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <div class="row">-->
      <!--        <div class="col-md-6 form-group">-->
      <!--          <label>Tripadvisor</label>-->
      <!--          <input placeholder="Tripadvisor" type="text" class="form-control" formControlName="reviewTripadvisor">-->
      <!--        </div>-->
      <!-- <div class="col-md-6 form-group form-check">
          <label class="form-check-label" for="sustainableSeal">Experiencia sostenible</label>
          <div class="form-group form-check mt-2">
            <input type="checkbox" class="form-check-input" formControlName="sustainableSeal" id="sustainableSeal">
            <label class="form-check-label" for="sustainableSeal">Sello sostenibilidad</label>
          </div>
        </div> -->
      <!-- </div> -->
      <div class="row mt-3">
        <div class="col-md-6 mb-3 mb-md-0 d-flex justify-content-md-start justify-content-center">
          <button type="button" class="btn btn-primary mr-md-2 mb-md-2 h-100" *ngIf="experience"
            routerLink="../../experiencias">
            {{ "Listado de experiencias" | translate }}
          </button>
        </div>
        <div class="col-md-6 d-flex justify-content-md-end justify-content-center">
          <a [href]="translate.getDefaultLang() + '/maker/admin/experiencias'"
            class="btn btn-light mr-2 mb-2 d-flex align-items-center h-100">{{ "Cancelar" | translate }}</a>
          <!--        <button
            type="button"
            class="btn btn-outline-primary mb-2"
            *ngIf="experience"
            (click)="deleteExperience(experience['@id'])"
          >
            {{ "Eliminar experiencia" | translate }}
          </button> -->
          <button type="button" class="btn btn-primary mr-md-2 mb-md-2 h-100" *ngIf="experience"
            (click)="createModalities()">
            {{ "Guardar Experiencia" | translate }}
          </button>
          <button type="button" class="btn btn-primary mr-md-2" *ngIf="!experience" (click)="createExperience()">
            {{ "Guardar Experiencia" | translate }}
          </button>
          <button *ngIf="showTab != tabs[1]" type="button" (click)="showTab = tabs[tabs.indexOf(showTab)+1]"
            class="btn btn-primary mr-md-2 mb-md-2 h-100">
            {{ "Continuar" | translate }}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
<div class="mt-2" *ngIf="alert.type == 1">
  <p class="alert" [class.alert-success]="alert.type == 1">
    {{ alert.message }}
  </p>
</div>
<div class="mt-2" *ngIf="alert.type == 2">
  <p class="alert" [class.alert-danger]="alert.type == 2">
    Ha habido un error enviado el formulario. Compruebe todos los campos.
  </p>
</div>

<ng-template #miniSheetTemplate id="miniSheetTemplate">
  <app-experience-card [experience]="experience" [isFooterActive]="false"></app-experience-card>
</ng-template>

<ng-template #webSheetTemplate id="webSheetTemplate">
  <div class="card">
    <div class="card-body d-flex flex-column position-relative">
      <div class="container" *ngIf="experience">
        <div class="row mt-5">
          <div class="col">
            <strong style="font-size: 26px;">
              {{experienceForm.controls['name'].value}}
            </strong>
            <div class="d-flex justify-content-between">
              <div>
                <strong *ngFor="let category of myPreviewCategories; let last = last">{{category}}<ng-container
                    *ngIf="!last || myPreviewSubcategories.length > 0"> /
                  </ng-container></strong>
                <strong *ngIf="myPreviewCategories.length == 0">Sin categoría</strong>
                <strong *ngFor="let subcategory of myPreviewSubcategories; let last = last">{{subcategory}}<ng-container
                    *ngIf="!last"> /
                  </ng-container></strong>
                <span>
                  |
                </span>
                <span class="vi-pin"></span>
                <span>
                  {{myPreviewLocation}}, {{experienceForm.controls['country'].value}}
                </span>
              </div>
              <div class="product-icons d-lg-block d-none">
                <span class="vi-heart mr-3"></span>
                <span class="vi-plane"></span>
              </div>
            </div>
          </div>
        </div>
        <div class="rounded product-images row mt-4 mb-lg-5 w-100 mx-0">
          <div class="h-lg-100 col-md-6 col-sm-12 pl-0 position-relative">
            <img class="h-100 img-fluid"
              onError="this.src='https://venntur.com/assets/image-not-available-placeholder.png'"
              [src]="'https://venntur.com' + (mediaExperiences.length > 0 ? mediaExperiences[0].url : '')"
              alt="Imagen de la empresa">
            <span class="position-absolute pink-button">{{experienceForm.controls['promotionType'].value}}</span>
            <span class="position-absolute pink-button"
              *ngIf="experienceForm.controls['promotionType'].value == null">Sin datos</span>
          </div>
          <div class="w-100 d-block d-md-none">
            <div class="d-flex justify-content-between align-items-center" style="margin-top: 21px;">
              <!-- (click)="openPhotosModal(photosContent,{class: 'photosModal'})" -->
              <button class="view-photos-mobile"><span class="vi-photos mr-2"></span><strong>Ver
                  fotos</strong></button>
              <div class="product-icons d-block d-lg-none">
                <span class="vi-heart mr-3"></span>
                <span class="vi-plane"></span>
              </div>
            </div>
            <div class="select-anchor">
              <select class="w-100" name="select">
                <!-- (change)="scrollToElement($event.target.value.toString()); activeTag = 'description'" -->
                <option value="section-1">Descripción</option>
                <option value="section-2" selected>Qué incluye</option>
                <option value="section-3" selected>Excluído</option>
                <option value="section-4">Qué debes
                  saber</option>
                <option value="section-5">Qué debes
                  llevar</option>
              </select>
            </div>
          </div>
          <div class="col-md-6 d-md-block d-none pr-0">
            <div class="row m-0 w-100">
              <div class="col-md-6 pl-0" style="margin-bottom: 4px;">
                <img class="img-fluid custom-image"
                  onError="this.src='https://venntur.com/assets/image-not-available-placeholder.png'"
                  [src]="'https://venntur.com' + (mediaExperiences.length > 1 ? mediaExperiences[1].url : '')"
                  alt="Imagen de la empresa">
              </div>
              <div class="col-md-6 pr-0">
                <img class="img-fluid custom-image"
                  onError="this.src='https://venntur.com/assets/image-not-available-placeholder.png'"
                  [src]="'https://venntur.com' + (mediaExperiences.length > 2 ? mediaExperiences[2].url : '')"
                  alt="Imagen de la empresa" style="border-radius: 0px 20px 0px 0px;">
              </div>
              <div class="col-md-6 pl-0">
                <img class="img-fluid custom-image"
                  onError="this.src='https://venntur.com/assets/image-not-available-placeholder.png'"
                  [src]="'https://venntur.com' + (mediaExperiences.length > 3 ? mediaExperiences[3].url : '')"
                  alt="Imagen de la empresa">
              </div>
              <div class="col-md-6 pr-0">
                <img class="img-fluid custom-image"
                  onError="this.src='https://venntur.com/assets/image-not-available-placeholder.png'"
                  [src]="'https://venntur.com' + (mediaExperiences.length > 4 ? mediaExperiences[4].url : '')"
                  alt="Imagen de la empresa" style="border-radius: 0px 0px 20px 0px;">
                <!-- (click)="openPhotosModal(photosContent,{class: 'photosModal'})" -->
                <button class="view-photos" *ngIf="mediaExperiences.length > 5"><span
                    class="vi-photos mr-2"></span><strong>Ver
                    fotos</strong></button>

              </div>
            </div>
          </div>
        </div>
        <div class="row pt-md-0">
          <div class="col-lg-8 col-12 col pt-3">
            <div class="product-details">
              <div class="product-anchors d-md-flex d-none">
                <a><strong>Descripción</strong></a>
                <a><strong>Qué incluye</strong></a>
                <a><strong>Excluído</strong></a>
                <a><strong>Qué debes
                    saber</strong></a>
                <a><strong>Qué debes
                    llevar</strong></a>
              </div>
              <div class="d-flex rounded p-3 product-details">
                <div class="pr-3">
                  <span class="vi-clock pr-1"></span>
                  <span>
                    {{convertTimeToCustomFormat(experienceForm.controls['duration'].value)}}
                  </span>
                  <span *ngIf="experienceForm.controls['duration'].value == null">
                    Sin datos
                  </span>
                </div>
                <div class="pr-3">
                  <span class="pr-2 d-none d-md-inline">|</span>
                  <span class="vi-calendar pr-1"></span>
                  <span>{{findTextByValue(weekDays,experienceForm.controls['weekDays'].value)}}</span>
                  <span *ngIf="experienceForm.controls['weekDays'].value.length == 0">
                    Sin datos
                  </span>
                </div>
                <div>
                  <span class="pr-2 d-none d-md-inline">|</span>
                  <span class="vi-bubble pr-1"></span>
                  <span *ngFor="let language of myPreviewLanguages; let last = last">{{language}}<ng-container
                      *ngIf="!last">,
                    </ng-container></span>
                  <span *ngIf="myPreviewLanguages == []">Sin datos</span>
                  <span [title]="" *ngIf="myPreviewLanguages.length > 4"> ...</span>
                </div>
              </div>
              <div id="section-1" class="includes" *ngIf="experienceForm.controls['descriptionExtended'].value != ''">
                <span [innerHTML]="experienceForm.controls['descriptionExtended'].value">
                </span>
                <span *ngIf="experienceForm.controls['descriptionExtended'].value == ''">
                  Sin datos
                </span>
              </div>
              <div class="row m-0 p-0">
                <div class="col-lg-6 col pl-0" *ngIf="experienceForm.controls['includes'].value != null">
                  <div id="section-2" class="title">
                    <span>
                      Qué incluye
                    </span>
                  </div>
                  <ul class="bullets-includes">
                    <li class="pb-3"
                      *ngFor="let includes of experienceForm.controls['includes'].value; let last = last">
                      {{includes}}
                    </li>
                    <li *ngIf="experienceForm.controls['includes'].value == null">
                      Sin datos
                    </li>
                  </ul>
                </div>
                <div class="col-lg-6 col-12 pl-0 pl-lg-3 pr-0"
                  *ngIf="experienceForm.controls['notIncludes'].value != null">
                  <div id="section-3" class="title">
                    <span>
                      Excluído
                    </span>
                  </div>
                  <ul class="bullets-not-includes">
                    <li class="pb-3"
                      *ngFor="let notIncludes of experienceForm.controls['notIncludes'].value; let last = last">
                      {{notIncludes}}
                    </li>
                    <li *ngIf="experienceForm.controls['notIncludes'].value == null">
                      Sin datos
                    </li>
                  </ul>
                </div>
              </div>
              <div id="section-4" class="py-4" *ngIf="experienceForm.controls['moreInfo'].value != null">
                <div class="title">
                  <span>
                    Qué debes saber
                  </span>
                </div>
                <span>
                  {{experienceForm.controls['whyUs'].value}}
                </span>
                <span *ngIf="experienceForm.controls['moreInfo'].value == null">
                  Sin datos
                </span>
              </div>
              <div id="section-5" class="py-4">
                <div class="title">
                  <span>
                    Qué debes llevar
                  </span>
                </div>
                <ul class="bullets-not-includes">
                  <li class="pb-3"
                    *ngFor="let carryInfo of experienceForm.controls['carryInfo'].value; let last = last">
                    {{carryInfo}}
                  </li>
                  <li *ngIf="experienceForm.controls['carryInfo'].value == null">
                    Sin datos
                  </li>
                </ul>
              </div>
              <!-- <div class="py-4">
                <div class="card rounded text-center p-3" style="min-height: 680px;">
                  <span>Widget Tripadvisor</span>
                </div>
              </div> -->
            </div>
          </div>
          <div class="col-lg-4 col-12 pt-lg-0 pt-4">
            <div class="booking-widget text-center">
              <div>
                <strong>
                  Solicitud de Reserva
                </strong>
              </div>
              <div class="card shadow rounded p-3" style="min-height: 680px;margin-bottom: 50px;">
                <div class="d-flex flex-column justify-content-center">
                  <form method="post">
                    <div class="w-auto">
                      <bs-datepicker-inline [bsConfig]="bsConfig"></bs-datepicker-inline>
                    </div>
                    <div class="px-4">
                      <div class="d-flex border-bottom border-top">
                        <div class="w-50 mr-2">
                          <div class="my-1 text-left border-right">
                            <label class="text-left" for="adults">Adultos</label>
                            <ng-select name="adults">
                              <ng-option *ngFor="let adultQty of [].constructor(experience.persons);let i = index"
                                [value]="i+1">{{i+1}}</ng-option>
                            </ng-select>
                          </div>

                        </div>
                        <div class="w-50 ml-2">
                          <div class="my-1 text-left">
                            <label class="text-left" for="kids">Niños</label>
                            <ng-select name="kids">
                              <ng-option *ngFor="let adultQty of [].constructor(experience.persons);let i = index"
                                [value]="i">{{i}}</ng-option>
                            </ng-select>
                          </div>

                        </div>
                      </div>
                      <div class="border-bottom d-flex flex-column pt-1">
                        <label class="text-left" for="name">Nombre</label>
                        <input type="text" name="name" placeholder="--">
                      </div>
                      <div class="border-bottom d-flex flex-column pt-1">
                        <label class="text-left" for="phone">Teléfono</label>
                        <input type="text" name="phone" placeholder="--">
                      </div>
                      <div class="border-bottom d-flex flex-column pt-1">
                        <label class="text-left" for="email">Mail</label>
                        <input type="text" name="email" placeholder="--">
                      </div>
                      <div class="border-bottom d-flex flex-column pt-1 mb-3">
                        <label class="text-left" for="comments">Comentarios</label>
                        <textarea type="textarea" name="comments" placeholder="--"></textarea>
                      </div>
                      <div class="d-flex data-protection mb-3">
                        <input type="checkbox" name="data-protection" class="mr-2">
                        <label for="data-protection" class="font-weight-normal">He leído y acepto la <a target="_blank"
                            href='https://pro.venntur.com/politica-de-privacidad/'>Política de
                            privacidad</a></label>
                      </div>
                      <button class="btn btn-primary w-100 mb-3">Reservar</button>
                    </div>
                  </form>

                </div>
              </div>
            </div>
            <div class="d-flex flex-column text-center mb-3">
              <div class="p-0" style="font-size: 18px;">
                <strong>
                  ¿Alguna duda?
                </strong>
              </div>
              <small>
                Estamos aquí para ayudarte
              </small>
            </div>
            <div class="maker-info card rounded">
              <div class="d-flex justify-content-between">
                <span>
                  Organizado por
                </span>
                <a>
                  Ver ficha de empresa
                </a>
              </div>
              <div class="d-flex align-items-center justify-content-between">
                <img class="pl-1" [src]="'https://venntur.com/assets/maker/files/' + myMakerLogo"
                  alt="Logo de la empresa+" height="40">
                <span>{{makerName}}</span>
              </div>
              <div class="maker-info-footer d-flex justify-content-between m-0 w-100">
                <a [href]="'https://test.com'" target="_blank" class=" w-100 h-100"><span class="vi-web"></span></a>
                <ng-template #tol1Template>Tooltip</ng-template>
                <span class="d-none d-lg-flex w-100 h-100 pointer"><span class="vi-mobile"></span></span>
                <a [href]="'tel:666999666'" class="d-flex d-lg-none w-100 h-100"><span class="vi-mobile"></span></a>

                <ng-template #tol2Template>Tooltip</ng-template>
                <span class="d-none d-lg-flex w-100 h-100 pointer"><span class="vi-email"></span></span>
                <a class="d-flex d-lg-none w-100 h-100"><span class="vi-email"></span></a>

                <a class="pointer d-none d-lg-flex w-100 h-100"><span class="vi-whatsapp"></span></a>
                <a class="d-flex d-lg-none w-100 h-100"><span class="vi-whatsapp"></span></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>