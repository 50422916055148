import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ExperiencesService } from '../../experiences/experiences/experiences.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.css']
})
export class RedirectComponent implements OnInit {

  name: string;
  makerLogo: string;
  slug: string;
  experience: any;

  constructor(private router: Router, private experienceService: ExperiencesService, private route: ActivatedRoute, public translate: TranslateService) { }

  ngOnInit(): void {
    this.slug = this.router['rawUrlTree'].root.children.primary.segments[2].path;

    this.experienceService.getExperienceBySlug(this.slug, this.translate.getDefaultLang()).subscribe(experience => {

      this.experience = experience['hydra:member'][0];

      this.name = this.experience.name;
      this.makerLogo = 'https://venntur.com/assets/maker/files/' + this.experience.maker.mediaMakers[0].contentUrl;

      setTimeout(() => {
        const params:any = new URL(this.experience.url);

        if (params.search) {
          window.location.href = !this.experience.civitatisId ? this.experience.url + '&utm_source=venntur' : this.experience.url + '&ag_aid=4732';
        } else {
          window.location.href = !this.experience.civitatisId ? this.experience.url + '?utm_source=venntur' : this.experience.url + '?ag_aid=4732';
        }
      }, 2500);
    });
  }
}
