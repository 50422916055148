import { Component, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/operators';

import { Experience } from '../experiences/experience.model';
import { ExperiencesService } from '../experiences/experiences.service';
import { TakerService } from '../../shared/taker.service';
import { Taker } from '../../taker/taker-profile/taker.model';
import { TranslateService } from '@ngx-translate/core';
import { ResellerService } from '../../shared/reseller.service';
import { AuthService } from '../../shared/auth.service';
import { ExperienceSearchComponent } from 'src/app/shared/experience-search/experience-search.component';

@Component({
  selector: 'app-experiences-page',
  styleUrls: ['./experiences-page.component.scss'],
  templateUrl: './experiences-page.component.html',
})

export class ExperiencesPageComponent implements OnInit {
  parentVariable: string;
  public expandedIndex = -1;
  public originalExperiences: Experience[];
  experiences = [];
  takerExperiences = [];
  resellerExperiences = [];
  shownDays = [
    { value: 0, text: 'Todos los días' },
    { value: 1, text: 'Lun' },
    { value: 2, text: 'Mar' },
    { value: 3, text: 'Mié' },
    { value: 4, text: 'Jue' },
    { value: 5, text: 'Vie' },
    { value: 6, text: 'Sáb' },
    { value: 7, text: 'Dom' },
  ];
  isAlertShown = false;
  throttle = 150;
  scrollDistance = 0.1;
  page = 1;
  total = 0;
  notEmptyExperience = true;
  notScrolly = true;
  loading = false;
  @ViewChild('autoShownModal', { static: false }) autoShownModal: ModalDirective;
  @Input() childData: string;

  constructor(
    private experienceService: ExperiencesService,
    private router: Router,
    private titleService: Title,
    private takerService: TakerService,
    private resellerService: ResellerService,
    public authService: AuthService,
    public translate: TranslateService,
    private meta: Meta,
    private renderer2: Renderer2
  ) {
  }

  ngOnInit(): void {
    this.removeMetaTags();
    this.loading = true;
    this.titleService.setTitle('Encuentra tu experiencia | Venntur');
    this.router['rawUrlTree'].queryParams['page'] = this.page;

    setTimeout(() => {
      this.experienceService.getExperiences(this.router['rawUrlTree'].queryParams, this.translate.getDefaultLang())
        .pipe(
          finalize(() => this.loading = false)
        )
        .subscribe((experiences => {
          this.total = experiences['hydra:totalItems'];
          this.experiences = experiences['hydra:member'];
        }));
    }, 1000);
    this.experienceService.getExperiencesByTakerId(this.takerService.getId()).subscribe(experiences => {
      this.takerExperiences = Array.from(experiences['hydra:member']).map((item) => item['@id']);
    });
    this.experienceService.getExperiencesByResellerId(this.resellerService.getId(), {}).subscribe(experiences => {
      this.resellerExperiences = Array.from(experiences['hydra:member']).map((item) => item['experience']['@id']);
    });
    this.meta.addTags([
      { name: 'description', content: 'test' },
      { name: 'lang', content: 'es' },
      { name: 'robots', content: 'index, follow' },
      { property: 'og:title', content: 'test' },
      { property: 'og:description', content: 'test' },
      { property: 'og:image', content: 'https://venntur.com/assets/venntur-isotype-70.png' },
      { property: 'og:url', content: 'https://venntur.com/' },
      { property: 'og:site_name', content: 'Venntur' }
    ]);
  }

  clickFavorite(event, experienceId) {
    if (this.takerService.getId()) {
      this.takerExperiences.includes(experienceId) ?
        this.takerExperiences.splice(this.takerExperiences.indexOf(experienceId), 1) : this.takerExperiences.push(experienceId);
      const taker = {
        favoriteExperiences: this.takerExperiences
      };
      const editedTaker = new Taker(taker);
      this.takerService.putTaker(this.takerService.getId(), editedTaker).subscribe();
    } else {
      this.isAlertShown = true;
    }
  }

  addFavoriteToReseller(event, experienceId) {
    this.resellerExperiences.includes(experienceId) ?
      this.resellerExperiences.splice(this.resellerExperiences.indexOf(experienceId), 1) : this.resellerExperiences.push(experienceId);
    const favoriteExperience = {
      reseller: '/resellers/' + this.resellerService.getId(),
      experience: experienceId,
      status: 'Pendiente contacto'
    };
    this.resellerService.postFavoriteExperience(favoriteExperience).subscribe();
  }

  removeFavoriteToReseller(favoriteExperienceId) {
    this.experienceService.getExperiencesByResellerId(this.resellerService.getId(), { id: favoriteExperienceId }).subscribe(experiences => {
      this.resellerService.deleteFavoriteExperience(experiences['hydra:member'][0]['@id']).subscribe(() => {
        this.resellerExperiences.splice(this.resellerExperiences.indexOf(favoriteExperienceId), 1);
      });
    });
  }

  expandText(index: number): void {
    this.expandedIndex = index === this.expandedIndex ? -1 : index;
  }

  onScroll() {
    this.loading = true;
    if (this.notScrolly && this.notEmptyExperience) {
      this.notScrolly = false;
      setTimeout(() => {
        this.loadNextExperience();
      }, 500);
    } else {
      this.loading = false;
    }
  }

  loadNextExperience() {
    // this.router['rawUrlTree'].queryParams['page'] = ++this.page;
    ++this.page;
    let updatedUrl;
    if (this.parentVariable) {
      updatedUrl = this.parentVariable.replace(/page=\d+/, `page=${this.page}`);
    } else if (this.router['rawUrlTree'].queryParams['localidad']) {
      this.router['rawUrlTree'].queryParams['page'] = this.page;
      updatedUrl = this.router['rawUrlTree'];
    } else {
      updatedUrl = '?page=' + this.page;
    }

    let params: any = (new URL('http://localhost:4200/' + updatedUrl)).searchParams;    

    if (this.experiences.length < this.total) {
      this.experienceService.getExperiencesByUrl(params, this.translate.getDefaultLang())
        .pipe(
          finalize(() => this.loading = false)
        )
        .subscribe((experiences => {
          this.total = experiences['hydra:totalItems'];
          this.experiences = this.experiences.concat(experiences['hydra:member']);
        }));
      // let i;
      // const length = this.experiences.length;
      // const experiencePerPage = 9;
      // const experienceToLoad = this.originalExperiences.length - length;

      // if ( experienceToLoad >= experiencePerPage ) {
      //   for (i = length; i <= length + experiencePerPage; i++) {
      //     this.experiences.push(this.originalExperiences[i]);
      //   }
      // } else {
      //   for (i = length; i <= length + experienceToLoad - 1; i++) {
      //     this.experiences.push(this.originalExperiences[i]);
      //   }
      // }
      this.notScrolly = true;
    } else {
      this.notEmptyExperience = false;
      this.loading = false;
    }
  }

  hideModal(): void {
    this.autoShownModal.hide();
  }

  onHidden(): void {
    this.isAlertShown = false;
  }

  changeParentVariable(newVariable: string) {
    this.parentVariable = newVariable;

    const params = (new URL('http://localhost:4200/' + this.parentVariable)).searchParams;

    this.experienceService.getExperiencesByUrl(params, this.translate.getDefaultLang())
      .pipe(
        finalize(() => this.loading = false)
      )
      .subscribe((experiences => {
        this.total = experiences['hydra:totalItems'];
        this.experiences = experiences['hydra:member'];
        this.page = 1;
      }));

  }

  getUrl(isCivitatis, image) {
    return !isCivitatis ? 'https://venntur.com/assets/experience/files/' + image : image;
  }

  setCategoryTitle(experience) {
    return experience.categories.map(obj => obj.name).join(' / ')
  }

  setLanguagesTitle(experience) {
    return experience.languages.map(obj => obj.name).join(' , ')
  }

  convertTimeToCustomFormat(inputTime: string): string {
    let formattedText;
    const [hours, minutes] = inputTime.split(':').map(part => parseInt(part, 10));

    if (minutes != 0) {
      formattedText = hours + ' h y ' + minutes + ' min'
    } else {
      formattedText = hours + ' h'
    }

    return formattedText;
  }

  shareExperience(experience) {
    var enlace = 'https://venntur.com/product/' + experience.slug;
    var mensaje = this.translate.instant('¡Mira esta experiencia en Venntur!');

    mensaje = encodeURIComponent(mensaje);
    enlace = encodeURIComponent(enlace);

    var enlaceWhatsApp = 'https://wa.me/?text=' + mensaje + '%20' + enlace;

    window.open(enlaceWhatsApp);

  }

  removeMetaTags() {
    const metaTags = Array.from(document.querySelectorAll('meta'));

    metaTags.forEach((metaTag: Element) => {
      this.renderer2.removeChild(document.head, metaTag);
    });
  }

}
