import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ExperiencesService } from '../experiences/experiences/experiences.service';
import { MakerService } from '../shared/maker.service';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { ActivatedRoute } from '@angular/router';
import { maxTableRows } from 'globals';

@Component({
  selector: 'app-product-reviews-list',
  templateUrl: './product-reviews-list.component.html',
  styleUrls: ['./product-reviews-list.component.scss']
})
export class ProductReviewsListComponent implements OnInit {
  maxTableRows = maxTableRows;
  title = 'Reviews de ';
  experienceName = '';
  productId = this.activatedRoute.snapshot.params.id

  searchForm: UntypedFormGroup;
  @ViewChild(DatatableComponent) table: DatatableComponent;

  columns = [];
  rows = [];

  maker: any = [];

  constructor(
    public translate: TranslateService,
    private searchFormBuilder: UntypedFormBuilder,
    private experienceService: ExperiencesService,
    private makerService: MakerService,
    private activatedRoute: ActivatedRoute,
  ) {

    this.getReviewsByExperienceId();
    this.getExperienceName();

    this.makerService.getMaker('/makers/' + makerService.getId()).subscribe((maker => {
      this.maker = maker;
    }));

    this.columns = [
      { prop: 'comments', name: 'Comentario' },
      { prop: 'channel', name: 'Canal' },
      { prop: 'name', name: 'Nombre' },
      { prop: 'reviewerLocation', name: 'Localidad' },
    ];
  }

  ngOnInit(): void {
    this.searchForm = this.searchFormBuilder.group({
      comments: [''],
    });
  }

  getExperienceName() {
    this.experienceService.getExperience(this.productId).subscribe((resp => {
      this.experienceName = resp.name;
    }));
  }

  getReviewsByExperienceId() {
    this.experienceService.getReviewsByExperienceId(this.productId).subscribe((reviews => {
      this.rows = reviews['hydra:member'];
    }));
  }


  applyFilter() {
    this.experienceService.getReviewsByExperienceId(this.productId, this.searchForm.value).subscribe(
      data => {
        this.rows = data['hydra:member'];
        this.table.offset = 0;
      }
    );
  }

  setReviewStatus(id, status) {
    this.experienceService.putReviewStatus(id, status).subscribe((maker => {
      this.getReviewsByExperienceId()
    }));
  }

  formatDateString(inputDateString: string): string {
    const date = new Date(inputDateString);

    if (isNaN(date.getTime())) {
      return "Invalid Date";
    }

    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();

    const formattedDateString = `${day}/${month}/${year}`;

    return formattedDateString;
  }

}
