import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../shared/auth.service';
import {TranslateService} from '@ngx-translate/core';
import {FacebookLoginProvider, GoogleLoginProvider, SocialAuthService, SocialUser} from 'angularx-social-login';
import {environment} from '../../../environments/environment';

const FRONT_URL = environment.frontUrl;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  @Input() loginData: any;
  loginForm: UntypedFormGroup;
  alert = {message: null, type: null};
  constructor(private authService: AuthService,
              private socialAuthService: SocialAuthService,
              private fb: UntypedFormBuilder,
              private router: Router,
              public translate: TranslateService) {
    this.loginForm = fb.group({
      email: ['',
        [Validators.required, Validators.email]
      ],
      password: ['',
        Validators.required
      ]
    });
  }

  ngOnInit() {
    if (this.loginData.email) {
      this.loginForm.controls['email'].setValue(this.loginData.email);
      this.loginForm.controls['password'].setValue(atob(this.loginData.password));
    }

   }

  login() {
    const loginModel = this.loginForm.value;
    this.authService.login(loginModel.email, loginModel.password)
      .then(() => {
            this.alert.type = 1;
            this.alert.message = 'Login correcto';
            setTimeout(() => {
              this.alert = {message: null, type: null};
              if (this.authService.hasMakerRole()) {
                window.location.replace(FRONT_URL + this.translate.getDefaultLang() + '/maker/admin/perfil/');
              } else if (this.authService.hasTakerRole()) {
                window.location.replace(FRONT_URL + this.translate.getDefaultLang() + '/taker/admin/perfil/');
              } else if (this.authService.hasResellerRole()) {
                window.location.replace(FRONT_URL + this.translate.getDefaultLang() + '/reseller/admin/perfil/');
              }
            }, 1000);
      })
      .catch(error => {
        this.alert.message = error.error.code + '- ' + error.error.message;
        this.alert.type = 2;
        setTimeout(() => {
          this.alert = {message: null, type: null};
      }, 5000); });
  }

  loginWithFB() {
    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID).then((user: SocialUser) => {
      this.authService.loginByFacebook(user, 'ROLE_MAKER')
        .then(
          (response) => {
            setTimeout(() => {
              this.alert = {message: null, type: null};
              if (this.authService.hasMakerRole()) {
                window.location.replace(FRONT_URL + this.translate.getDefaultLang() + '/maker/admin/perfil/');
              } else if (this.authService.hasTakerRole()) {
                window.location.replace(FRONT_URL + this.translate.getDefaultLang() + '/taker/admin/perfil/');
              } else if (this.authService.hasResellerRole()) {
                window.location.replace(FRONT_URL + this.translate.getDefaultLang() + '/reseller/admin/perfil/');
              }
              // window.location.replace(FRONT_URL + this.translate.getDefaultLang() + '/maker/admin/perfil/');
              // this.router.navigateByUrl(this.translate.getDefaultLang() + '/maker/admin/perfil/');
            }, 1000);
          },
          (error) => {
            this.alert.type = 2;
            this.alert.message = 'Ocurrió un error al intentar hacer login con Facebook, por favor vuelve a intentarlo';
          });
    });
  }

  loginWithGoogle() {
    this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then((user: SocialUser) => {
      console.log(user);
      this.authService.loginByGoogle(user.idToken, 'ROLE_MAKER')
        .then(
          (response) => {
            // window.location.reload();
            setTimeout(() => {
              this.alert = {message: null, type: null};
              if (this.authService.hasMakerRole()) {
                window.location.replace(FRONT_URL + this.translate.getDefaultLang() + '/maker/admin/perfil/');
              } else if (this.authService.hasTakerRole()) {
                window.location.replace(FRONT_URL + this.translate.getDefaultLang() + '/taker/admin/perfil/');
              } else if (this.authService.hasResellerRole()) {
                window.location.replace(FRONT_URL + this.translate.getDefaultLang() + '/reseller/admin/perfil/');
              }
            }, 1000);
            // this.router.navigateByUrl(this.translate.getDefaultLang() + '/maker/admin/perfil/');
          },
          (error) => {
            this.alert.type = 2;
            this.alert.message = 'Ocurrió un error al intentar hacer login con Google, por favor vuelve a intentarlo';
          });
    });
  }
}
