<header id="navbar" class="navbar navbar-expand-lg"
  [ngClass]="isAdmin && authService.isReallyLoggedIn() ? 'navbar--admin' : ''">
  <div class="d-flex justify-content-between w-100"
    [ngClass]="{'container py-1' : !isAdmin}">
    <div [ngClass]="{'d-none': isOpen}" class="col-6 navbar-brand-wrapper" >
      <a class="navbar-brand brand-logo" [routerLink]="'/' + translate.getDefaultLang()"><img
          src="assets/venntur-logo-175.png" alt="Venntur" class="w-100" /></a>
      <!-- <a class="navbar-brand brand-logo" [href]="'/' + translate.getDefaultLang()"><img src="assets/venntur-logo-150.png" alt="Venntur" /></a> -->
      <ng-container *ngIf="authService.isMaker() && isAdmin || authService.isTaker() && isAdmin">
        <!-- <a class="navbar-brand brand-logo-mini" routerLink="/"><img src="assets/venntur-isotype-38.png" alt="Venntur"/></a> -->
        <!-- <button class="btn btn-light p-1" type="button" data-toggle="minimize" (click)="toggleSidebar()">
                <i class="fa fa-bars"></i>
              </button> -->
      </ng-container>
    </div>
    <div class="col-5 d-none d-sm-block">
      <nav class="navbar-menu-wrapper ml-auto small align-self-center">
        <ul class="navbar-nav nav-pills navbar-nav-right ml-auto d-flex flex-row text-nowrap align-items-center float-right">
          <li class="nav-item" *ngIf="!authService.isReallyLoggedIn()">
            <a href="https://pro.venntur.com" target="_blank" class="nav-link text-dark font-weight-bold">{{ 'Publica tus experiencias' | translate}}</a>
          </li>
          <li class="nav-item" *ngIf="!authService.isReallyLoggedIn()">
            <span class="nav-link">|</span>
          </li>
          <li class="nav-item" *ngIf="!authService.isReallyLoggedIn()">
            <span class="d-flex align-items-center">
              <a class="nav-link nav-link__user-login" (click)="openLoginModal(loginModalContent)" tooltip="Acceso"
                placement="bottom">
                <span class="vi-user"></span>
              </a>
            </span>
          </li>
          <li class="nav-item" *ngIf="!authService.isReallyLoggedIn()">
            <span class="nav-link">|</span>
          </li>
          <li class="nav-item nav-profile dropdown" dropdown placement="bottom right">
            <div *ngIf="authService.isAuthenticated() | async">
              <a href id="profileDropdown" class="nav-link dropdown-toggle" dropdownToggle (click)="false"
                data-toggle="dropdown" aria-expanded="false">
                <span class="nav-profile d-none d-md-inline">
                  <span class="vi-user"></span> <span class="ml-1">{{makerService.getName() || takerService.getName()
                    ||
                    resellerService.getName()}}</span>&nbsp;
                </span>
                <span class="nav-profile d-md-none">
                  <span class="vi-user"></span> <span class="ml-1">{{getFirstWord(makerService.getName()) ||
                    getFirstWord(takerService.getName()) ||
                    getFirstWord(resellerService.getName())}}</span>&nbsp;
                </span>
              </a>
              <div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="profileDropdown">
                <!--              <ng-container *ngIf="authService.hasMakerRole() || authService.hasTakerRole()">-->
                <!--              <a class="dropdown-item" [href]="authService.hasMakerRole() ? translate.getDefaultLang() + '/maker/admin/experiencias' : translate.getDefaultLang() + '/taker/admin/favoritos'">-->
                <!--                <ng-container *ngIf="authService.hasMakerRole(); else favorites"><i-feather name="key" class="mr-1"></i-feather> {{ 'Área privada' | translate}}</ng-container>-->
                <!--                <ng-template #favorites><i-feather name="heart" class="mr-1"></i-feather> </ng-template>favoritos-->
                <a class="dropdown-item" [href]="translate.getDefaultLang() + '/maker/admin/dashboard'"
                  *ngIf="authService.hasMakerRole()">
                  {{ 'Dashboard' | translate}}
                </a>
                <a class="dropdown-item" [href]="translate.getDefaultLang() + '/maker/admin/experiencias'"
                  *ngIf="authService.hasMakerRole()">
                  {{ 'Experiencias' | translate}}
                </a>
                <!-- <a class="dropdown-item" [href]="translate.getDefaultLang() + '/maker/admin/cms'"
                        *ngIf="authService.hasMakerRole()">
                        {{ 'CMS' | translate}}
                      </a> -->
                <a class="dropdown-item" [href]="translate.getDefaultLang() + '/maker/admin/reviews'"
                  *ngIf="authService.hasMakerRole()">
                  {{ 'Reviews' | translate}}
                </a>
                <!-- <a class="dropdown-item" [href]="translate.getDefaultLang() + '/maker/admin/ventas'"
                        *ngIf="authService.hasMakerRole()">
                        {{ 'Stop Sales' | translate}}
                      </a> -->
                <!-- <a class="dropdown-item" [href]="translate.getDefaultLang() + '/maker/admin/analiticas'"
                        *ngIf="authService.hasMakerRole()">
                        {{ 'Analíticas' | translate}}
                      </a> -->
                <!-- <a class="dropdown-item" [href]="translate.getDefaultLang() + '/maker/admin/contactos'"
                        *ngIf="authService.hasMakerRole()">
                        {{ 'Contactos' | translate}}
                      </a> -->
                <a class="dropdown-item" [href]="translate.getDefaultLang() + '/maker/admin/perfil'"
                  *ngIf="authService.hasMakerRole() && (authService.hasTakerRole() || authService.hasResellerRole())">
                  {{ 'Perfil Maker' | translate}}
                </a>
                <div class="dropdown-divider" *ngIf="authService.hasMakerRole() && authService.hasTakerRole()"></div>
                <a class="dropdown-item" [href]="translate.getDefaultLang() + '/taker/admin/favoritos'"
                  *ngIf="authService.hasTakerRole()">
                  {{ 'Favoritos' | translate}}
                </a>
                <a class="dropdown-item" [href]="translate.getDefaultLang() + '/taker/admin/perfil'"
                  *ngIf="authService.hasTakerRole() && (authService.hasMakerRole() || authService.hasResellerRole())">
                  {{ 'Perfil Taker' | translate}}
                </a>
                <div class="dropdown-divider"
                  *ngIf="authService.hasResellerRole() && (authService.hasMakerRole() || authService.hasTakerRole())">
                </div>
                <a class="dropdown-item" [href]="translate.getDefaultLang() + '/reseller/admin/mis-productos'"
                  *ngIf="authService.hasResellerRole()">
                  {{ 'Mis productos' | translate}}
                </a>
                <a class="dropdown-item" [href]="translate.getDefaultLang() + '/reseller/admin/perfil'"
                  *ngIf="((authService.hasResellerRole() && authService.hasMakerRole()) || (authService.hasResellerRole() && authService.hasTakerRole()))">
                  {{ 'Perfil Reseller' | translate}}
                </a>
                <a class="dropdown-item" [href]="translate.getDefaultLang() + '/maker/admin/perfil'"
                  *ngIf="authService.hasMakerRole() && !authService.hasResellerRole() && !authService.hasTakerRole()">
                  {{ 'Mi perfil' | translate}}
                </a>
                <a class="dropdown-item" [href]="translate.getDefaultLang() + '/taker/admin/perfil'"
                  *ngIf="authService.hasTakerRole() && !authService.hasResellerRole() && !authService.hasMakerRole()">
                  {{ 'Mi perfil' | translate}}
                </a>
                <a class="dropdown-item" [href]="translate.getDefaultLang() + '/reseller/admin/perfil'"
                  *ngIf="authService.hasResellerRole() && !authService.hasMakerRole() && !authService.hasTakerRole()">
                  {{ 'Mi perfil' | translate}}
                </a>
                <a class="dropdown-item" [href]="translate.getDefaultLang() + '/maker/admin/perfil'"
                  *ngIf="!authService.hasMakerRole()">
                  {{ '¿Quieres ser Maker?' | translate}}
                </a>
                <!--  <a  class="dropdown-item" [href]="translate.getDefaultLang() + '/taker/admin/perfil'" *ngIf="!authService.hasTakerRole()">
                          {{ '¿Quieres ser Taker?' | translate}}
                        </a>
                        <a  class="dropdown-item" [href]="translate.getDefaultLang() + '/reseller/admin/perfil'" *ngIf="!authService.hasResellerRole()">
                          {{ '¿Quieres ser Reseller?' | translate}}
                        </a> -->
                <!-- <div class="dropdown-divider"></div>
                        <a  class="dropdown-item" [href]="authService.hasMakerRole() ? translate.getDefaultLang() + '/maker/admin/datos-acceso' : authService.hasTakerRole() ? translate.getDefaultLang() + '/taker/admin/datos-acceso' : translate.getDefaultLang() + '/reseller/admin/datos-acceso'">
                          {{ 'Datos de acceso' | translate}}
                        </a> -->
                <!--   style="border-color:rgb(186, 186, 186);"  -->
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" (click)="logout()">
                  {{ 'Cerrar sesión' | translate}}
                </a>
                <!--              </ng-container>-->
              </div>
            </div>
            <!-- <div *ngIf="!authService.isReallyLoggedIn()">
                    <button class="dropdown-item" (click)="login()">
                      <i class="fa fa-power-off text-primary"></i>Login
                    </button>
                  </div> -->
          </li>
          <li class="nav-item" *ngIf="authService.isReallyLoggedIn()">
            <span class="nav-link">|</span>
          </li>
          <li class="nav-item">
            <a href="https://venntur.com/blog/" target="_blank" class="nav-link text-dark font-weight-bold">NEWS</a>
          </li>
          <li class="nav-item">
            <span class="nav-link">|</span>
          </li>
          <li class="nav-item">
            <span class="pl-0 nav-link">
              <select (change)="changeLanguage($event)"
                style="background: transparent; border: none; width: 60px; font-weight: bold;" name="" id="">
                <option [selected]="activeLang == 'en'" style="text-align: center;">EN</option>
                <option [selected]="activeLang == 'es'" style="text-align: center;">ES</option>
              </select>
            </span>
          </li>
        </ul>
      </nav>
    </div>
    <div class="d-sm-none">
      <button class="burguer-button" (click)="openBurguerMenu()">
        <span *ngIf="!isOpen" class="vi-burger"></span>
        <span *ngIf="isOpen" class="vi-close"></span>
      </button>
    </div>
  </div>
</header>

<div id="open-close-container" [@openClose]="isOpen ? 'open' : 'closed'" class="d-sm-none open-close-container">
  <div class="open-close-container-content">
    <!-- <span>The box is now {{ isOpen ? 'Open' : 'Closed' }}!</span> -->
    <div class="white-logo">
      <img src="assets/logo-venntur-white.png" alt="Logo">
    </div>
    <nav class="mobile-menu">
      <ul>
        <li class="nav-item" *ngIf="!authService.isReallyLoggedIn()">
          <a href="https://pro.venntur.com" target="_blank" class="nav-link font-weight-bold">{{ 'Publica tus experiencias' | translate}}</a>
        </li>
        <li class="nav-item" *ngIf="!authService.isReallyLoggedIn()">
          <span class="d-flex align-items-center">
            <a class="nav-link font-weight-bold nav-link__user-login" (click)="openLoginModal(loginModalContent)" tooltip="Acceso"
              placement="bottom">
              <span>Log In</span>
            </a>
          </span>
        </li>
        <li *ngIf="!authService.isReallyLoggedIn()" class="nav-item">
          <a href="https://venntur.com/blog/" target="_blank" class="nav-link font-weight-bold">NEWS</a>
        </li>
        <li class="nav-item nav-profile dropdown" dropdown placement="bottom right">
          <div *ngIf="authService.isAuthenticated() | async">
            <!-- <div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="profileDropdown"> -->
              <!--              <ng-container *ngIf="authService.hasMakerRole() || authService.hasTakerRole()">-->
              <!--              <a class="dropdown-item" [href]="authService.hasMakerRole() ? translate.getDefaultLang() + '/maker/admin/experiencias' : translate.getDefaultLang() + '/taker/admin/favoritos'">-->
              <!--                <ng-container *ngIf="authService.hasMakerRole(); else favorites"><i-feather name="key" class="mr-1"></i-feather> {{ 'Área privada' | translate}}</ng-container>-->
              <!--                <ng-template #favorites><i-feather name="heart" class="mr-1"></i-feather> </ng-template>favoritos-->
              <li *ngIf="authService.hasMakerRole()" class="nav-item nav-link font-weight-bold">
                <a [href]="translate.getDefaultLang() + '/maker/admin/dashboard'">
                  {{ 'Dashboard' | translate}}
                </a>
              </li>
              <li *ngIf="authService.hasMakerRole()" class="nav-item nav-link font-weight-bold">
                <a [href]="translate.getDefaultLang() + '/maker/admin/experiencias'">
                  {{ 'Experiencias' | translate}}
                </a>
              </li>
              <li *ngIf="authService.hasMakerRole()" class="nav-item nav-link font-weight-bold">
                <a [href]="translate.getDefaultLang() + '/maker/admin/reviews'">
                  {{ 'Reviews' | translate}}
                </a>
              </li>
              <!-- <a class="dropdown-item" [href]="translate.getDefaultLang() + '/maker/admin/cms'"
                        *ngIf="authService.hasMakerRole()">
                        {{ 'CMS' | translate}}
                      </a> -->
              <!-- <a class="dropdown-item" [href]="translate.getDefaultLang() + '/maker/admin/ventas'"
                        *ngIf="authService.hasMakerRole()">
                        {{ 'Stop Sales' | translate}}
                      </a> -->
              <!-- <a class="dropdown-item" [href]="translate.getDefaultLang() + '/maker/admin/analiticas'"
                        *ngIf="authService.hasMakerRole()">
                        {{ 'Analíticas' | translate}}
                      </a> -->
              <!-- <a class="dropdown-item" [href]="translate.getDefaultLang() + '/maker/admin/contactos'"
                        *ngIf="authService.hasMakerRole()">
                        {{ 'Contactos' | translate}}
                      </a> -->
              <li *ngIf="authService.hasMakerRole() && (authService.hasTakerRole() || authService.hasResellerRole())" class="nav-item nav-link font-weight-bold">
                <a [href]="translate.getDefaultLang() + '/maker/admin/perfil'">
                  {{ 'Perfil Maker' | translate}}
                </a>
              </li>
              <div class="dropdown-divider" *ngIf="authService.hasMakerRole() && authService.hasTakerRole()"></div>
              <li *ngIf="authService.hasTakerRole()" class="nav-item nav-link font-weight-bold">
                <a [href]="translate.getDefaultLang() + '/taker/admin/favoritos'">
                  {{ 'Favoritos' | translate}}
                </a>
              </li>
              <li *ngIf="authService.hasTakerRole() && (authService.hasMakerRole() || authService.hasResellerRole())" class="nav-item nav-link font-weight-bold">
                <a [href]="translate.getDefaultLang() + '/taker/admin/perfil'">
                  {{ 'Perfil Taker' | translate}}
                </a>
              </li>
              <div class="dropdown-divider"
                *ngIf="authService.hasResellerRole() && (authService.hasMakerRole() || authService.hasTakerRole())">
              </div>
              <li *ngIf="authService.hasResellerRole()" class="nav-item nav-link font-weight-bold">
                <a class="dropdown-item" [href]="translate.getDefaultLang() + '/reseller/admin/mis-productos'">
                  {{ 'Mis productos' | translate}}
                </a>
              </li>
              <li *ngIf="((authService.hasResellerRole() && authService.hasMakerRole()) || (authService.hasResellerRole() && authService.hasTakerRole()))" class="nav-item nav-link font-weight-bold">
                <a [href]="translate.getDefaultLang() + '/reseller/admin/perfil'">
                  {{ 'Perfil Reseller' | translate}}
                </a>
              </li>
              <li *ngIf="authService.hasMakerRole() && !authService.hasResellerRole() && !authService.hasTakerRole()" class="nav-item nav-link font-weight-bold">
                <a [href]="translate.getDefaultLang() + '/maker/admin/perfil'">
                  {{ 'Mi perfil' | translate}}
                </a>
              </li>
              <li *ngIf="authService.hasTakerRole() && !authService.hasResellerRole() && !authService.hasMakerRole()" class="nav-item nav-link font-weight-bold">
                <a [href]="translate.getDefaultLang() + '/taker/admin/perfil'">
                  {{ 'Mi perfil' | translate}}
                </a>
              </li>
              <li *ngIf="authService.hasResellerRole() && !authService.hasMakerRole() && !authService.hasTakerRole()" class="nav-item nav-link font-weight-bold">
                <a [href]="translate.getDefaultLang() + '/reseller/admin/perfil'">
                  {{ 'Mi perfil' | translate}}
                </a>
              </li>
              <li *ngIf="!authService.hasMakerRole()" class="nav-item nav-link font-weight-bold">
                <a [href]="translate.getDefaultLang() + '/maker/admin/perfil'">
                  {{ '¿Quieres ser Maker?' | translate}}
                </a>
              </li>
              <li *ngIf="authService.hasMakerRole() || authService.hasTakerRole() || authService.hasResellerRole()" class="nav-item">
                <a href="https://venntur.com/blog/" target="_blank" class="nav-link font-weight-bold">NEWS</a>
              </li>
              <!--  <a  class="dropdown-item" [href]="translate.getDefaultLang() + '/taker/admin/perfil'" *ngIf="!authService.hasTakerRole()">
                          {{ '¿Quieres ser Taker?' | translate}}
                        </a>
                        <a  class="dropdown-item" [href]="translate.getDefaultLang() + '/reseller/admin/perfil'" *ngIf="!authService.hasResellerRole()">
                          {{ '¿Quieres ser Reseller?' | translate}}
                        </a> -->
              <!-- <div class="dropdown-divider"></div>
                        <a  class="dropdown-item" [href]="authService.hasMakerRole() ? translate.getDefaultLang() + '/maker/admin/datos-acceso' : authService.hasTakerRole() ? translate.getDefaultLang() + '/taker/admin/datos-acceso' : translate.getDefaultLang() + '/reseller/admin/datos-acceso'">
                          {{ 'Datos de acceso' | translate}}
                        </a> -->
              <!--   style="border-color:rgb(186, 186, 186);"  -->
              <div class="dropdown-divider"></div>
              <li class="nav-item nav-link font-weight-bold">
                <a (click)="logout()">
                  {{ 'Cerrar sesión' | translate}}
                </a>
              </li>
              <!--              </ng-container>-->
            <!-- </div> -->
          </div>
          <!-- <div *ngIf="!authService.isReallyLoggedIn()">
                    <button class="dropdown-item" (click)="login()">
                      <i class="fa fa-power-off text-primary"></i>Login
                    </button>
                  </div> -->
        </li>
        <li class="nav-item nav-link font-weight-bold">
          <a style="cursor: pointer;" (click)="changeMobileLanguage($event)">EN</a>
          <span class="px-2">|</span>
          <a style="cursor: pointer;" (click)="changeMobileLanguage($event)">ES</a>
        </li>
      </ul>
    </nav>
  </div>
</div>

<ng-template #loginModalContent>
  <button type="button" class="btn btn-lg btn-white btn-close pull-right ml-auto mt-3 mr-3 p-1" aria-label="Close"
    (click)="modalRef.hide()">
    <span class="vi-close"></span>
  </button>
  <div class="modal-body mx-auto">
    <div class="h4 text-center">{{ '¿Ya tienes cuenta?' | translate}}</div>
    <p class="mb-4 text-center">{{ 'Accede para ir a tu panel de usuario' | translate}}</p>
    <app-login [loginData]="loginData"></app-login>
  </div>
</ng-template>
