import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule, Title } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AccordionModule } from 'ngx-bootstrap/accordion';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { ExperiencesPageComponent } from './pages/experiences/experiences-page/experiences-page.component';
import { HomeComponent } from './pages/home/home.component';
import { IconsModule } from './shared/icons/icons.module';
import { NavbarComponent } from './core/navbar/navbar.component';
import { SidebarComponent } from './core/sidebar/sidebar.component';
import { ExperiencesListComponent } from './pages/experiences/experiences-list/experiences-list.component';
import { ExperiencesComponent } from './pages/experiences/experiences/experiences.component';
import { ExperiencesService } from './pages/experiences/experiences/experiences.service';
import { CategoriesService } from './shared/categories/categories.service';
import { TagsService } from './shared/tags/tags.service';
import { LanguagesService } from './shared/languages/languages.service';
import { LocationService } from './shared/locations/location.service';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { MediaExperiencesService } from './pages/experiences/experiences/media-experiences.service';
import { CountryService } from './shared/locations/country.service';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { RegisterMakerComponent } from './pages/register/register-maker/register-maker.component';
import { AuthService } from './pages/shared/auth.service';
import { AuthGuard } from './pages/shared/auth-guard.service';
import { LoggedInGuard } from './pages/shared/logged-in-guard.service';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MakerService } from './pages/shared/maker.service';
import { ApiAuthInterceptor } from './app.api-auth-interceptor';
import { MakerAdminComponent } from './pages/maker/maker-admin.component';
import { MakerComponent } from './pages/maker/maker-profile/maker.component';
import { MediaMakerService } from './pages/maker/maker-profile/media-maker';
import { ExperienceSearchComponent } from './shared/experience-search/experience-search.component';
import { FooterComponent } from './core/footer/footer.component';
import { MakerCtaComponent } from './shared/maker-cta/maker-cta.component';
import { CropperComponent } from './pages/shared/cropper/cropper.component';
import { AngularCropperjsModule } from 'angular-cropperjs';
import { UsersService } from './shared/users/users.service';
import { UsersComponent } from './shared/users/users.component';
import { PasswordRecoverComponent } from './pages/users/password-recover/password-recover.component';
import { TakerService } from './pages/shared/taker.service';
import { TakerAdminComponent } from './pages/taker/taker-admin.component';
import { TakerComponent } from './pages/taker/taker-profile/taker.component';
import { ConfirmationDialogComponent } from './pages/shared/confirmation-dialog/confirmation-dialog.component';
import { ResetPasswordComponent } from './pages/users/reset-password/reset-password.component';
import { TakerFavoritesComponent } from './pages/taker/taker-favorites/taker-favorites.component';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthServiceConfig, SocialLoginModule } from 'angularx-social-login';
import { environment } from '../environments/environment';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppPasswordDirective } from './app-password.directive';
import { WordpressPageComponent } from './pages/wordpress-page/wordpress-page.component';
import { MakerPageComponent } from './pages/maker/maker-list/maker-page.component';
import { Cropper2Component } from './pages/shared/cropper/cropper2.component';
import { Cropper3Component } from './pages/shared/cropper/cropper3.component';
import { Cropper4Component } from './pages/shared/cropper/cropper4.component';
import { FullWidthCropperComponent } from './pages/shared/cropper/full-width-cropper.component';
import { MakerPinComponent } from './pages/maker/maker-pin/maker-pin.component';
import { MakerContactsListComponent } from './pages/maker/maker-contacts/maker-contacts-list/maker-contacts-list.component';
import { MakerContactsComponent } from './pages/maker/maker-contacts/maker-contacts/maker-contacts.component';
import { SecondaryMenuComponent } from './core/secondary-menu/secondary-menu.component';
import { ContactFormComponent } from './pages/users/contact-form/contact-form.component';
import { ResellerAdminComponent } from './pages/reseller/reseller-admin.component';
import { ResellerComponent } from './pages/reseller/reseller-profile/reseller.component';
import { ResellerService } from './pages/shared/reseller.service';
import { MediaResellerService } from './pages/reseller/reseller-profile/media-reseller';
import { ResellerContactsListComponent } from './pages/reseller/reseller-contacts/reseller-contacts-list/reseller-contacts-list.component';
import { ResellerContactsComponent } from './pages/reseller/reseller-contacts/reseller-contacts/reseller-contacts.component';
import { RegisterResellerComponent } from './pages/register/register-reseller/register-reseller.component';
import { ResellerExperiencesListComponent } from './pages/reseller/reseller-experiences-list/reseller-experiences-list.component';
import { ResellerMakersListComponent } from './pages/reseller/reseller-makers-list/reseller-makers-list.component';
import { ResellerCountriesListComponent } from './pages/reseller/reseller-countries-list/reseller-countries-list.component';
import { ResellerLocationsListComponent } from './pages/reseller/reseller-locations-list/reseller-locations-list.component';
import { MakerResellersListComponent } from './pages/maker/maker-resellers-list/maker-resellers-list.component';
import { RedirectComponent } from './pages/shared/redirect/redirect.component';
import { MakerGroupComponent } from './pages/maker/maker-contacts/maker-group/maker-group.component';
import { SalesListComponent } from './pages/sales/sales-list/sales-list.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { esLocale } from 'ngx-bootstrap/locale';
defineLocale('es', esLocale);
import { SalesService } from './pages/sales/sales.service';
import { EditorModule } from '@tinymce/tinymce-angular';
import { SalesHistoryComponent } from './pages/sales/sales-history/sales-history.component';
import { AnalyticsService } from './pages/analytics/analytics.service';
import { AnalyticsComponent } from './pages/analytics/analytics.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { QRCodeModule } from 'angular2-qrcode';
import { ProductPageComponent } from './pages/product/product-page/product-page.component';
import { ProductPinPageComponent } from './pages/product/product-pin-page/product-pin-page.component';
import { ExperienceCardComponent } from './shared/experience-card/experience-card.component';
import { ReviewsComponent } from './shared/reviews/reviews.component';
import { ReviewFormComponent } from './shared/review-form/review-form.component';
import { ReviewsPageComponent } from './pages/reviews/reviews-page.component';
import { ProductReviewsListComponent } from './pages/product-reviews-list/product-reviews-list.component';
import { ModalAlertComponent } from './shared/modal-alert/modal-alert.component';
import { MakerDirectories } from './pages/maker/maker-directories/maker-directories.component';
import { MakerSearch } from './shared/maker-search/maker-search.component';
import { MakerCardComponent } from './shared/maker-card/maker-card.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { CmsComponent } from './pages/cms/cms.component';
import { CmsModuleComponent } from './pages/cms/cms-module/cms-module.component';
import { CmsFieldComponent } from './pages/cms/cms-field/cms-field.component';

@NgModule({
  bootstrap: [AppComponent],
  declarations: [
    AppComponent,
    ExperiencesPageComponent,
    HomeComponent,
    NavbarComponent,
    SidebarComponent,
    ExperiencesListComponent,
    ExperiencesComponent,
    ExperienceSearchComponent,
    LoginComponent,
    RegisterComponent,
    RegisterMakerComponent,
    RegisterResellerComponent,
    MakerAdminComponent,
    MakerComponent,
    MakerPageComponent,
    MakerPinComponent,
    MakerContactsListComponent,
    MakerContactsComponent,
    TakerAdminComponent,
    TakerComponent,
    TakerFavoritesComponent,
    ResellerAdminComponent,
    ResellerComponent,
    ResellerContactsListComponent,
    ResellerContactsComponent,
    ResellerMakersListComponent,
    ResellerExperiencesListComponent,
    ResellerCountriesListComponent,
    ResellerLocationsListComponent,
    MakerResellersListComponent,
    UsersComponent,
    FooterComponent,
    MakerCtaComponent,
    CropperComponent,
    Cropper2Component,
    Cropper3Component,
    Cropper4Component,
    FullWidthCropperComponent,
    PasswordRecoverComponent,
    ContactFormComponent,
    ConfirmationDialogComponent,
    ResetPasswordComponent,
    NotFoundComponent,
    WordpressPageComponent,
    AppPasswordDirective,
    SecondaryMenuComponent,
    RedirectComponent,
    MakerGroupComponent,
    SalesListComponent,
    SalesHistoryComponent,
    AnalyticsComponent,
    DashboardComponent,
    ProductPageComponent,
    ProductPinPageComponent,
    ExperienceCardComponent,
    ReviewsComponent,
    ReviewFormComponent,
    ReviewsPageComponent,
    ProductReviewsListComponent,
    ModalAlertComponent,
    MakerDirectories,
    MakerSearch,
    MakerCardComponent,
    CmsComponent,
    CmsModuleComponent,
    CmsFieldComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    IconsModule,
    InfiniteScrollModule,
    FormsModule,
    AccordionModule.forRoot(),
    CarouselModule.forRoot(),
    ModalModule.forRoot(),
    TooltipModule.forRoot(),
    ReactiveFormsModule,
    HttpClientModule,
    NgxDatatableModule,
    NgSelectModule,
    BsDropdownModule.forRoot(),
    CollapseModule.forRoot(),
    BrowserAnimationsModule,
    AngularCropperjsModule,
    SocialLoginModule,
    EditorModule,
    BsDatepickerModule.forRoot(),
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => {
          return new TranslateHttpLoader(http);
        },
        deps: [ HttpClient ]
      }
    }),
    QRCodeModule,
    CKEditorModule
  ],
  providers: [
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(environment.google.clientId),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(environment.facebook.clientId),
          },
        ],
      } as SocialAuthServiceConfig,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiAuthInterceptor,
      multi: true,
    },
    ExperiencesService,
    CategoriesService,
    TagsService,
    LanguagesService,
    CountryService,
    LocationService,
    MediaExperiencesService,
    AuthService,
    AuthGuard,
    LoggedInGuard,
    MakerService,
    TakerService,
    ResellerService,
    UsersService,
    MediaMakerService,
    MediaResellerService,
    Title,
    TranslateService,
    SalesService,
    AnalyticsService
  ]
})
export class AppModule { }
