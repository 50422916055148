<div *ngIf="experience" class="experience-card rounded-lg shadow overflow-hidden">
    <div class="experience-photos position-relative">
        <!-- <carousel [interval]="0" class="w-100 carousel" data-ride="carousel">
            <slide *ngFor="let image of experience.mediaExperiences" class="slide">
                <img onError="this.src='assets/venntur-placeholder.jpeg'"
                    [src]="getUrl(experience.civitatisId, image.contentUrl)" alt="{{experience.name}} - Venntur"
                    title="{{experience.name}}" style="display: block; width: 100%;">
            </slide>
        </carousel> -->
        <!-- <div class="keen-slider" #photosSlider *ngIf="isDashboard == false">
            <ng-container *ngFor="let image of experience.mediaExperiences.slice(0, 1); let i = index">
        <div class="keen-slider__slide" [ngClass]="{'active': i == 0}"> -->
        <img onError="this.src='assets/venntur-placeholder.jpeg'"
            [src]="getUrl(experience.civitatisId, experience.mediaExperiences[0].contentUrl)"
            alt="{{experience.name}} - Venntur" title="{{experience.name}}" style="display: block; width: 100%;">
        <!-- </div>
        </ng-container>
        </div> -->
        <div *ngIf="isDashboard">
            <img onError="this.src='assets/venntur-placeholder.jpeg'"
                [src]="getUrl(experience.civitatisId, experience.mediaExperiences[0].contentUrl)"
                alt="{{experience.name}} - Venntur" title="{{experience.name}}" style="display: block; width: 100%;">
        </div>
        <!-- <ng-template [ngIf]="experience.promotion">
            <span
                class="position-absolute badge badge-pill badge-primary font-weight-normal bg-pink ml-4 mb-3">{{experience.promotionType}}</span>
        </ng-template> -->
    </div>
    <div class="experience-info position-relative px-4 px-md-3 px-lg-4 py-3">
        <!-- <div class="dots" *ngIf="dotHelper.length > 1 && isDashboard == false">
            <button (click)="slider.moveToIdx(i)" *ngFor="let slide of dotHelper; let i = index"
                [class]="'dot ' + (i === currentSlide ? 'active' : '')"></button>
        </div> -->
        <div class="d-flex justify-content-between mb-1" style="height: 32px;">
            <div class="experience-icons mb-2 text-md">
                <span (click)="clickFavorite($event, experience['@id'])" class="mr-3 pointer"
                    [ngClass]="takerExperiences.includes(experience['@id']) ? 'vi-heart-fill' : 'vi-heart'"></span>
                <span class="vi-plane mr-3 pointer" (click)="shareExperience(experience)"></span>
            </div>
            <ng-container *ngIf="experience.price !== null">
                <div class="text-primary font-weight-bold">
                    <div class="fromText text-right text-xxs">{{ 'Desde' | translate}}</div>
                    <div class="priceText h4 m-0">{{experience.price}} €</div>
                </div>
            </ng-container>

        </div>
        <div class="category mb-2 text-xxs font-weight-bold" [title]="setCategoryTitle(experience)">
            <span *ngFor="let category of experience.categories; let last = last">{{category.name}}<ng-container
                    *ngIf="!last"> /
                </ng-container></span>
        </div>
        <h2 class="experiencie-title h6 mb-3 text-truncate-2" [title]="experience.name">{{experience.name}}</h2>
        <carousel [interval]="0" class="w-100 small">
            <slide>
                <ul class="experience-meta list-unstyled">
                    <li class="time" *ngIf="experience.duration != null">
                        <span class="vi-clock"></span>{{convertTimeToCustomFormat(experience.duration)}}
                    </li>
                    <li class="days" *ngIf="experience.weekDays && experience.weekDays.length >= 1">
                        <span class="vi-calendar"></span>
                        <span *ngFor="let weekDay of experience.weekDays; let i = index">
                            {{shownDays[weekDay].text | translate}}{{i === experience.weekDays.length - 1 ? '' : ', '}}
                        </span>
                    </li>
                    <li class="languages" [title]="setLanguagesTitle(experience)" *ngIf="experience.languages != null">
                        <span class="vi-bubble"></span>
                        <span *ngFor="let language of experience.languages.slice(0,3); let last = last">
                            {{language.name}}<ng-container *ngIf="!last">,
                            </ng-container>
                        </span>
                        <span *ngIf="experience.languages.length > 3"> ...</span>
                    </li>
                    <li class="location" *ngIf="experience.location != null">
                        <span class="vi-pin"></span>
                        <span>{{experience.location.name}}, {{experience.location.country}}</span>
                    </li>
                </ul>
            </slide>
            <slide class="description" n *ngIf="experience.description != null">
                <div>
                    <span [innerHtml]="experience.description"></span>
                </div>
            </slide>
        </carousel>
        <div class="booking-button">
            <a *ngIf="experience.maker['makerPlan'] && experience.maker['makerPlan']['plan']['shortName'] !== 'Plan Free' && experience.sheetCompleted"
                class="btn btn-sm"
                [ngClass]="experience.maker.makerPlan.plan.name == 'Plan Free - de 1 a 3 productos en directorio' ? 'btn-free' : 'btn-primary w-100'"
                [routerLink]="'/' + translate.getDefaultLang() +'/product/' + experience.slug" target="_blank"
                rel="noopener noreferrer">
                <!-- <ng-container *ngIf="experience.urlButtonText === 'book'; else moreInfo">{{ 'Reservar' |
            translate}}</ng-container> -->
                <ng-container>{{ 'Reservar - Info' |
                    translate}}</ng-container>
            </a>
            <a *ngIf="experience.maker['makerPlan'] && experience.maker['makerPlan']['plan']['shortName'] == 'Plan Free' || !experience.sheetCompleted"
                class="btn btn-sm"
                [ngClass]="experience.maker.makerPlan.plan.name == 'Plan Free - de 1 a 3 productos en directorio' ? 'btn-free' : 'btn-primary w-100'"
                [routerLink]="experience.slug + '/redirigir'" target="_blank" rel="noopener noreferrer">
                <!-- <ng-container *ngIf="experience.urlButtonText === 'book'; else moreInfo">{{ 'Reservar' |
            translate}}</ng-container> -->
                <ng-container>{{ 'Visitar web' |
                    translate}}</ng-container>
            </a>
        </div>
    </div>
    <footer *ngIf="isFooterActive == true" class="experience-maker-info border-top py-3 px-4">
        <div class="d-flex justify-content-between align-items-center mb-2" style="height: 24px;">
            <div *ngIf="!experience.civitatisId" class="text-xxs font-weight-bold">{{ 'Organizado por' | translate}}
            </div>
            <div *ngIf="experience.civitatisId" class="text-xxs font-weight-bold">{{ 'Experiencia ofrecida por' |
                translate}}</div>
            <div
                *ngIf="experience.maker['makerPlan'] && experience.maker['makerPlan']['plan']['shortName'] !== 'Plan Free' && !experience.civitatisId && (experience.maker['sheetCompleted'] || translate.getDefaultLang() == 'en')">
                <a [routerLink]="'/' + translate.getDefaultLang() +'/maker/' + experience.maker.slug" target="_blank"
                    class="btn btn-sm btn-secondary rounded-sm text-xxs">{{ 'Ver ficha de empresa' | translate}}</a>
            </div>
        </div>
        <div class="d-flex align-items-center justify-content-between">
            <div class="mr-4">
                <ng-container
                    *ngIf="!experience.maker['makerPlan'] || experience.maker['makerPlan']['plan']['shortName'] === 'Plan Free' || experience.civitatisId || !experience.maker['sheetCompleted']; else makerProfileImage">
                    <div class="maker-logo">
                        <img class="img-fluid"
                            [src]="'https://venntur.com/assets/maker/files/' + (experience.maker['mediaMakers'].length > 0 ? experience.maker['mediaMakers'][0]['contentUrl'] : 'assets/venntur-isotype-70.png')"
                            alt="{{experience.maker['name']}}" title="{{experience.maker['name']}}">
                    </div>
                </ng-container>
                <ng-template #makerProfileImage>
                    <div class="maker-logo d-flex align-items-center">
                        <a [routerLink]="'/' + translate.getDefaultLang() +'/maker/' + experience.maker.slug"
                            target="_blank">
                            <img class="img-fluid"
                                [src]="'https://venntur.com/assets/maker/files/' + (experience.maker['mediaMakers'].length > 0 ? experience.maker['mediaMakers'][0]['contentUrl'] : 'assets/venntur-isotype-70.png')"
                                alt="{{experience.maker['name']}}" title="{{experience.maker['name']}}">
                        </a>
                    </div>
                </ng-template>
            </div>
            <div class="small">
                <ng-container
                    *ngIf="!experience.maker['makerPlan'] || experience.maker['makerPlan']['plan']['shortName'] === 'Plan Free' || experience.civitatisId || !experience.maker['sheetCompleted']; else makerProfileName">
                    <strong class="d-block">{{experience.maker["name"]}}</strong>
                </ng-container>
                <ng-template #makerProfileName>
                    <a [routerLink]="'/' + translate.getDefaultLang() +'/maker/' + experience.maker.slug"
                        target="_blank" class="d-block font-weight-bold text-dark">
                        {{experience.maker["name"]}}
                    </a>
                </ng-template>
            </div>

        </div>
        <div *ngIf="!experience.maker.user.isMakerReseller || experience.civitatisId" class="maker-ctc-info">
            <ul class="list-unstyled d-flex justify-content-between small mb-0 mt-2">
                <li><a [href]="experience.civitatisId ? experience.url + '?ag_aid=4732' : experience.maker['web'] + '?utm_source=venntur'"
                        title="Página web" target="_blank" rel="noopener noreferrer"><span class="vi-web"></span></a>
                </li>
                <li><a [href]="'tel:'+experience.maker['phone']" title="Teléfono"><span class="vi-mobile"></span></a>
                </li>
                <li><a [href]="'mailto:'+experience.maker['email']" title="Email"><span class="vi-email"></span></a>
                </li>
                <li *ngIf="experience.maker['whatsapp']"><a
                        [href]="'https://api.whatsapp.com/send?phone='+experience.maker['whatsapp']" title="WhatsApp"
                        target="_blank" rel="noopener noreferrer"><span class="vi-whatsapp"></span></a></li>
            </ul>
        </div>
    </footer>
</div>

<div *ngIf="isAlertShown" [config]="{ show: true }" (onHidden)="onHidden()" bsModal #autoShownModal="bs-modal"
    class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-auto-name">
    <div class="modal-dialog">
        <div class="modal-content">
            <button type="button" class="btn btn-sm btn-light btn-close pull-right ml-auto" aria-label="Close"
                (click)="hideModal()">
                <i-feather name="x"></i-feather>
            </button>
            <div class="modal-body text-center ">
                <p><i-feather name="alert-triangle" class="text-warning large"></i-feather></p>
                <p>Inicia sesión y añade actividades a tus <strong>favoritos</strong></p>
                <app-login></app-login>
            </div>
        </div>
    </div>
</div>