<ng-container *ngFor="let section of homeData.modules">
  <ng-container *ngIf="section.code == 'hero'">
    <div #imagesContainer class="backgrounds">
      <div class="hero container d-flex flex-column justify-content-center vh-100 py-5 position-relative">
        <div class="row d-none">
          <div class="col-9 col-md-6 col-lg-4 mx-auto border border-dark rounded bg-white p-0">
            <h1 class="slogan border-bottom border-dark py-2 px-3 m-0 h3 text-center">{{ 'Tu deseo es viajar' |
              translate
              }}<br /> {{ 'tu pasión es venntur' | translate }}</h1>
            <!--  <h1 class="border-bottom border-dark py-2 px-3 m-0 h3 text-center">{{ '¡Las experiencias nos unen!' | translate }}</h1> -->
            <form *ngIf="searchType === 'makers'" [formGroup]="experienceSearchForm"
              class="border-bottom border-dark search-form__home">
              <div class="input-group select--location">
                <div class="search-input-container py-2 px-3">
                  <p class="pre-label small m-0">{{ 'Quiero buscar una empresa en' | translate }}...</p>
                  <ng-select (change)="search(true, 'makers')" [(ngModel)]="citySelected" formControlName="location"
                    [placeholder]="translate.instant('Elige un destino')"
                    notFoundText="{{ 'No se han encontrado destinos' | translate }}" bindLabel="asdf" bindValue="">
                    <ng-option [value]="" disabled>{{ 'Destinos' | translate }}</ng-option>
                    <ng-option (click)="search(true, 'makers')" [value]="city.id"
                      *ngFor="let city of makersCities">{{city.name}} <span>- {{city.countMakers}}
                        empresas</span></ng-option>
                  </ng-select>
                </div>
                <a (click)="search(true, 'makers')"
                  class="btn btn-primary d-flex justify-content-center align-items-center"
                  title="{{ 'Buscar' | translate }}"><span class="vi-search"></span></a>
              </div>
            </form>

            <!-- class="border-bottom border-dark search-form__home" -->

            <form *ngIf="searchType === 'experiences'" [formGroup]="experienceSearchForm"
              class="border-dark search-form__home">
              <div class="input-group select--location">
                <div class="search-input-container py-2 px-3">
                  <p class="pre-label small m-0">{{ 'Disfruta tu experiencia en' | translate }}...</p>
                  <ng-select (change)="search(true, 'experiencias')" [(ngModel)]="citySelected"
                    formControlName="location" [placeholder]="translate.instant('Elige un destino')"
                    notFoundText="{{ 'No se han encontrado destinos' | translate }}" bindLabel="asdf" bindValue="">
                    <ng-option [value]="" disabled>{{ 'Destinos' | translate }}</ng-option>
                    <ng-option (click)="search(true, 'experiencias')" [value]="city.id"
                      *ngFor="let city of experiencesCities">{{city.name}} <span>- {{city.countExperiences}}
                        experiencias</span></ng-option>
                  </ng-select>
                </div>
                <a (click)="search(true, 'experiencias')"
                  class="btn btn-primary d-flex justify-content-center align-items-center"
                  title="{{ 'Buscar' | translate }}"><span class="vi-search"></span></a>
              </div>
            </form>

            <!-- <div class="d-flex flex-row">
  
          <div class="border-right w-50 py-3 radio">
            <input type="radio" id="experiences" name="searchType" value="experiences"
              (change)="searchType = 'experiences'" checked />
            <label for="experiences" class=" m-0 radio-label">Experiencias</label>
          </div>
  
          <div class="border-left w-50 py-3 radio">
            <input type="radio" id="makers" name="searchType" value="makers" (change)="searchType = 'makers'" />
            <label for="makers" class="m-0 radio-label">Empresas</label>
          </div>
  
        </div> -->

          </div>
        </div>
        <div>
          <h1 class="slogan border-dark py-2 px-3 m-0 h3 text-center">{{ 'Your wish is to travel' | translate
            }}<br /> {{ 'your passion is venntur' | translate }}</h1>
        </div>
        <div class="search-form">
          <form *ngIf="searchType === 'experiences'" [formGroup]="experienceSearchForm"
            class="border-dark search-form__home">
            <div class="input-group select--location">
              <div class="search-input-container py-2 px-3">
                <ng-select (change)="search(true, 'experiencias')" [(ngModel)]="citySelected" formControlName="location"
                  [placeholder]="translate.instant('Busca por destino')"
                  notFoundText="{{ 'No se han encontrado destinos' | translate }}" bindLabel="asdf" bindValue="">
                  <ng-option [value]="" disabled>{{ 'Destinos' | translate }}</ng-option>
                  <ng-option (click)="search(true, 'experiencias')" [value]="city.id"
                    *ngFor="let city of experiencesCities">{{city.name}} <span>- {{city.countExperiences}}
                      experiencias</span></ng-option>
                </ng-select>
              </div>
              <a (click)="search(true, 'experiencias')"
                class="btn btn-primary d-flex justify-content-center align-items-center"
                title="{{ 'Buscar' | translate }}"><span class="vi-search"></span></a>
            </div>
          </form>
        </div>
        <div class="scrolling-parent w-100 justify-content-center">
          <div #scrollingIndicator class="scrolling-indicator animated">
            <div class="d-flex flex-column" style="row-gap: 4px;">
              <span class="vi-scroll"></span>
              <span class="vi-chevron-down"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="section.code == 'community'">
    <div class="globes container d-flex flex-column justify-content-center">
      <div class="globes-header text-center">
        <strong>venntur, </strong><span>{{ "la primera comunidad de experiencias al aire libre" | translate }}</span>
      </div>
      <div class="d-flex d-md-none flex-column">
        <div class="globes-flex-parent d-flex justify-content-center">
          <a class="image-globe" target="_blank" [href]="'https://venntur.com/' + getLang() + section.extra[0].link">
            <img [src]="section.extra[0].imageUrl" alt="">
          </a>
          <a class="image-globe" target="_blank" [href]="'https://venntur.com/' + getLang() + section.extra[1].link">
            <img [src]="section.extra[1].imageUrl" alt="">
          </a>
          <a class="image-globe" target="_blank" [href]="'https://venntur.com/' + getLang() + section.extra[2].link">
            <img [src]="section.extra[2].imageUrl" alt="">
          </a>
        </div>
        <div class="big-globe-flex-parent d-flex justify-content-center">
          <div class="image-big-globe d-flex align-items-center">
            <a target="_blank" [href]="'https://venntur.com/' + getLang() + section.extra[3].link">
              <img [src]="section.extra[3].imageUrl" alt="">
            </a>
          </div>
        </div>
        <div class="globes-flex-parent inversed d-flex justify-content-center">
          <a class="image-globe" target="_blank" [href]="'https://venntur.com/' + getLang() + section.extra[4].link">
            <img [src]="section.extra[4].imageUrl" alt="">
          </a>
          <a class="image-globe" target="_blank" [href]="'https://venntur.com/' + getLang() + section.extra[5].link">
            <img [src]="section.extra[5].imageUrl" alt="">
          </a>
          <a class="image-globe" target="_blank" [href]="'https://venntur.com/' + getLang() + section.extra[6].link">
            <img [src]="section.extra[6].imageUrl" alt="">
          </a>
        </div>
      </div>
      <div class="d-none d-md-flex justify-content-center align-items-center">
        <div class="globes-md-flex-parent d-flex">
          <div class="single-image-md-globe d-flex align-items-center">
            <a target="_blank" [href]="'https://venntur.com/' + getLang() + section.extra[0].link">
              <img [src]="section.extra[0].imageUrl" alt="">
            </a>
          </div>
          <div class="d-flex flex-column justify-content-between">
            <div class="image-md-globe">
              <a target="_blank" [href]="'https://venntur.com/' + getLang() + section.extra[1].link">
                <img [src]="section.extra[1].imageUrl" alt="">
              </a>
            </div>
            <div class="image-md-globe">
              <a target="_blank" [href]="'https://venntur.com/' + getLang() + section.extra[2].link">
                <img [src]="section.extra[2].imageUrl" alt="">
              </a>
            </div>
          </div>
        </div>
        <div class="big-globe-md-flex-parent d-flex justify-content-center">
          <div class="image-big-md-globe">
            <a target="_blank" [href]="'https://venntur.com/' + getLang() + section.extra[3].link">
              <img [src]="section.extra[3].imageUrl" alt="">
            </a>
          </div>
        </div>
        <div class="globes-md-flex-parent inversed d-flex">
          <div class="d-flex flex-column justify-content-between">
            <div class="image-md-globe">
              <a target="_blank" [href]="'https://venntur.com/' + getLang() + section.extra[4].link">
                <img [src]="section.extra[4].imageUrl" alt="">
              </a>
            </div>
            <div class="image-md-globe">
              <a target="_blank" [href]="'https://venntur.com/' + getLang() + section.extra[5].link">
                <img [src]="section.extra[5].imageUrl" alt="">
              </a>
            </div>
          </div>
          <div class="single-image-md-globe d-flex align-items-center">
            <a target="_blank" [href]="'https://venntur.com/' + getLang() + section.extra[6].link">
              <img [src]="section.extra[6].imageUrl" alt="">
            </a>
          </div>
        </div>
      </div>
      <div class="text text-center">
        <span>{{ "venntur es la primera red de experiencias al aire libre propuesta directamente por creadores y expertos locales." | translate }} </span>
        <strong>{{"Únete a la comunidad venntur para disfrutar de las mejores y más nuevas experiencias" | translate}}</strong>
        <span>
          {{ "siempre que estés de viaje, ¡con el contacto directo de los proveedores locales!" | translate}}
        </span>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="section.code == 'quality'">
    <div class="section blue-first d-flex flex-column">
      <div class="container">
        <div style="max-width: 552px;margin: 0 auto;">
          <div class="title-container d-flex flex-column flex-md-row justify-content-md-center align-items-md-center">
            <div class="d-flex justify-content-center mb-2">
              <span class="vi-the-best-quality"></span>
            </div>
            <div class="title text-center">
              <h2>
                <strong>{{"La Mejor" | translate}} </strong><span>{{"Calidad" | translate}}</span>
              </h2>
            </div>
          </div>
          <div class="text text-center d-flex flex-column">
            <span>{{"La calidad es importante para venntur: nuestros proveedores están recomendados por nuestro sello de calidad." | translate}}</span><br>
            <strong>{{"¡Confía en las experiencias de los verdaderos expertos!" | translate}}</strong>
          </div>
        </div>
        <ng-container *ngIf="qualityExperiences.length > 0">
          <div class="cards">
            <div class="keen-slider" style="height: 702px;" #qualitySliderRef>
              <ng-container *ngFor="let qualityExperience of qualityExperiences">
                <div class="keen-slider__slide" style="padding-left: 8px;padding-right: 8px;">
                  <app-experience-card [experience]="qualityExperience" [isFooterActive]="true"></app-experience-card>
                </div>
              </ng-container>
            </div>
            <ng-container *ngIf="qualityExperiences.length > 1">
              <div class="bullets" *ngIf="dotHelper.length > 1">
                <button *ngFor="let slide of dotHelper; let i = index" [ngClass]="i === currentSlide ? 'active' : ''"
                  (click)="qualitySlider.moveToIdx(i)" class="bullet"></button>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="section.code == 'new'">
    <div class="section blue-second d-flex flex-column">
      <div class="container">
        <div class="title-container d-flex flex-column flex-md-row justify-content-md-center align-items-md-center">
          <div class="d-flex justify-content-center mb-2">
            <span class="vi-whats-new"></span>
          </div>
          <div class="title text-center">
            <h2>
              <strong>{{"Que hay" | translate}} </strong><span>{{"de nuevo" | translate}}</span>
            </h2>
          </div>
        </div>
        <div class="text text-center d-flex flex-column">
          <span>{{"Tal vez eres un viajero frecuente y" | translate}} <strong>{{"un amante de las aventuras:" | translate}}</strong> {{"¡Aquí encontrarás nuevas oportunidades para disfrutar de tu tiempo libre!" | translate}}</span>
        </div>
        <ng-container *ngIf="lastExperiences.length > 0">
          <div class="cards">
            <div class="keen-slider" style="height: 702px;" #lastSliderRef>
              <ng-container *ngFor="let lastExperience of lastExperiences">
                <div class="keen-slider__slide" style="padding-left: 8px;padding-right: 8px;">
                  <app-experience-card [experience]="lastExperience" [isFooterActive]="true"></app-experience-card>
                </div>
              </ng-container>
            </div>
            <ng-container *ngIf="lastExperiences.length > 1">
              <div class="bullets" *ngIf="lastDotHelper.length > 1">
                <button *ngFor="let slide of lastDotHelper; let i = index"
                  [ngClass]="i === lastCurrentSlide ? 'active' : ''" (click)="lastSlider.moveToIdx(i)"
                  class="bullet"></button>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="section.code == 'love'">
    <div class="section pink d-flex flex-column position-relative">
      <div class="container">
        <div class="d-flex flex-column flex-lg-row justify-content-between align-items-center">
          <div>
            <div class="d-flex justify-content-center justify-content-md-start mb-3">
              <span class="vi-what-people-love-more"></span>
            </div>
            <div class="title">
              <h2>
                <strong>{{"Lo que más le gusta" | translate}} </strong><span>{{"a la gente" | translate}}</span>
              </h2>
            </div>
            <div class="text d-flex flex-column">
              <span>{{"Nuestros seguidores son siempre los primeros en elegir y comentar sus experiencias:" | translate}} <strong>{{"encuentra la mas popular del mes!" | translate}}</strong></span>
            </div>
            <div class="icons d-flex justify-content-center justify-content-md-start">
              <a target="_blank" href="https://www.instagram.com/venntur_/">
                <span class="vi-icon-instagram"></span>
              </a>
              <a target="_blank" href="https://www.tiktok.com/@venntur_of">
                <span class="vi-icon-tik-tok"></span>
              </a>
              <a target="_blank" href="https://www.facebook.com/vennturcom/?locale=es_ES">
                <span class="vi-icon-facebook"></span>
              </a>
            </div>
          </div>
          <div>
            <div class="keen-slider love-slider" #loveSliderRef>
              <div class="keen-slider__slide">
                <div class="mosaic">
                  <div class="mosaic-image">
                    <a target="_blank" [href]="section.extra[whatPeopleLoveIndex].link">
                      <img [src]="section.extra[whatPeopleLoveIndex].imageUrl" alt="">
                    </a>
                  </div>
                  <div class="mosaic-image">
                    <a target="_blank" [href]="section.extra[whatPeopleLoveIndex + 1].link">
                      <img [src]="section.extra[whatPeopleLoveIndex + 1].imageUrl" alt="">
                    </a>
                  </div>
                  <div class="mosaic-image">
                    <a target="_blank" [href]="section.extra[whatPeopleLoveIndex + 2].link">
                      <img [src]="section.extra[whatPeopleLoveIndex + 2].imageUrl" alt="">
                    </a>
                  </div>
                  <div class="mosaic-image">
                    <a target="_blank" [href]="section.extra[whatPeopleLoveIndex + 3].link">
                      <img [src]="section.extra[whatPeopleLoveIndex + 3].imageUrl" alt="">
                    </a>
                  </div>
                </div>
              </div>
              <div class="keen-slider__slide">
                <div class="mosaic">
                  <div class="mosaic-image">
                    <a target="_blank" [href]="section.extra[whatPeopleLoveIndex + 4].link">
                      <img [src]="section.extra[whatPeopleLoveIndex + 4].imageUrl" alt="">
                    </a>
                  </div>
                  <div class="mosaic-image">
                    <a target="_blank" [href]="section.extra[whatPeopleLoveIndex + 5].link">
                      <img [src]="section.extra[whatPeopleLoveIndex + 5].imageUrl" alt="">
                    </a>
                  </div>
                  <div class="mosaic-image">
                    <a target="_blank" [href]="section.extra[whatPeopleLoveIndex + 6].link">
                      <img [src]="section.extra[whatPeopleLoveIndex + 6].imageUrl" alt="">
                    </a>
                  </div>
                  <div class="mosaic-image">
                    <a target="_blank" [href]="section.extra[whatPeopleLoveIndex + 7].link">
                      <img [src]="section.extra[whatPeopleLoveIndex + 7].imageUrl" alt="">
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="bullets">
              <button *ngFor="let slide of [].constructor(section.extra.length / 4);let i = index"
                [ngClass]="i === loveCurrentSlide ? 'active' : ''" (click)="loveSlider.moveToIdx(i)"
                class="bullet"></button>
            </div>
          </div>
        </div>
        <div class="isotype position-absolute">
          <img src="assets/venntur-isotype-70.png" alt="">
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="section.code == 'destinations'">
    <div class="section destinations d-flex flex-column">
      <div class="container">
        <div class="d-lg-flex justify-content-between">
          <div class="title">
            <h2>
              <strong>{{"Viajando a..." | translate}}</strong><span> {{"¿España?" | translate}}</span>
            </h2>
          </div>
          <div class="text d-flex flex-column pt-lg-3">
            <span>{{"Encuentra tu ubicación y selecciona tu próxima experiencia:" | translate}} <strong>{{"¡Los makers locales están esperando para darte la bienvenida!" | translate}}</strong></span>
          </div>
        </div>
        <div class="d-md-none select">
          <form [formGroup]="destinySearchForm">
            <ng-select (change)="search(true, 'experiencias')" [(ngModel)]="citySelected" formControlName="location"
              [placeholder]="translate.instant('Select your destination')"
              notFoundText="{{ 'No se han encontrado destinos' | translate }}" bindLabel="asdf" bindValue="">
              <ng-option [value]="" disabled>{{ 'Destinos' | translate }}</ng-option>
              <ng-option (click)="search(true, 'experiencias')" [value]="city.id"
                *ngFor="let city of experiencesCities">{{city.name}}
                <!-- <span>- {{city.countMakers}}
              empresas</span> -->
              </ng-option>
            </ng-select>
          </form>
        </div>
      </div>
      <div class="container-lg d-none d-md-block options px-md-5 px-lg-0">
        <hr>
        <ul class="w-100 columns4-2 small">
          <li style="cursor: pointer;padding-right: 1rem;" *ngFor="let location of locations"
            (click)="searchExperiences(location.id)">
            {{location.name}}</li>
        </ul>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="section.code == 'sostenibility'">
    <div class="section eco d-flex flex-column position-relative">
      <div class="container">
        <div class="eco-image">
          <img src="../../../assets/images/images-home/new-home-images/image-planet-b.png" alt="">
        </div>
        <div class="button-parent">
          <div class="eco-title d-flex">
            <div class="d-flex flex-column justify-content-end justify-content-md-between" style="line-height: 37px;">
              <div class="title1">
                <span>{{"No tenemos un" | translate}}</span>
              </div>
              <div class="title2">
                <span>{{"Planeta" | translate}}</span>
              </div>
            </div>
            <div class="title3">
              <span>B!</span>
            </div>
          </div>
          <div class="text">
            <strong>{{"Nuestros makers respetan la naturaleza" | translate}} </strong><span>{{"y se comportan de acuerdo con nuestras valoraciones en relación con el programa de sostenibilidad de la comunidad europea. Lea nuestro estatuto de sostenibilidad aquí." | translate}}</span>
          </div>
          <button class="position-absolute">
            <a *ngIf="getLang() == 'es/'" [href]="section.link.spanish" target="_blank">{{"Vive la Sostenibilidad" | translate}}</a>
            <a *ngIf="getLang() == 'en/' || getLang() == '' " [href]="section.link.english" target="_blank">{{"Vive la Sostenibilidad" | translate}}</a>
          </button>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="section.code == 'passion'">
    <div class="section orange d-flex flex-column">
      <div class="container">
        <div>
          <div class="d-flex justify-content-center justify-content-md-start mb-3">
            <span class="vi-your-passion-is-our-passion"></span>
          </div>
          <div class="d-md-none title">
            <h2>
              <span><strong>{{"Tu pasión es" | translate}} </strong><br>{{"nuestra pasión" | translate}}</span>
            </h2>
          </div>
          <div class="d-none d-md-block title">
            <h2>
              <span><strong>{{"Tu pasión es" | translate}} </strong>{{"nuestra pasión" | translate}}</span>
            </h2>
          </div>
          <div class="text d-none d-md-flex flex-column">
            <span><strong>{{"Somos amantes de los viajes y buscadores de emociones:" | translate}}</strong>
              {{"nuestro objetivo es encontrar los mejores momentos para compartir con familiares y amigos. ¡Únete a nuestra comunidad!" | translate}}</span>
          </div>
          <div class="hashtags d-none d-md-flex flex-column">
            <span>#venntur</span>
            <span>#passionoflife</span>
          </div>
        </div>
        <div class="images d-flex flex-column px-3">
          <div class="image">
            <a target="_blank" [href]="'https://venntur.com/' + getLang() + section.extra[0].link">
              <img [src]="section.extra[0].imageUrl" alt="">
            </a>
          </div>
          <div class="image align-self-end">
            <a target="_blank" [href]="'https://venntur.com/' + getLang() + section.extra[1].link">
              <img [src]="section.extra[1].imageUrl" alt="">
            </a>
          </div>
          <div class="image">
            <a target="_blank" [href]="'https://venntur.com/' + getLang() + section.extra[2].link">
              <img [src]="section.extra[2].imageUrl" alt="">
            </a>
          </div>
        </div>
        <div class="text text-center d-flex d-md-none flex-column">
          <strong>We are travel lovers and emotion seekers:</strong>
          <span>we aim to find the best moments to share with family and friends. Join our community!</span>
        </div>
        <div class="hashtags d-flex d-md-none flex-column text-center">
          <span>#venntur</span>
          <span>#passionoflife</span>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>