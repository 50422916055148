import { HttpClient, HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const API_URL = environment.cmsApiUrl;

@Injectable({
  providedIn: 'root'
})
export class CmsService {

  constructor(private http: HttpClient) { }

  public get(id: any, endpoint: string): Observable<any> {
    return this.http.get(`${API_URL}/${endpoint}/${id}`).pipe(
      catchError(this.handleError)
    );
  }

  public getAll(endpoint: string): Observable<any> {
    return this.http.get(`${API_URL}/${endpoint}`).pipe(
      catchError(this.handleError)
    );
  }
  
  public update(id: any, endpoint: string, data: any): Observable<any> {
    return this.http.put(`${API_URL}/${endpoint}/${id}`, data).pipe(
      catchError(this.handleError)
    );
  }

  public updateWeb(endpoint: string, data: any): Observable<any> {
    return this.http.post(`${API_URL}/${endpoint}`, data).pipe(
      catchError(this.handleError)
    );
  }

  public cmsMediaUpload(fileItem: File, url, extraData?: object): Observable<any> {
    const apiCreateEndpoint = `${API_URL}${url}`;
    const formData: FormData = new FormData();

    formData.append('file', fileItem, fileItem.name);
    if (extraData) {
      for (const key in extraData) {
        if (extraData.hasOwnProperty(key)) {
          formData.append(key, extraData[key]);
        }
      }
    }
    return this.http.post(`${apiCreateEndpoint}`, formData).pipe(
      catchError(this.handleError)
    );

    // const req = new HttpRequest('POST', apiCreateEndpoint, formData, {
    //   reportProgress: true // for progress data
    // });
    // return this.http.request(req);
  }

  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('Ha ocurrido un error:', error.error.message);
    } else {
      console.error(
        `Backend devolvió el codigo ${error.status}, ` +
        `Contenido: ${error.error}`);
    }
    return throwError(
      'Algún error ocurrió: por favor vuelva a intentarlo.');
  };

}
