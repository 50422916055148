import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { CmsService } from './service/cms.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ModalAlertComponent } from 'src/app/shared/modal-alert/modal-alert.component';

@Component({
  selector: 'app-cms',
  templateUrl: './cms.component.html',
  styleUrls: ['./cms.component.scss']
})
export class CmsComponent implements OnInit {
  currentTab;
  cmsForm: UntypedFormGroup;
  pageObject: any = {
    modules: []
  };

  dialogRef: BsModalRef;

  web = [];

  constructor(private formBuilder: UntypedFormBuilder, public translate: TranslateService, public cmsService: CmsService, public dialog: BsModalService ) { }

  ngOnInit(): void {
    this.getWeb();
  }

  getWeb() {
    this.cmsService.get(1, 'webs').subscribe((resp: any) => {
      this.web = resp;
      this.currentTab = this.web['pages'][0].slug
    })
  }

  saveWeb() {
    this.cmsService.updateWeb('update_web', this.web).subscribe((resp: any) => {
      this.openAlert('Página Guardada!', 'w-300', true);
    })
  }

  openAlert(modalText: string, className: string, status: boolean) {
    let iconClass: string;
    if (status) {
      iconClass = 'vi-check';
    } else {
      iconClass = 'vi-sad';
    }
    const modalConfig = {
      class: className,
      initialState: {
        alertMessage: this.translate.instant(
          modalText
        ),
        iconClass,
        status
      }
    }
    this.dialogRef = this.dialog.show(ModalAlertComponent, modalConfig);
    setTimeout(() => {
      this.dialogRef.hide();
    }, 2500);
  }

}
